export const roleTypeToPlural: { [key: string]: string } = {
  student: 'students',
  collaborator: 'collaborators',
};

export const roleTypeToSingular: { [key: string]: string } = {
  students: 'student',
  collaborators: 'collaborator',
};

export const SETTINGS_COLOR_PICKERS = {
  titleColor: ['#212B36', '#637381', '#919EAB'],
  paragraphColor: ['#212B36', '#637381', '#919EAB'],
  linkColor: ['#0079CC', '#005691', '#22C55E'],
  buttonColor: ['#22C55E', '#0079CC', '#B71D18'],
};
