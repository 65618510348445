import { Helmet } from 'react-helmet-async';

import { useParams } from 'src/routes/hooks';

import { TestsEditView } from 'src/sections/staff/tests/tests/view';

// ----------------------------------------------------------------------

export default function TestEditPage() {
  const params = useParams();

  const { id } = params;

  return (
    <>
      <Helmet>
        <title>Test: Edit</title>
      </Helmet>

      <TestsEditView id={id!} />
    </>
  );
}
