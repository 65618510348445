import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { Stack, Checkbox, TextField } from '@mui/material';

interface QuestionExternalUrlInputProps {
  isExternalField: boolean;
  mimeTypeField?: string;
  currentTab: string;
  control: Control<any>;
  setValue: (name: any, value: any, options?: object) => void;
  QUESTION_MEDIA_TYPES: Record<string, any>;
}

const QuestionExternalUrlInput: React.FC<QuestionExternalUrlInputProps> = ({
  isExternalField,
  mimeTypeField,
  currentTab,
  control,
  setValue,
  QUESTION_MEDIA_TYPES,
}) => (
  <Stack gap={0.5} flexDirection="row" marginTop="24px" alignItems="center">
    <Checkbox
      onChange={(e) => {
        if (e.target.checked) {
          setValue('mediaPath', null);
          setValue('mimeType', Object.keys(QUESTION_MEDIA_TYPES[currentTab].accept)[0]);
          setValue('isExternal', true);
          setValue('externalUrl', '');
        } else {
          setValue('isExternal', false);
          setValue('externalUrl', '');
          setValue('mimeType', undefined);
        }
      }}
      checked={isExternalField}
    />
    <Controller
      name="externalUrl"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          size="small"
          fullWidth
          onChange={(event) => {
            if (event.target.value) {
              setValue('mimeType', Object.keys(QUESTION_MEDIA_TYPES[currentTab].accept)[0]);
            }
            field.onChange(event.target.value);
          }}
          error={!!error}
          placeholder="URL"
          disabled={!isExternalField}
          helperText={error && error.message}
          value={mimeTypeField?.startsWith(currentTab) ? field.value : ''}
        />
      )}
    />
  </Stack>
);

export default QuestionExternalUrlInput;
