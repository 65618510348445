import * as Yup from 'yup';

export const CreateOrUpdateTestSchema = Yup.object().shape({
  id: Yup.string().optional(),
  name: Yup.string().required('Test name is required'),
  instruction: Yup.string().required('Instructions is required'),
  isRandomized: Yup.boolean().default(false),
  dimension: Yup.number().optional(),
  categoryId: Yup.string().optional(),
  totalQuestions: Yup.number().required(),
  testDuration: Yup.number().default(3600),
  isPublished: Yup.boolean().required(),
  isArchived: Yup.boolean().required(),
  levels: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().optional(),
        name: Yup.string().required('Level name is required'),
        minPercentage: Yup.number().min(1).max(100).required('Valid percentage is required'),
      }),
    )
    .min(1, 'A level is required'),
  independentQuestions: Yup.array().optional(),
  sections: Yup.array().optional(),
});

export type CreateOrUpdateTest = Yup.InferType<typeof CreateOrUpdateTestSchema>;
