const keys = {
  general: {
    categories: {
      fetchCategories: 'fetchCategories',
    },
    tests: {
      fetchTest: 'fetchTest',
    },
    results: {
      fetchResult: 'fetchResult',
    },
    premises: {
      fetchPremises: 'fetchPremises',
    },
  },
  staff: {
    staffs: {
      fetchAllStaffs: 'fetchAllStaffs',
      fetchStaff: 'fetchStaff',
    },
    premises: {
      fetchAllPremises: 'fetchAllPremises',
      fetchPremise: 'fetchPremise',
      fetchStudentsByPremiseId: 'fetchStudentsByPremiseId',
    },
    tests: {
      fetchTests: 'fetchTests',
      fetchTest: 'fetchTest',
      fetchQuestionsForTest: 'fetchQuestionsForTest',
    },
    questions: {
      fetchAllQuestions: 'fetchAllQuestions',
      fetchQuestion: 'fetchQuestion',
      fetchTags: 'fetchTags',
    },
    categories: {
      fetchAllCategories: 'fetchAllCategories',
      fetchCategory: 'fetchCategory',
      fetchTestForCategories: 'fetchTestForCategories',
    },
    notifications: {
      templates: {
        templates: 'templates',
        template: 'template',
        templateByRole: (role: string) => `template ${role}`,
      },
      settings: {
        setting: 'setting',
      },
    },
    students: {
      fetchAllStudents: 'fetchAllStudents',
      fetchStudentInfo: 'fetchStudentInfo',
      fetchStudentResults: 'fetchStudentResults',
      fetchStudentResult: 'fetchStudentResult',
      fetchStudentNotifications: 'fetchStudentNotifications',
    },
    results: {
      fetchAllResults: 'fetchAllResults',
    },
    fetchStaffAccount: 'fetchStaffAccount',
    fetchTestLevels: 'fetchTestLevels',
  },
  student: {
    fetchStudentAccountInfo: 'fetchStudentAccountInfo',
  },
};

export default keys;
