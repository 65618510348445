import React, { Children } from 'react';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import {
  Box,
  Link,
  Stack,
  Switch,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import queryKeys from 'src/constants/query-keys';
import { USER_ROLES } from 'src/constants/user-roles';
import { deletePremise, updatePremise } from 'src/api/premises';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { ITableColumns } from 'src/types/misc';
import { IModifiedPremisesItem } from 'src/types/premises';

type Props = {
  row: IModifiedPremisesItem;
  headLabel: Partial<ITableColumns>[] | any;
};

export default function PremisesTableRow({ row, headLabel }: Props) {
  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const confirm = useBoolean();
  const queryClient = useQueryClient();
  const [checked, setChecked] = React.useState(row.isActive || false);
  const navigate = useNavigate();
  const { t } = useTranslate();

  const handleDeletePremise = async () => {
    try {
      confirm.onFalse();
      await deletePremise(row.id);
      enqueueSnackbar(t('premises_edit_page.premises_deleted'));
      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.premises.fetchAllPremises] });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const redirectUrl = paths.staff.premiseEdit(row.id);

  const handleEdit = async () => {
    navigate(redirectUrl);
  };

  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    await updatePremise({ ...row, isActive: !row.isActive });

    queryClient.invalidateQueries({ queryKey: [queryKeys.staff.premises.fetchAllPremises] });

    await new Promise((resolve) => setTimeout(resolve, 500));
    enqueueSnackbar(t('common.update_success'));
  };

  const tableActions = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('premises_edit_page.edit_premises')} placement="top" arrow>
        <IconButton onClick={handleEdit}>
          <Iconify icon="solar:pen-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  const tableDeleteAction = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('premises_edit_page.delete_premises')} placement="top" arrow>
        <IconButton onClick={() => confirm.onTrue()} sx={{ color: 'error.dark' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
  return (
    <>
      <TableRow hover>
        {Children.toArray(
          headLabel.map((headCell: any) => {
            if (headCell?.field_mod) {
              return (
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                  }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.field_mod]}
                </TableCell>
              );
            }
            if (headCell?.id) {
              if (headCell.id === 'name') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Stack>
                      <Typography variant="subtitle2" fontWeight={900}>
                        <Link
                          color="inherit"
                          underline="hover"
                          component={RouterLink}
                          href={redirectUrl}
                          sx={{ cursor: 'pointer' }}
                        >
                          {/* @ts-ignore */}
                          {row?.[headCell?.id]}
                        </Link>
                      </Typography>
                      <Typography color="action.disabled" variant="caption">
                        {row.address}
                      </Typography>
                    </Stack>
                  </TableCell>
                );
              }
              if (headCell.id === 'email') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      whiteSpace: 'nowrap',
                      textDecoration: 'none',
                      color: 'customColors.custom1',
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* <Link> */}
                    {/* @ts-ignore */}
                    {row?.[headCell?.id]}
                    {/* </Link> */}
                  </TableCell>
                );
              }

              if (headCell.id === 'analyst') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      textDecoration: 'none',
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* @ts-ignore */}
                    {row?.[headCell?.id]}
                  </TableCell>
                );
              }

              if (headCell.id === 'isActive') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      whiteSpace: 'nowrap',
                      textDecoration: 'none',
                      color: 'success',
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Switch
                      checked={checked}
                      onChange={handleSwitchChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="success"
                      disabled={isAnalyst}
                    />
                  </TableCell>
                );
              }

              if (headCell.id === 'actions') {
                return <>{tableActions}</>;
              }

              return (
                <TableCell
                  sx={{ whiteSpace: 'nowrap', width: headCell.width, minWidth: headCell.minWidth }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.id]}
                </TableCell>
              );
            }
            return <>{tableDeleteAction}</>;
          }),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" width={24} height={24} />{' '}
            <Typography variant="h6" fontWeight={900}>
              {t('premises_edit_page.delete_premises_title')}
            </Typography>
          </Box>
        }
        content={
          <Typography variant="subtitle1" fontWeight={400}>
            {t('premises_edit_page.sure_delete')}
          </Typography>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeletePremise}>
            {t('common.delete')}
          </Button>
        }
      />
    </>
  );
}
