const CollaboratorsIcon = () => (
  <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.32">
      <path
        d="M20.974 12.9491C21.4184 12.0559 21.6683 11.0489 21.6683 9.98352C21.6683 8.91815 21.4184 7.91115 20.974 7.01794C21.8845 5.78393 23.3488 4.98352 25 4.98352C27.7614 4.98352 30 7.2221 30 9.98352C30 12.7449 27.7614 14.9835 25 14.9835C23.3488 14.9835 21.8845 14.1831 20.974 12.9491Z"
        fill="#1877F2"
      />
      <path
        d="M23.4329 32.9264C24.4272 33.1779 25.5199 33.3169 26.6667 33.3169C31.269 33.3169 35 31.0783 35 28.3169C35 25.5554 31.269 23.3169 26.6667 23.3169C25.5184 23.3169 24.4243 23.4562 23.4289 23.7083C25.4355 24.9056 26.6683 26.5298 26.6683 28.3185C26.6683 30.1061 25.4371 31.7293 23.4329 32.9264Z"
        fill="#1877F2"
      />
    </g>
    <path
      d="M15.0017 16.6502C18.6836 16.6502 21.6683 13.6654 21.6683 9.98352C21.6683 6.30162 18.6836 3.31685 15.0017 3.31685C11.3198 3.31685 8.33501 6.30162 8.33501 9.98352C8.33501 13.6654 11.3198 16.6502 15.0017 16.6502Z"
      fill="#1877F2"
    />
    <path
      d="M15.0017 34.9852C21.445 34.9852 26.6683 32.0004 26.6683 28.3185C26.6683 24.6366 21.445 21.6518 15.0017 21.6518C8.55835 21.6518 3.33501 24.6366 3.33501 28.3185C3.33501 32.0004 8.55835 34.9852 15.0017 34.9852Z"
      fill="#1877F2"
    />
  </svg>
);

export default CollaboratorsIcon;
