import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  alpha,
  Radio,
  Stack,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  RadioGroup,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@mui/material';

import { IUseBooleanReturnType } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { SITE_URL } from 'src/config-global';

import { IStudentModifiedItem } from 'src/types/students';
import { ICollaboratorsItem } from 'src/types/collaborators';

import Iconify from '../../iconify';
import StaffSearchBox from '../staff-search-box';
import StudentSearchBox from '../students-search-box';

interface ShareDiloagProps {
  dialog: IUseBooleanReturnType;
  title: string;
  enableUserTypeSelection?: boolean;
  studentLinkToShare: string;
  staffLinkToShare?: string;
  onShare: (payload: any) => Promise<void>;
  addtionalPayloadToShareFn?: Record<string, string>;
}

const RADIO_OPTIONS = [
  { label: 'Student', value: 'student' },
  { label: 'Collaborator', value: 'staff' },
];

function ShareDialog({
  dialog,
  title,
  enableUserTypeSelection = false,
  studentLinkToShare = '',
  staffLinkToShare = '',
  onShare,
  addtionalPayloadToShareFn = {},
}: ShareDiloagProps) {
  const [selectedRecipient, setSelectedRecipient] = useState<
    null | IStudentModifiedItem | ICollaboratorsItem
  >(null);
  const [selectedUserType, setSelectedUserType] = useState<string>(RADIO_OPTIONS[0].value);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();

  const closeDialog = () => {
    dialog.onFalse();
  };

  const handleRecipientChosen = (recipient: IStudentModifiedItem | ICollaboratorsItem) => {
    setSelectedRecipient(recipient);
  };

  const handleUserTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRecipient(null);
    setSelectedUserType((event.target as HTMLInputElement).value);
  };

  const handleCopyLink = () => {
    const linkToShare = selectedUserType === 'student' ? studentLinkToShare : staffLinkToShare;
    navigator.clipboard
      .writeText(`${SITE_URL}${linkToShare}`)
      .then(() => {
        enqueueSnackbar(t('sharing.link_copied'), { variant: 'success' });
      })
      .catch((_error) => {
        enqueueSnackbar(t('sharing.failed_copy_link'), { variant: 'error' });
      });
  };

  const handleSendEmail = async () => {
    try {
      setIsSendingEmail(true);
      const toEmail = selectedRecipient?.email;
      const toFullName = selectedRecipient?.fullName;
      const linkToShare = selectedUserType === 'student' ? studentLinkToShare : staffLinkToShare;

      const data = {
        ...addtionalPayloadToShareFn,
        to: toEmail,
        link: `${SITE_URL}${linkToShare}`,
        toName: toFullName,
        isForStudent: selectedUserType === 'student',
      };

      await onShare(data);
      enqueueSnackbar(t('sharing.share_email_sent'), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('sharing.failed_share_email'), { variant: 'error' });
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <Dialog
      open={dialog.value}
      onClose={closeDialog}
      fullWidth
      sx={{
        '& .MuiPaper-elevation': {
          maxWidth: '720px',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h6" component="span" fontWeight="bold">
          {title}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 20,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="mingcute:close-line" />
      </IconButton>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '1.5rem',
          paddingBottom: '1rem',
        }}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />
        {enableUserTypeSelection && (
          <FormControl>
            <RadioGroup
              aria-labelledby="user-type-selection"
              value={selectedUserType}
              onChange={handleUserTypeChange}
              name="user-type-selection"
              sx={{ gap: '1.25rem', pl: '0.5rem' }}
              row
            >
              {RADIO_OPTIONS.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio
                      sx={{
                        color: 'customColors.custom1',
                        '&.Mui-checked': {
                          color: 'customColors.custom1',
                        },
                      }}
                    />
                  }
                  label={option.label}
                  sx={{
                    mr: 0,
                    '&:not(:last-of-type)': {
                      mr: 2,
                    },
                    color: 'grey.900',
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box sx={{ minWidth: '390px' }}>
            {selectedUserType === 'student' ? (
              <StudentSearchBox onStudentChosen={handleRecipientChosen} />
            ) : (
              <StaffSearchBox onStaffChosen={handleRecipientChosen} />
            )}
          </Box>
          <LoadingButton
            variant="contained"
            endIcon={
              <Iconify
                icon="streamline:mail-send-envelope-solid"
                sx={{ width: '16px', height: '16px' }}
              />
            }
            sx={{ minWidth: '125px', backgroundColor: 'customColors.custom1' }}
            onClick={handleSendEmail}
            disabled={!selectedRecipient}
            loading={isSendingEmail}
          >
            {t('sharing.share_send_email')}
          </LoadingButton>
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '24px',
              color: 'grey.500',
              textAlign: 'center',
            }}
          >
            {t('common.or')}
          </Typography>
          <Button
            variant="contained"
            sx={{
              minWidth: '90px',
              backgroundColor: (theme) => alpha(theme.palette.customColors.custom1, 0.08),
              color: 'customColors.custom1',
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.customColors.custom1, 0.3),
              },
            }}
            onClick={handleCopyLink}
            disabled={
              (selectedUserType === 'student' && !studentLinkToShare) ||
              (selectedUserType === 'staff' && !staffLinkToShare)
            }
          >
            {t('sharing.share_copy_link')}
          </Button>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} variant="outlined" color="inherit">
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShareDialog;
