import { isEqual } from 'lodash';
import React, { useMemo, useState, useCallback } from 'react';

import { Card, Table, TableBody, TableContainer } from '@mui/material';

import { useDebounce } from 'src/hooks/use-debounce';

import { removeFalsyValuesFromObject } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { TABLE_DEFAULTS } from 'src/constants/misc';
import { USER_ROLES } from 'src/constants/user-roles';
import { useGetStudentListByPremiseId } from 'src/api/premises';

import Scrollbar from 'src/components/scrollbar';
import StudentsToolbar from 'src/components/student-table/student-toolbar';
import StudentTableRow from 'src/components/student-table/student-table-row';
import StudentsTableFiltersResult from 'src/components/student-table/student-table-filters-results';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { IListFilters } from 'src/types/misc';
import {
  IStudentModifiedItem,
  IStudentsTableFilters,
  IStudentsTableFilterValue,
} from 'src/types/students';

type Props = {
  id: string;
};

const defaultFilters: IStudentsTableFilters = {
  searchText: '',
};

export default function StudentTab({ id }: Props) {
  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const [filters, setFilters] = useState(defaultFilters);
  const { t } = useTranslate();

  const TABLE_HEAD = useMemo(
    () => [
      { id: 'fullName', label: t('common.name'), width: 238, align: 'left' },
      { id: 'email', label: t('common.email'), width: 273, align: 'left' },
      { id: 'phone', label: t('common.phone'), width: 145, align: 'left' },
      { id: 'results', label: t('common.results'), width: 114, align: 'center' },
      { id: 'level', label: t('common.level'), width: 131, align: 'center' },
      ...(!isAnalyst
        ? [{ id: 'actions', label: t('common.actions'), align: 'left' }, { id: '' }]
        : []),
    ],
    [t, isAnalyst],
  );

  const table = useTable({ defaultRowsPerPage: TABLE_DEFAULTS.ROWS_PER_PAGE });

  const canReset = !isEqual(defaultFilters, filters);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const handleFilters = useCallback(
    (name: string, value: IStudentsTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) => ({
      ...(fetchAll
        ? { all: true }
        : {
            page: table.page + 1,
            limit: table.rowsPerPage,
          }),
      sortBy: table.orderBy === 'fullName' ? 'name' : table.orderBy,
      sortDirection: table.order,
    }),
    [table],
  );

  const apiFiltersHandler = useCallback(() => {
    const allFilters: Partial<IStudentsTableFilters> = { ...filters };
    if (allFilters.searchText) {
      delete allFilters.searchText;
    }

    return allFilters;
  }, [filters]);

  const [debouncedSearchText] = useDebounce(filters.searchText || '', 1500);

  const apiFilters: IListFilters<IStudentsTableFilters> = useMemo(() => {
    const allFilters = apiFiltersHandler();
    const tableFilters = tableFiltersHandler({ fetchAll: false });

    return removeFalsyValuesFromObject({
      ...allFilters,
      ...tableFilters,
      search: debouncedSearchText,
    });
  }, [apiFiltersHandler, tableFiltersHandler, debouncedSearchText]);

  const { data }: any = useGetStudentListByPremiseId({ ...apiFilters, id });

  const {
    students: tableData = [],
    totalCount = 0,
  }: {
    students: IStudentModifiedItem[];
    totalCount: number;
  } = useMemo(() => ({ students: data?.data || [], totalCount: data?.total_count || 0 }), [data]);

  const notFound = (!tableData.length && canReset) || !tableData.length;

  return (
    <Card>
      <StudentsToolbar onFilters={handleFilters} filters={filters} isPremiseStudentTab />

      {canReset && (
        <StudentsTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          onResetFilters={handleResetFilters}
          //
          results={totalCount}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={20}
              numSelected={table.selected.length}
              onSort={table.onSort}
            />

            <TableBody>
              {tableData.length > 0 &&
                tableData.map((row, index) => (
                  <StudentTableRow
                    key={`${row.id} ${index}`}
                    row={row}
                    headLabel={TABLE_HEAD}
                    isPremiseStudentTab
                  />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalCount}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}
