import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Card,
  Stack,
  alpha,
  Table,
  Button,
  TableBody,
  Container,
  TableContainer,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';

import { csvDownloader } from 'src/utils/file-downloader';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { removeFalsyValuesFromObject } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import queryKeys from 'src/constants/query-keys';
import { TABLE_DEFAULTS } from 'src/constants/misc';
import { USER_ROLES } from 'src/constants/user-roles';
import { syncWithDatoCMS, useGetPremisesList } from 'src/api/premises';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { IListFilters } from 'src/types/misc';
import {
  IPremisesItem,
  IModifiedPremisesItem,
  IPremisesTableFilters,
  IPremisesTableFilterValue,
} from 'src/types/premises';

import PremisesToolbar from '../list/premises-toolbar';
import PremisesTableRow from '../list/premises-table-row';
import CreatePremisesDialog from '../list/create-premises-dialog';
import PremisesTableFiltersResult from '../list/premises-table-filters-result';

const defaultFilters: IPremisesTableFilters = {
  searchText: '',
  status: '',
};

const EXCEL_DOWNLOAD_HEADERS = ['Name', 'Email', 'Associated analysts', 'Status'];

const EXCEL_DATA_KEYS = ['name', 'email', 'analyst', 'isActive'];

export default function PremisesListView() {
  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const [filters, setFilters] = useState(defaultFilters);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const { t } = useTranslate();
  const queryClient = useQueryClient();

  const TABLE_HEAD = useMemo(
    () => [
      { id: 'name', label: t('common.name'), width: 284, align: 'left' },
      { id: 'email', label: t('common.email'), width: 314, align: 'left' },
      {
        id: 'analyst',
        label: t('premises_listing_page.associated_analysts'),
        width: 209,
        align: 'left',
      },
      { id: 'isActive', label: t('common.status'), width: 93, align: 'center' },
      ...(!isAnalyst
        ? [{ id: 'actions', label: t('common.actions'), align: 'left' }, { id: '' }]
        : []),
    ],
    [t, isAnalyst],
  );

  const table = useTable({ defaultRowsPerPage: TABLE_DEFAULTS.ROWS_PER_PAGE });

  const settings = useSettingsContext();

  const canReset = !isEqual(defaultFilters, filters);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const createDialog = useBoolean();

  const handleFilters = useCallback(
    (name: string, value: IPremisesTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) => ({
      ...(fetchAll
        ? { all: true }
        : {
            page: table.page + 1,
            limit: table.rowsPerPage,
          }),
      sortBy: table.orderBy === 'fullName' ? 'name' : table.orderBy,
      sortDirection: table.order,
    }),
    [table],
  );

  const apiFiltersHandler = useCallback(() => {
    const allFilters: Partial<IPremisesTableFilters> = { ...filters };
    if (allFilters.searchText) {
      delete allFilters.searchText;
    }

    return allFilters;
  }, [filters]);

  const [debouncedSearchText] = useDebounce(filters.searchText || '', 1500);

  const apiFilters: IListFilters<IPremisesTableFilters> = useMemo(() => {
    const allFilters = apiFiltersHandler();
    const tableFilters = tableFiltersHandler({ fetchAll: false });

    return removeFalsyValuesFromObject({
      ...allFilters,
      ...tableFilters,
      search: debouncedSearchText,
    });
  }, [apiFiltersHandler, tableFiltersHandler, debouncedSearchText]);

  const { data }: any = useGetPremisesList({ ...apiFilters });

  const {
    collaborators: tableData = [],
    totalCount = 0,
  }: {
    collaborators: IModifiedPremisesItem[];
    totalCount: number;
  } = useMemo(() => {
    const groupedArray = data?.data?.map((pr: IPremisesItem) => {
      const formatData = {
        id: pr.id,
        name: pr.name,
        address: pr.address,
        isActive: pr.isActive,
        email: pr.email,
        analyst: pr?.users?.map((us) => us.user.fullName).join(', '),
      };

      return formatData;
    });

    return {
      collaborators: groupedArray,
      totalCount: data?.total_count || 0,
    };
  }, [data]);

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const fileDownloader = useCallback(
    ({ fetchedRows, fileType }: { fetchedRows: any[]; fileType: string }) => {
      const rows = fetchedRows.map((rowData) =>
        EXCEL_DATA_KEYS.map((key) => rowData[key as keyof {}] as string | number | boolean),
      );
      csvDownloader({
        fileName: 'Premises-list',
        fileType,
        headers: EXCEL_DOWNLOAD_HEADERS as string[],
        rows: rows as (string | number)[][],
      });
    },
    [],
  );

  const excelDataModifier = useCallback(
    (rows: IPremisesItem[]) =>
      rows?.map((row) => ({
        name: row.name,
        email: row.email,
        analyst: row.users.map((us) => us.user.fullName).join(', ') || '',
        isActive: row.isActive ? t('common.yes') : t('common.no'),
      })) || [],
    [t],
  );

  const downloadPremises = useCallback(
    async (fileType: string) => {
      try {
        setIsFileLoading(true);
        const {
          data: { data: downloadData },
        } = await axiosInstance(endpoints.staff.premises, {
          params: canReset
            ? removeFalsyValuesFromObject({
                ...apiFiltersHandler(),
                ...tableFiltersHandler({ fetchAll: true }),
                search: debouncedSearchText,
              })
            : { sendAllRows: true },
        });
        if (downloadData?.data.length > 0) {
          fileDownloader({ fetchedRows: excelDataModifier(downloadData?.data || []), fileType });
        } else {
          enqueueSnackbar('no_data_found', { variant: 'error' });
        }
        setIsFileLoading(false);
      } catch (error) {
        setIsFileLoading(false);
        enqueueSnackbar(JSON.stringify(error || '{}'), { variant: 'error' });
      }
    },
    [
      apiFiltersHandler,
      canReset,
      debouncedSearchText,
      excelDataModifier,
      fileDownloader,
      tableFiltersHandler,
    ],
  );

  const fileDownloadHandler = useCallback(() => downloadPremises('csv'), [downloadPremises]);

  const handleSyncWithDato = async () => {
    try {
      setIsSyncing(true);
      const response = await syncWithDatoCMS();
      enqueueSnackbar(`Synced ${response.data.synced} records from DatoCMS`, {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.premises.fetchAllPremises] });
    } catch (error) {
      console.error('Error syncing with DatoCMS:', error);
      enqueueSnackbar('Failed to sync with DatoCMS', { variant: 'error' });
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={t('common.premises')}
          links={[{ name: t('common.dashboard'), href: '#' }, { name: t('common.premises') }]}
          action={
            !isAnalyst && (
              <Stack spacing={1} direction="row">
                <LoadingButton
                  sx={{
                    backgroundColor: alpha('#0997FF', 0.08),
                    color: '#066BB5',
                  }}
                  startIcon={
                    isSyncing ? (
                      <Iconify icon="eos-icons:loading" />
                    ) : (
                      <Iconify icon="mdi:refresh" />
                    )
                  }
                  disabled={isSyncing}
                  onClick={handleSyncWithDato}
                >
                  {t('common.sync_with_dato')}
                </LoadingButton>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'customColors.custom1',
                  }}
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  onClick={createDialog.onTrue}
                >
                  {t('common.new_premises')}
                </Button>
              </Stack>
            )
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Card
          sx={{
            mt: { xs: 3, md: 5 },
          }}
        >
          <PremisesToolbar
            onFilters={handleFilters}
            filters={filters}
            fileDownloadHandler={fileDownloadHandler}
            isFileLoading={isFileLoading}
          />

          {canReset && (
            <PremisesTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={totalCount}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  // TODO:
                  rowCount={20}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {tableData.length > 0 &&
                    tableData.map((row, index) => (
                      <PremisesTableRow
                        key={`${row.id} ${index}`}
                        row={row}
                        headLabel={TABLE_HEAD}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalCount}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>
      {createDialog.value && <CreatePremisesDialog dialog={createDialog} />}
    </>
  );
}
