import React, { ChangeEvent, useCallback } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { TextField, Typography, InputAdornment, SelectChangeEvent } from '@mui/material';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';

import { IStudentsTableFilters, IStudentsTableFilterValue } from 'src/types/students';

type props = {
  filters: IStudentsTableFilters;
  onFilters: (name: string, value: IStudentsTableFilterValue) => void;
  fileDownloadHandler?: () => void;
  isFileLoading?: boolean;
  isPremiseStudentTab?: boolean;
};

export default function StudentsToolbar({
  filters,
  onFilters,
  fileDownloadHandler,
  isFileLoading,
  isPremiseStudentTab = false,
}: props) {
  const { t } = useTranslate();
  const handleFilter = useCallback(
    (
      event: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
    ) => {
      onFilters(field, event.target.value);
    },
    [onFilters],
  );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={filters.searchText}
          onChange={(e) => handleFilter(e, 'searchText')}
          placeholder={t('common.search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
        {!isPremiseStudentTab && (
          <LoadingButton
            variant="outlined"
            size="small"
            endIcon={<Iconify icon="mingcute:download-3-fill" />}
            loading={isFileLoading}
            sx={{
              color: 'customColors.custom1',
              borderColor: 'customColors.custom1',
              minWidth: 113,
            }}
            onClick={fileDownloadHandler}
          >
            <Typography variant="overline" textTransform="none" whiteSpace="nowrap">
              {t('common.export')} CSV
            </Typography>
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
