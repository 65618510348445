import React from 'react';
import { Helmet } from 'react-helmet-async';

import { PremisesView } from 'src/sections/staff/premises/view';

export default function CollaboratorsList() {
  return (
    <>
      <Helmet>
        <title>Premises: List</title>
      </Helmet>

      <PremisesView />
    </>
  );
}
