import v from 'voca';
import React from 'react';

import { Box, Container } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useGetTemplateByRole } from 'src/api/templates';
import { roleTypeToPlural } from 'src/constants/template';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import { ITemplateRoleItem } from 'src/types/templates';

import EmailTemplatesRoleItems from '../details/email-templates-role-items';

type Props = {
  role: string;
};

export default function EmailDetailsView({ role }: Props) {
  const settings = useSettingsContext();
  const { t } = useTranslate();

  const { data }: any = useGetTemplateByRole(role);
  const templateRoleData: ITemplateRoleItem[] = React.useMemo(() => data || [], [data]);
  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={v.titleCase(roleTypeToPlural[role]) || ''}
        links={[
          { name: t('common.dashboard'), href: '#' },
          { name: t('common.notification'), href: paths.staff.notifications.email },
          { name: t('common.email'), href: paths.staff.notifications.email },
          { name: v.titleCase(roleTypeToPlural[role]) },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr',
          },
          gap: 5,
          width: '100%',
        }}
      >
        {templateRoleData.map((template) => (
          <EmailTemplatesRoleItems key={template.id} template={template} role={role} />
        ))}
      </Box>
    </Container>
  );
}
