import v from 'voca';
import React, { ChangeEvent, useCallback } from 'react';

import { Stack } from '@mui/system';
import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { USER_ROLES } from 'src/constants/user-roles';

import Iconify from 'src/components/iconify';

import {
  ICollaboratorsTableFilters,
  ICollaboratorsTableFilterValue,
} from 'src/types/collaborators';

type props = {
  filters: ICollaboratorsTableFilters;
  onFilters: (name: string, value: ICollaboratorsTableFilterValue) => void;
};

export default function CollaboratorsToolbar({ filters, onFilters }: props) {
  const { t } = useTranslate();
  const handleFilter = useCallback(
    (
      event: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
    ) => {
      onFilters(field, event.target.value);
    },
    [onFilters],
  );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
      }}
    >
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 287 },
        }}
      >
        <InputLabel id="type-multiple-checkbox-label">{t('common.role')}</InputLabel>

        <Select
          labelId="type-multiple-checkbox-label"
          id="type-multiple-checkbox"
          value={filters.role}
          onChange={(e) => handleFilter(e, 'role')}
          input={<OutlinedInput label={t('common.role')} />}
          sx={{ textTransform: 'capitalize' }}
        >
          {Object.values(USER_ROLES)
            .filter((option) => option !== USER_ROLES.STUDENT)
            .map((option) => (
              <MenuItem key={option} value={option}>
                {v.titleCase(option)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={filters.searchText}
          onChange={(e) => handleFilter(e, 'searchText')}
          placeholder={t('common.search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
}
