import { useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';

import { useDebounce } from 'src/hooks/use-debounce';

import { useTranslate } from 'src/locales';
import { useGetStudentsList } from 'src/api/students';

import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';

import { IStudentModifiedItem } from 'src/types/students';

type Props = {
  onStudentChosen: (student: IStudentModifiedItem) => void;
  disabled?: boolean;
};

export default function StudentSearchBox({ onStudentChosen, disabled = false }: Props) {
  const [query, setQuery] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const { t } = useTranslate();

  const [debouncedSearchText, isDebouncing] = useDebounce(query, 1500);

  const { data: studentData, isPending } = useGetStudentsList({
    all: true,
    searchQuery: debouncedSearchText,
    disableUntilSearch: true,
  });

  const students = useMemo(() => (studentData ? studentData.data : []), [studentData]);

  const isLoading = isDebouncing || (debouncedSearchText.trim() !== '' && isPending);

  return (
    <Autocomplete
      disabled={disabled}
      loading={isLoading}
      autoHighlight
      fullWidth
      inputValue={searchInput}
      onInputChange={(_event, newVal, reason) => {
        if (reason !== 'reset') {
          setSearchInput(newVal);
          setQuery(newVal);
        }
      }}
      onChange={(_e, newVal) => {
        setSearchInput((newVal?.fullName as string) ?? '');
        setQuery('');
        onStudentChosen(newVal as unknown as IStudentModifiedItem);
      }}
      options={students as IStudentModifiedItem[]}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => `${option.fullName}`}
      renderOption={(props, option: IStudentModifiedItem) => (
        <li {...props} key={option.id} style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
          <Typography variant="caption">{option.fullName}</Typography>
        </li>
      )}
      noOptionsText={<SearchNotFound query={query} sx={{ bgcolor: 'unset' }} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('search_students')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <Iconify icon="svg-spinners:8-dots-rotate" />
                ) : (
                  <Iconify icon="ion:chevron-down" width="1rem" height="1rem" color="grey.500" />
                )}
              </>
            ),
          }}
        />
      )}
      sx={{
        '& .MuiInputBase-root': {
          maxHeight: '40px',
          padding: '10px 14px 10px 14px !important',
        },
      }}
    />
  );
}
