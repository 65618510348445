/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import Lottie from 'react-lottie';

import { Box } from '@mui/material';

const LottieAnimation: React.FC<any> = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: props.animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: props.bgColor ? props.bgColor : 'transparent',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <Lottie options={defaultOptions} height={props.height} width={props.width} />
    </Box>
  );
};

export default LottieAnimation;
