import { useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import loadingAnimation from 'src/assets/animations/LoadingAnimation.json';

import { LottieAnimation } from 'src/components/animate';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  redirect?: boolean;
};

export default function GuestGuard({ children, redirect = true }: Props) {
  const { loading } = useAuthContext();

  return (
    <>
      {loading ? (
        <LottieAnimation
          animationData={loadingAnimation}
          bgColor="#043F6B"
          height={180}
          width={180}
        />
      ) : (
        <Container redirect={redirect}>{children}</Container>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

function Container({ children, redirect }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo') || paths.root;

  const { authenticated } = useAuthContext();

  const check = useCallback(() => {
    if (authenticated) {
      router.replace(returnTo);
    }
  }, [authenticated, returnTo, router]);

  // useEffect(() => {
  //   if (redirect) {
  //     check();
  //   }
  // }, [check, redirect]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
