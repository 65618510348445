import { memo, forwardRef } from 'react';

import Box from '@mui/material/Box';

// import { ENVIRONMENT } from 'src/config-global';
// import { ENVIRONMENTS } from 'src/constants/misc';

import { ScrollbarProps } from './types';
// import { BANNER_HEIGHT_OFFSET } from '../banner';
import { StyledScrollbar, StyledRootScrollbar } from './styles';

// ----------------------------------------------------------------------

const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>(({ children, sx, ...other }, ref) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (mobile) {
    return (
      <Box ref={ref} sx={{ overflow: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar
        scrollableNodeProps={{
          ref,
        }}
        clickOnTrack={false}
        sx={sx}
        // sx={{ ...sx, top: ENVIRONMENT !== ENVIRONMENTS.prod ? BANNER_HEIGHT_OFFSET : 0 }}
        {...other}
      >
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
});

export default memo(Scrollbar);
