export const CATEGORY_COLORS = ['#E00034', '#FFB000', '#00B2FF', '#00B748', '#FF6600', '#004270'];

// export const TABS = [
//   { name: 'General', iconifyIcon: 'material-symbols:info' },
//   { name: 'Test', iconifyIcon: 'solar:notes-bold' },
//   { name: 'Share', iconifyIcon: 'solar:share-bold' },
// ];

export const getTABS = (t: any) => [
  { name: t('common.general'), iconifyIcon: 'material-symbols:info' },
  { name: t('common.test'), iconifyIcon: 'solar:notes-bold' },
  { name: t('common.share'), iconifyIcon: 'solar:share-bold' },
];
