/* eslint-disable no-nested-ternary */
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';

import { useRouter } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';

import { useTranslate } from 'src/locales';
import { getQuestionMediaTypes } from 'src/constants/questions';
import { NewQuestionSchema } from 'src/schemas/new-edit-question';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { RHFSwitch, RHFUploadBoxCustom } from 'src/components/hook-form';
import InputSectionDetails from 'src/components/custom-input-section-details/input-section-details';

import { ITestQuestion } from 'src/types/staff/tests';
import { IQuestionType, IQuestionItem } from 'src/types/question';

import QuestionTags from './question-tags';
import QuestionContent from './question-content';
import AnswersFields from './question-answer-fields';
import QuestionMediaTabs from './question-media-tabs';
import QuestionExternalUrlInput from './question-media-external-url';
import {
  processAnswers,
  handleQuestionBankSave,
  handleTestQuestionSave,
  processMediaAndMimeType,
} from './question-utils';

type Props = {
  currentQuestion?: IQuestionItem | ITestQuestion;
  isTestQuestion?: boolean;
  handleCloseEditQuestion?: ({ isEditCancelled }: { isEditCancelled?: boolean }) => void;
  setDndItems?: React.Dispatch<React.SetStateAction<any>>;
};

export default function QuestionForm({
  currentQuestion,
  isTestQuestion = false,
  handleCloseEditQuestion,
  setDndItems,
}: Props) {
  const { t } = useTranslate();
  const QUESTION_MEDIA_TYPES = useMemo(() => getQuestionMediaTypes(t), [t]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const router = useRouter();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mdUp = useResponsive('up', 'md');

  const parentMethods = useFormContext();

  const defaultValues = useMemo(
    () => ({
      ...(currentQuestion || {}),
      question: currentQuestion?.question || '',
      questionType: currentQuestion?.questionType || null,
      answers:
        currentQuestion?.answers?.map((answer) => ({
          text: answer.text || '',
          positivePoint: (answer.points > 0 && answer.points) || 0,
          negativePoint: (answer.points < 0 && answer.points) || 0,
        })) || [],
      isPublished: currentQuestion?.isPublished ?? true,
      instruction: currentQuestion?.instruction || '',
      mediaPath: !currentQuestion?.isExternal
        ? currentQuestion?.imagePath ||
          currentQuestion?.audioPath ||
          currentQuestion?.videoPath ||
          null
        : null,
      mimeType: currentQuestion?.mimeType || undefined,
      tags: currentQuestion?.tags || [],
      isExternal: currentQuestion?.isExternal || false,
      externalUrl: currentQuestion?.isExternal
        ? currentQuestion?.imagePath ||
          currentQuestion?.audioPath ||
          currentQuestion?.videoPath ||
          ''
        : '',
      questionId: isTestQuestion ? null : undefined,
      isNewQuestion: isTestQuestion ? currentQuestion?.isNewQuestion || false : undefined,
    }),
    [currentQuestion, isTestQuestion],
  );

  const methods = useForm({
    resolver: yupResolver(NewQuestionSchema),
    defaultValues,
  });

  const { control, setValue, reset, watch } = methods;

  const [mimeTypeField, isExternalField] = watch(['mimeType', 'isExternal']);
  const [currentTab, setCurrentTab] = useState(Object.keys(QUESTION_MEDIA_TYPES)[0]);
  const [editingQuestionMediaTab, setEditingQuestionMediaTab] = useState('');

  useEffect(() => {
    if (currentQuestion?.mimeType) {
      let currMedia = '';
      Object.entries(QUESTION_MEDIA_TYPES).find(([mediaType, config]) => {
        if (currentQuestion.mimeType! in config.accept) {
          currMedia = mediaType;
          return true;
        }
        return false;
      });
      setEditingQuestionMediaTab(currMedia);
      setCurrentTab(currMedia);
    }
  }, [QUESTION_MEDIA_TYPES, currentQuestion]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('mediaPath', newFile, { shouldValidate: true });
        setValue('mimeType', newFile.type);
        setValue('externalUrl', '');
        setValue('isExternal', false);
      }
    },
    [setValue],
  );

  const handleRemoveFile = useCallback(() => {
    setValue('mediaPath', null);
  }, [setValue]);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const handleSaveQuestion = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsSubmitting(true);

    // Trigger validation
    const isValid = await methods.trigger();

    if (!isValid) {
      setIsSubmitting(false);
      return; // Stop here if the form is invalid
    }

    try {
      const data = methods.getValues();
      const { answers, questionType, tags, isExternal, ...restFormData } = data;

      const mediaAndMimeType = await processMediaAndMimeType(
        data,
        editingQuestionMediaTab,
        QUESTION_MEDIA_TYPES,
        currentTab,
        enqueueSnackbar,
      );
      const { modifiedAnswersObject, totalPoints } = processAnswers(
        answers || [],
        questionType as IQuestionType,
      );

      const finalData: IQuestionItem = {
        ...restFormData,
        questionType: questionType as IQuestionType,
        answers: modifiedAnswersObject,
        tags: tags as string[],
        totalPoints,
        isExternal,
        ...mediaAndMimeType,
      };

      if (isTestQuestion && setDndItems) {
        // For test questions
        handleTestQuestionSave(finalData, setDndItems, parentMethods);
        handleCloseEditQuestion?.({});
      } else {
        // For questions in question bank
        await handleQuestionBankSave(
          finalData,
          currentQuestion,
          queryClient,
          reset,
          enqueueSnackbar,
          t,
          router,
        );
      }
    } catch (error) {
      console.error('Error saving question:', error);
      enqueueSnackbar(t('common.error_occurred'), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    reset();
    if (isTestQuestion) {
      handleCloseEditQuestion?.({ isEditCancelled: true });
    }
  };

  const renderInputFieldsAndMediaTabs = (
    <>
      {!isTestQuestion && mdUp && (
        <InputSectionDetails
          label={t('common.content')}
          details={`${t('common.title')}, ${t('common.content')}, ${t('common.media')}`}
        />
      )}

      {isTestQuestion ? (
        <Grid container spacing={3} sx={{ width: '100%', margin: 0 }}>
          <Grid xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: '900', lineHeight: '24px', color: 'info.dark' }}
            >
              {`Question's content`}
            </Typography>
          </Grid>
          <Grid xs={12} md={6}>
            <QuestionContent />
          </Grid>
          <Grid xs={12} md={6}>
            <Stack spacing={1} width="100%">
              <QuestionMediaTabs
                currentTab={currentTab}
                handleChangeTab={handleChangeTab}
                QUESTION_MEDIA_TYPES={QUESTION_MEDIA_TYPES}
              />

              <RHFUploadBoxCustom
                thumbnail
                acceptingType={
                  QUESTION_MEDIA_TYPES[currentTab as keyof typeof QUESTION_MEDIA_TYPES].accept
                }
                name="mediaPath"
                onDrop={handleDrop}
                onDelete={handleRemoveFile}
                mimeType={mimeTypeField}
                disabled={isExternalField}
                currentTab={currentTab}
                mediaPath={watch('mediaPath')}
              />

              <QuestionExternalUrlInput
                isExternalField={isExternalField}
                mimeTypeField={mimeTypeField}
                currentTab={currentTab}
                control={control}
                setValue={setValue}
                QUESTION_MEDIA_TYPES={QUESTION_MEDIA_TYPES}
              />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            {!mdUp && <CardHeader title={t('common.content')} />}

            <Stack spacing={3}>
              <QuestionContent />

              <Stack spacing={1} width="100%">
                <QuestionMediaTabs
                  currentTab={currentTab}
                  handleChangeTab={handleChangeTab}
                  QUESTION_MEDIA_TYPES={QUESTION_MEDIA_TYPES}
                />

                <RHFUploadBoxCustom
                  thumbnail
                  acceptingType={
                    QUESTION_MEDIA_TYPES[currentTab as keyof typeof QUESTION_MEDIA_TYPES].accept
                  }
                  name="mediaPath"
                  onDrop={handleDrop}
                  onDelete={handleRemoveFile}
                  mimeType={mimeTypeField}
                  disabled={isExternalField}
                  currentTab={currentTab}
                  mediaPath={watch('mediaPath')}
                />
                <QuestionExternalUrlInput
                  isExternalField={isExternalField}
                  mimeTypeField={mimeTypeField}
                  currentTab={currentTab}
                  control={control}
                  setValue={setValue}
                  QUESTION_MEDIA_TYPES={QUESTION_MEDIA_TYPES}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>
      )}
    </>
  );

  const renderAnswerTypes = (
    <>
      {!isTestQuestion && mdUp && (
        <InputSectionDetails
          label={t('common.answers')}
          details={t('questions_edit_page.select_answer_typology')}
        />
      )}

      <Grid xs={12} md={isTestQuestion ? 12 : 8}>
        {isTestQuestion ? (
          <>
            <Typography
              variant="body1"
              sx={{ fontWeight: '900', lineHeight: '24px', color: 'info.dark', mt: 1, mb: 1 }}
            >
              {t('test_edit_page.questions.answer_typology')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: '400', lineHeight: '22px', color: 'grey.600' }}
            >
              {t('test_edit_page.questions.select_answer_typology')}
            </Typography>
            <AnswersFields />
          </>
        ) : (
          <Card>
            {!mdUp && <CardHeader title={t('common.answers')} />}
            <AnswersFields />
          </Card>
        )}
      </Grid>
    </>
  );

  const renderTags = (
    <>
      {!isTestQuestion && mdUp && (
        <InputSectionDetails label="Tags" details="Add tags and create the question" />
      )}

      <Grid xs={12} md={isTestQuestion ? 12 : 8}>
        {!isTestQuestion ? (
          <Card sx={{ p: 3 }}>
            {!mdUp && <CardHeader title="Tags" />}
            <QuestionTags />
          </Card>
        ) : (
          <QuestionTags />
        )}
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {!isTestQuestion && mdUp && <Grid md={4} />}
      <Grid
        xs={12}
        md={isTestQuestion ? 12 : 8}
        gap={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: isTestQuestion ? 'flex-end' : 'flex-start',
          mt: 2,
        }}
      >
        {!isTestQuestion && (
          <Box sx={{ flexGrow: 1, pl: 3 }}>
            <RHFSwitch name="isPublished" label="Publish" />
          </Box>
        )}

        <Button
          size="large"
          color="inherit"
          variant="outlined"
          sx={{ alignSelf: 'center' }}
          onClick={handleCancelClick}
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          onClick={handleSaveQuestion}
          variant="contained"
          size="large"
          sx={{
            backgroundColor: (theme) => theme.palette.success.main,
            '&:hover': {
              backgroundColor: (theme) => `${alpha(theme.palette.success.main, 0.85)}`,
            },
          }}
          loading={isSubmitting}
        >
          {isTestQuestion
            ? t('test_edit_page.questions.save_question')
            : !currentQuestion?.id
              ? 'Create'
              : 'Save'}
        </LoadingButton>
      </Grid>
    </>
  );

  const formContent = (
    <FormProvider {...methods}>
      <Grid container spacing={3} sx={{ width: '100%', margin: '0' }}>
        {renderInputFieldsAndMediaTabs}
        {renderAnswerTypes}
        {renderTags}
        {renderActions}
      </Grid>
    </FormProvider>
  );

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: isTestQuestion
      ? currentQuestion?.id || (currentQuestion as ITestQuestion)._tempId!
      : 'standalone-question',
  });

  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
        transition,
      }
    : undefined;

  if (isTestQuestion) {
    return (
      <Stack ref={setNodeRef} style={style} alignItems="center" direction="row" width="100%">
        <Stack alignItems="center" direction="row">
          <Iconify
            icon="akar-icons:dot-grid"
            width="20px"
            color="grey.600"
            {...listeners}
            {...attributes}
            sx={{ cursor: 'pointer' }}
          />
          <Typography
            variant="body2"
            sx={{ mx: '0.75rem', color: 'grey.600', fontWeight: '900', fontSize: '16px' }}
          >
            {(currentQuestion as ITestQuestion).questionNumber}
          </Typography>
        </Stack>
        {isTestQuestion ? (
          <Card
            sx={{
              p: '1rem',
              ml: '2.750rem',
              display: 'flex',
              gap: '2rem',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {formContent}
          </Card>
        ) : (
          <Box sx={{ flexGrow: 1, width: '100%' }}>{formContent}</Box>
        )}
      </Stack>
    );
  }

  return formContent;
}
