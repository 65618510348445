import { useMemo, useState } from 'react';

import { Tab, Tabs, Container } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useSearchParams } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { getTABS } from 'src/constants/categories';
import { useGetCategoryById, shareCategoryToStaff } from 'src/api/categories';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import ShareCard from 'src/components/sharing/share-card-new';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import CategoryEditViewTests from '../category-edit-view-tests';
import CategoryEditViewGeneral from '../category-edit-view-general';

export default function CategoryEditView({ id }: { id: string }) {
  const settings = useSettingsContext();
  const { t } = useTranslate();

  const TABS = useMemo(() => getTABS(t), [t]);

  const searchParam = useSearchParams();
  const isNew = searchParam.get('new') === 'true';

  const { data: category, isPending } = useGetCategoryById(id);

  const [currentTab, setCurrentTab] = useState<string>(TABS[isNew ? 1 : 0].name);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={category?.name}
        links={[
          { name: t('common.dashboard'), href: '#' },
          { href: paths.staff.test.categories.root, name: t('common.categories') },
          { name: category?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Tabs value={currentTab} onChange={(e, newVal) => setCurrentTab(newVal)}>
        {TABS.map((type) => (
          <Tab
            key={type.name}
            sx={{ fontWeight: 'fontWeightBold' }}
            value={type.name}
            iconPosition="start"
            icon={<Iconify icon={type.iconifyIcon} width={24} />}
            label={type.name}
          />
        ))}
      </Tabs>

      {/* General View */}
      {currentTab === t('common.general') && category && (
        <CategoryEditViewGeneral category={category} />
      )}

      {/* Test View */}
      {currentTab === t('common.test') && !isPending && category && category?.tests && (
        <CategoryEditViewTests id={id} tests={category.tests} />
      )}

      {/* Share View */}
      {currentTab === t('common.share') && (
        <ShareCard
          title={t('categories_listing_page.share_category')}
          linkToShare={paths.staff.test.categories.editView(id)}
          isSharingToStaff
          onShare={shareCategoryToStaff}
          addtionalPayloadToShareFn={{
            categoryName: category?.name ?? '',
          }}
        />
      )}
    </Container>
  );
}
