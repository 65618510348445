import React from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { StudentDetailsView } from 'src/sections/staff/student/view';

export default function StudentDetailsPage() {
  const params = useParams();
  return (
    <>
      <Helmet>
        <title>Student: Details</title>
      </Helmet>

      {params?.id && <StudentDetailsView id={params.id} />}
    </>
  );
}
