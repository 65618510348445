import { useState, useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import loadingAnimation from 'src/assets/animations/LoadingAnimation.json';

import { LottieAnimation } from 'src/components/animate';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  roles?: string[];
};

export default function StaffGuard({ children, roles = [] }: Props) {
  const { loading } = useAuthContext();

  return (
    <>
      {loading ? (
        <LottieAnimation
          animationData={loadingAnimation}
          bgColor="#043F6B"
          height={180}
          width={180}
        />
      ) : (
        <Container roles={roles}>{children}</Container>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

function Container({ children, roles = [] }: Props) {
  const router = useRouter();

  const { authenticated, user } = useAuthContext();
  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: `${window.location.pathname}${window.location.search}`,
      }).toString();

      const loginPath = paths.login;

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    } else if (roles.length > 0 && !roles.includes(user?.role)) {
      router.replace('/403');
    } else {
      setChecked(true);
    }
  }, [authenticated, roles, router, user?.role]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
