import { isEqual } from 'lodash';
import { useMemo, useState, useCallback } from 'react';

import { Card, Table, Button, Container, TableBody, TableContainer } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useDebounce } from 'src/hooks/use-debounce';

import { removeFalsyValuesFromObject } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { TABLE_DEFAULTS } from 'src/constants/misc';
import { useGetQuestionList } from 'src/api/question';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import EmptyContent from 'src/components/empty-content/empty-content';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { IListFilters } from 'src/types/misc';
import {
  IQuestionItem,
  IQuestionTableFilters,
  IQuestionTableFilterValue,
} from 'src/types/question';

import QuestionTableRow from '../question-table-row';
import QuestionTableToolbar from '../question-table-toolbar';
import QuestionTableFiltersResult from '../question-table-filters-result';

const defaultFilters: IQuestionTableFilters = {
  questionType: '',
  exactPoints: '',
  searchText: '',
};

const POINT_OPTIONS = Array.from({ length: 10 }, (_, i) => ({
  value: `${i + 1}`,
  label: `${i + 1}`,
}));

export default function QuestionsEditView() {
  const settings = useSettingsContext();
  const { t } = useTranslate();

  const TABLE_HEAD = useMemo(
    () => [
      { id: 'questionType', label: t('common.type'), width: 180, align: 'left' },
      { id: 'question', label: t('common.name'), width: 50, align: 'left' },
      { id: 'tags', label: t('common.tags'), width: 272, align: 'left' },
      { id: 'totalPoints', label: t('common.points'), width: 91, align: 'center' },
      { id: 'isPublished', label: t('common.actions'), align: 'left' },
      { id: 'createdAt', label: t('common.created_at'), align: 'left' },
      { id: '' },
    ],
    [t],
  );

  const table = useTable({
    defaultRowsPerPage: TABLE_DEFAULTS.ROWS_PER_PAGE,
    defaultOrder: 'desc',
  });

  // const openFilters = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);

  const canReset = !isEqual(defaultFilters, filters);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) => ({
      ...(fetchAll
        ? { all: true }
        : {
            page: table.page + 1,
            pageSize: table.rowsPerPage,
          }),
      sortColumn: table.orderBy,
      sortOrder: table.order,
    }),
    [table],
  );

  const apiFiltersHandler = useCallback(() => {
    const allFilters: Partial<IQuestionTableFilters> = { ...filters };
    if (allFilters.searchText) {
      delete allFilters.searchText;
    }

    return allFilters;
  }, [filters]);

  const [debouncedSearchText] = useDebounce(filters.searchText || '', 1500);

  const apiFilters: IListFilters<IQuestionTableFilters> = useMemo(() => {
    const allFilters = apiFiltersHandler();
    const tableFilters = tableFiltersHandler({ fetchAll: false });

    return removeFalsyValuesFromObject({
      ...allFilters,
      ...tableFilters,
      searchQuery: debouncedSearchText,
    });
  }, [apiFiltersHandler, tableFiltersHandler, debouncedSearchText]);

  const { data, isFetching }: any = useGetQuestionList({
    ...apiFilters,
  });

  const {
    // currentPage = TABLE_DEFAULTS.CURRENT_PAGE,
    // totalPages = TABLE_DEFAULTS.TOTAL_PAGES,
    questions: tableData = [],
    totalCount = 0,
    // pageSize = TABLE_DEFAULTS.ROWS_PER_PAGE,
  }: {
    questions: IQuestionItem[];
    totalCount: number;
  } = useMemo(
    () => ({
      // currentPage: data?.currentPage,
      // totalPages: data?.totalPages,
      questions: data?.data,
      totalCount: data?.totalCount,
      // pageSize: data?.pageSize,
    }),
    [data],
  );

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const handleFilters = useCallback(
    (name: string, value: IQuestionTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('common.questions')}
        links={[{ name: t('common.dashboard'), href: '#' }, { name: t('common.questions') }]}
        action={
          tableData.length > 0 && (
            <Button
              component={RouterLink}
              href={paths.staff.test.questions.new}
              variant="contained"
              sx={{
                backgroundColor: 'customColors.custom1',
                '&:hover': {
                  backgroundColor: 'customColors.custom4',
                },
              }}
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {t('common.new_question')}
            </Button>
          )
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card>
        {isFetching || debouncedSearchText.length > 0 || canReset || tableData.length ? (
          <>
            <QuestionTableToolbar
              filters={filters}
              onFilters={handleFilters}
              //
              pointOptions={POINT_OPTIONS}
            />

            {canReset && (
              <QuestionTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                //
                onResetFilters={handleResetFilters}
                //
                results={totalCount}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    // TODO:
                    rowCount={20}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                  />

                  <TableBody>
                    {tableData.length > 0 &&
                      tableData.map((row) => (
                        <QuestionTableRow key={row.id} row={row} filters={apiFilters} />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={totalCount}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              //
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </>
        ) : (
          <EmptyContent
            filled
            title={`0 ${t('common.questions')}`}
            imgUrl="/assets/icons/empty/ic_chat.svg"
            action={
              <Button
                variant="contained"
                sx={{
                  mt: 1,
                  backgroundColor: 'customColors.custom1',
                  '&:hover': {
                    backgroundColor: 'customColors.custom4',
                  },
                }}
                LinkComponent={RouterLink}
                href={paths.staff.test.questions.new}
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                {t('common.new_question')}
              </Button>
            }
            sx={{ p: 5 }}
          />
        )}
      </Card>
    </Container>
  );
}
