import React, { Children } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { Box, Button, Tooltip, TableRow, TableCell, Typography, IconButton } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { shareResult } from 'src/api/result';
import queryKeys from 'src/constants/query-keys';
import { deleteStudentResults } from 'src/api/students';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import ShareDialog from 'src/components/sharing/share-dialog-new';

import { ITableColumns } from 'src/types/misc';

type Props = {
  row: any;
  headLabel: Partial<ITableColumns>[] | any;
};

export default function ResultTabTableRow({ row, headLabel }: Props) {
  const shareDialog = useBoolean(false);
  const confirm = useBoolean();
  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const handleDeleteStudentResult = async () => {
    try {
      confirm.onFalse();
      await deleteStudentResults(row.id);
      enqueueSnackbar(t('student_listing_page.student_result_deleted'));
      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.students.fetchStudentResults] });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const handleShare = async () => {
    shareDialog.onTrue();
  };

  const tableActions = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('student_listing_page.share_result')} placement="top" arrow>
        <IconButton onClick={handleShare}>
          <Iconify icon="solar:share-bold" color="customColors.custom1" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  const tableDeleteAction = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('student_listing_page.delete_result')} placement="top" arrow>
        <IconButton onClick={() => confirm.onTrue()} sx={{ color: 'error.dark' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  return (
    <>
      <TableRow hover>
        {Children.toArray(
          headLabel.map((headCell: any) => {
            if (headCell?.id) {
              if (headCell.id === 'testLevelName') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Typography variant="subtitle2" fontWeight={900}>
                      {row?.[headCell?.id] || ''}
                    </Typography>
                  </TableCell>
                );
              }

              if (headCell.id === 'createdAt') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      color: 'text.secondary',
                    }}
                    align={headCell.align || 'left'}
                  >
                    {row?.[headCell?.id] || ''}
                  </TableCell>
                );
              }

              if (headCell.id === 'scorePercentage') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {`${row?.[headCell?.id]}%` || ''}
                  </TableCell>
                );
              }

              if (headCell.id === 'actions') {
                return <React.Fragment key="actions">{tableActions}</React.Fragment>;
              }

              return (
                <TableCell
                  sx={{ whiteSpace: 'nowrap', width: headCell.width, minWidth: headCell.minWidth }}
                  align={headCell.align || 'left'}
                >
                  {row?.[headCell?.id] || ''}
                </TableCell>
              );
            }

            return <React.Fragment key="deleteAction">{tableDeleteAction}</React.Fragment>;
          }),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" width={24} height={24} />{' '}
            <Typography variant="h6" fontWeight={900}>
              {t('student_listing_page.delete_result_title')}
            </Typography>
          </Box>
        }
        content={
          <Typography variant="subtitle1" fontWeight={400}>
            {t('student_listing_page.sure_result_delte')}
          </Typography>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteStudentResult}>
            {t('common.delete')}
          </Button>
        }
      />

      <ShareDialog
        dialog={shareDialog}
        title={t('student_listing_page.share_result')}
        onShare={shareResult}
        enableUserTypeSelection
        addtionalPayloadToShareFn={{
          resultId: row.id,
        }}
        studentLinkToShare={paths.results.resultDetails(row.id)}
        staffLinkToShare={paths.staff.results}
      />
    </>
  );
}
