import React from 'react';
import ReactPlayer from 'react-player';

import { Box, Theme, SxProps, useTheme, CardMedia, useMediaQuery } from '@mui/material';

import { generateMediaUrl } from 'src/utils/misc';

import Image from 'src/components/image';

import { ITestQuestion } from 'src/types/staff/tests';

const MAX_WIDTH = '12rem';
const HEIGHT = '6rem';

const TestQuestionMedia = ({
  currentQuestion,
  sx = {},
}: {
  currentQuestion: ITestQuestion;
  sx?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const getWidth = () => {
    if (isXs) return '9rem';
    if (isMd) return '10rem';
    if (isLg) return MAX_WIDTH;
    return '9rem';
  };

  return (
    <Box sx={sx}>
      {currentQuestion?.imagePath && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Image
            src={
              currentQuestion?.isExternal
                ? currentQuestion?.imagePath
                : generateMediaUrl(currentQuestion?.imagePath)
            }
            alt={currentQuestion?.question || ''}
            sx={{
              height: HEIGHT,
              objectFit: 'contain',
              maxWidth: MAX_WIDTH,
            }}
          />
        </Box>
      )}
      {currentQuestion?.videoPath &&
        (currentQuestion?.isExternal ? (
          <ReactPlayer
            url={currentQuestion?.videoPath}
            style={{
              maxWidth: MAX_WIDTH,
            }}
            width="100%"
            height={HEIGHT}
          />
        ) : (
          <CardMedia
            component="video"
            controls
            src={generateMediaUrl(currentQuestion?.videoPath)}
            sx={{
              height: HEIGHT,
              objectFit: 'contain',
              maxWidth: MAX_WIDTH,
            }}
          />
        ))}
      {currentQuestion?.audioPath && (
        <ReactPlayer
          url={
            currentQuestion?.isExternal
              ? currentQuestion?.audioPath
              : generateMediaUrl(currentQuestion?.audioPath)
          }
          controls
          style={{
            maxWidth: getWidth(),
          }}
          height="3rem"
          width={getWidth()}
        />
      )}
    </Box>
  );
};

export default TestQuestionMedia;
