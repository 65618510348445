import React, { useState } from 'react';

import { Chip } from '@mui/material';

import { useDebounce } from 'src/hooks/use-debounce';

import { lightenHexColor } from 'src/utils/lighten-hex-color';

import { useTranslate } from 'src/locales';
import { customColors } from 'src/theme/palette';
import { useGetQuestionTags } from 'src/api/question';

import { RHFAutocomplete } from 'src/components/hook-form';

const QuestionTags: React.FC = () => {
  const { t } = useTranslate();
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText || '', 1000);
  const { data: existingTags = [], isLoading } = useGetQuestionTags(debouncedSearchText);

  return (
    <RHFAutocomplete
      name="tags"
      label={t('common.tags')}
      placeholder={`+ ${t('common.tags')}`}
      multiple
      freeSolo
      options={existingTags}
      onInputChange={(_, val) => setSearchText(val)}
      loading={isLoading}
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {option}
        </li>
      )}
      renderTags={(selected, getTagProps) =>
        selected.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option}
            label={option}
            size="small"
            variant="soft"
            sx={{
              backgroundColor: lightenHexColor(customColors.custom2, 84),
              color: 'customColors.custom2',
              '&:hover': {
                backgroundColor: lightenHexColor(customColors.custom2, 84),
              },
            }}
          />
        ))
      }
    />
  );
};

export default QuestionTags;
