import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { USER_ROLES } from 'src/constants/user-roles';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  tourWhite: icon('ic_tour_white'),
  test: icon('ic_test'),
  results: icon('ic_results'),
  settings: icon('ic_settings'),
  collaborators: icon('ic_collab'),
  notification: icon('ic_bell'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();

  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('staff_navigation.overview'),
        items: [
          {
            title: t('common.results'),
            path: paths.staff.results,
            icon: ICONS.results,
          },
          {
            title: t('common.test'),
            path: paths.staff.test.root,
            icon: ICONS.test,
            children: [
              { title: t('common.categories'), path: paths.staff.test.categories.root },
              {
                title: t('common.tests'),
                path: paths.staff.test.tests.root,
              },
              ...(!isAnalyst
                ? [
                    {
                      title: t('common.questions'),
                      path: paths.staff.test.questions.root,
                    },
                  ]
                : []),
            ],
          },
          {
            title: t('common.students'),
            path: paths.staff.students,
            icon: ICONS.user,
          },
          {
            title: t('common.premises'),
            path: paths.staff.premises,
            icon: ICONS.tourWhite,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('staff_navigation.management'),
        items: [
          {
            title: t('common.collaborators'),
            path: paths.staff.collaborators,
            icon: ICONS.collaborators,
          },
          ...(!isAnalyst
            ? [
                {
                  title: t('common.notifications'),
                  path: paths.staff.notifications.root,
                  icon: ICONS.notification,
                  children: [
                    { title: t('common.email'), path: paths.staff.notifications.email },
                    { title: t('common.settings'), path: paths.staff.notifications.settings },
                  ],
                },
              ]
            : []),
          {
            title: t('common.account'),
            path: paths.staff.account,
            icon: ICONS.settings,
          },
        ],
      },
    ],
    [t, isAnalyst],
  );

  return data;
}
