import * as Yup from 'yup';
import React, { useMemo } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import queryKeys from 'src/constants/query-keys';
import { createPremises } from 'src/api/premises';
import { USER_ROLES } from 'src/constants/user-roles';
import { useGetCollaboratorsList } from 'src/api/collaborators';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFTextField, RHFAutocomplete } from 'src/components/hook-form';

import { IUseBooleanReturnType } from 'src/types/misc';

type props = {
  dialog: IUseBooleanReturnType;
};

function CreatePremisesDialog({ dialog }: props) {
  const { data: collaborator } = useGetCollaboratorsList({ all: true, role: USER_ROLES.ANALYST });
  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const COLLABORATORS_OPTIONS = useMemo(
    () =>
      // @ts-ignore
      (collaborator?.data || []).map((pd: { id: string; fullName: string }) => ({
        label: pd.fullName,
        value: pd.id,
      })) || [],
    [collaborator],
  );

  const inviteCollaboratorSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required(t('premises_edit_page.collaborator_name_required')),
    email: Yup.string()
      .email(t('premises_edit_page.email_valid'))
      .required(t('common.email_required')),
    city: Yup.string(),
    address: Yup.string(),
    analystIds: Yup.array().of(Yup.mixed<any>()),
    isActive: Yup.boolean(),
    mailchimpId: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      email: '',
      mailchimpId: '',
      city: '',
      address: '',
      analystIds: [],
      isActive: false,
    }),
    [],
  );

  const methods = useForm({
    resolver: yupResolver<any>(inviteCollaboratorSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formatData = {
        name: data.name,
        email: data.email,
        isActive: data?.isActive,
        mailchimpId: data.mailchimpId,
        address: data.address,
        city: data.city,
        analystIds: data?.analystIds.map((ri: any) => ri.value),
      };

      await createPremises(formatData);

      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.premises.fetchAllPremises] });

      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar(t('common.create_success'));
      dialog.onFalse();
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        reset();
      }
    }
  });

  const dialogContent = (
    <DialogContent>
      <Stack spacing={3}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ width: '100%' }}
          spacing={2}
          paddingTop={1}
        >
          <RHFTextField
            name="name"
            sx={{
              width: '100%',
            }}
            label={t('common.name')}
            InputLabelProps={{ style: { fontWeight: 900 } }}
            required
          />

          <RHFTextField
            name="email"
            sx={{
              width: '100%',
            }}
            label={t('common.email')}
            InputLabelProps={{ style: { fontWeight: 900 } }}
            required
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ width: '100%' }}
          spacing={2}
          paddingTop={1}
        >
          <RHFTextField
            name="address"
            sx={{
              width: '100%',
            }}
            label={t('common.address')}
            InputLabelProps={{ style: { fontWeight: 900 } }}
          />

          <RHFTextField
            name="city"
            sx={{
              width: '100%',
            }}
            label={t('common.city')}
            InputLabelProps={{ style: { fontWeight: 900 } }}
          />
        </Stack>
        <RHFTextField
          name="mailchimpId"
          sx={{
            width: '100%',
          }}
          label="ID mailchimp"
          InputLabelProps={{ style: { fontWeight: 900 } }}
        />

        <RHFAutocomplete
          multiple
          forcePopupIcon={false}
          name="analystIds"
          options={COLLABORATORS_OPTIONS}
          value={values?.analystIds}
          label={t('common.analyst')}
          InputLabelProp={{ style: { fontWeight: 900 } }}
          sx={{
            '& .MuiInputLabel-root': {
              fontWeight: 900,
            },
          }}
          ChipProps={{
            style: { backgroundColor: '#00B8D941', color: '#006C9C', fontWeight: 900 },
          }}
          isOptionEqualToValue={(option, value) =>
            value === '' ? true : option.value === value.value
          }
        />

        <Controller
          name="isActive"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <FormControlLabel control={<Switch {...field} sx={{}} />} label={t('common.live')} />
            </div>
          )}
        />
      </Stack>
    </DialogContent>
  );

  const dialogAction = (
    <DialogActions>
      <Stack direction="row" spacing={1.5} justifyContent="end">
        <Button variant="outlined" size="medium" onClick={dialog.onFalse}>
          {t('common.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="success"
          size="medium"
          loading={isSubmitting}
        >
          {!collaborator ? t('common.invite') : t('common.save')}
        </LoadingButton>
      </Stack>
    </DialogActions>
  );
  return (
    <Dialog
      open={dialog.value}
      sx={{
        '& .MuiDialog-paper': {
          width: { xs: '100%', sm: '90%', md: '50%' },
          maxWidth: 'none',
          minHeight: '40%',
        },
      }}
      onClose={dialog.onFalse}
    >
      <DialogTitle fontWeight={900}>{t('common.new_premises')}</DialogTitle>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {dialogContent}
        {dialogAction}
      </FormProvider>
    </Dialog>
  );
}

export default CreatePremisesDialog;
