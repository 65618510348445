import * as Yup from 'yup';

import {
  POSITIVE_POINTS,
  NEGATIVE_POINTS,
  ANSWERS_TYPES_CONFIG,
  QUESTION_MEDIA_TYPES,
} from 'src/constants/questions';

import { IQuestionType, QUESTION_TYPES } from 'src/types/question';

export const NewQuestionSchema = Yup.object().shape({
  question: Yup.string().required('Question is required'),
  questionType: Yup.string()
    .oneOf([...Object.values(QUESTION_TYPES)], 'Answer type cannot be empty')
    .required('Answer type cannot be empty')
    .defined()
    .nullable()
    .test('is-valid-or-null', 'Answer type cannot be empty', (value) => value !== null),
  answers: Yup.lazy(() =>
    Yup.array()
      .of(
        Yup.object({
          text: Yup.string().required('Answer text cannot be empty'),
          positivePoint: Yup.number().oneOf(POSITIVE_POINTS, 'choose a valid value').default(0),
          negativePoint: Yup.number().oneOf(NEGATIVE_POINTS, 'choose a valid value').default(0),
        }),
      )
      .test('answersCountCheck', (value, context) => {
        const { questionType } = context.parent as { questionType: IQuestionType };

        if (!questionType) return true;

        const { minimumAnswersRequired, minimumRightAnswersRequired } =
          ANSWERS_TYPES_CONFIG[questionType];

        const answersLength = value?.length;

        if (!answersLength || answersLength < minimumAnswersRequired) {
          return context.createError({
            message: `Minimum ${minimumAnswersRequired} answers required`,
          });
        }

        // Track the balance of minimum right answers required
        let balanceMinRightAnswers = minimumRightAnswersRequired;
        let errorMessage = null;

        value?.some(({ positivePoint, negativePoint }) => {
          if (positivePoint > 0) balanceMinRightAnswers -= 1;
          if (negativePoint < 0 && positivePoint !== 0) {
            errorMessage = 'An answer cannot have both positive and negative points';
            return true;
          }
          return false;
        });

        // Check if minimum right answers are provided
        if (balanceMinRightAnswers > 0)
          errorMessage = `At least ${minimumRightAnswersRequired} correct answers required`;

        if (errorMessage) {
          return context.createError({
            message: errorMessage,
          });
        }

        // All validations passed
        return true;
      }),
  ),
  isPublished: Yup.boolean().default(true).required(),
  isExternal: Yup.boolean().default(false).required(),
  // not required
  instruction: Yup.string(),
  mediaPath: Yup.mixed<any>().notRequired(),
  externalUrl: Yup.string().url('external url must be a valid url').nullable(),
  mimeType: Yup.string()
    .oneOf(
      Object.keys(QUESTION_MEDIA_TYPES).reduce((types: string[], key) => {
        const acceptObj = QUESTION_MEDIA_TYPES[key].accept;
        types.push(...Object.keys(acceptObj));
        return types;
      }, []),
    )
    .when('mediaPath', {
      is: (exists: any) => !!exists,
      then: (schema) => schema.required('mime type is required'),
    })
    .when('externalUrl', {
      is: (exists: any) => !!exists,
      then: (schema) => schema.required('mime type is required'),
    }),
  tags: Yup.array().of(Yup.string()),
});
