export interface ICollaboratorsTableFilters {
  role: string;
  searchText: string;
}

export type ICollaboratorsTableFilterValue =
  | ICollaboratorsTableFilters['role']
  | ICollaboratorsTableFilters['searchText'];

interface ICollaboratorPremise {
  premise: {
    name: string;
    id: string;
  };
}

export type ICollaboratorsItem = {
  id: string;
  role: string;
  email: string;
  fullName: string;
  name: string;
  surname: string;
  city: string;
  phone: string;
  premises: ICollaboratorPremise[];
};

export type ICollaboratorsCreateItem = {
  id?: string;
  role: string;
  email: string;
  name: string;
  surname: string;
  premises: any[];
};

export const roleColors = {
  analyst: 'primary.lighter',
  admin: 'customColors.custom2',
};
