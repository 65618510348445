import { Accept } from 'react-dropzone';

export type IQuestionTableFilterValue =
  | IQuestionTableFilters['questionType']
  | IQuestionTableFilters['exactPoints']
  | string;

export type IQuestionAnswer = {
  text: string;
  points: number;
};

export const QUESTION_TYPES = {
  Single: 'single',
  Multiple: 'multiple',
  Text: 'text',
} as const;

export type IQuestionType = (typeof QUESTION_TYPES)[keyof typeof QUESTION_TYPES];

export type IQuestionItem = {
  id?: string;
  question: string;
  answers?: IQuestionAnswer[];
  instruction?: string;
  imagePath?: string | null;
  videoPath?: string | null;
  audioPath?: string | null;
  mimeType?: string | null;
  questionType: IQuestionType;
  tags?: string[];
  isPublished: boolean;
  isExternal: boolean;
  totalPoints?: number;
  createdAt?: string;
  isNewQuestion?: boolean;
};

export interface IQuestionTypeConfig {
  iconifyIcon: string;
  label: string;
  minimumAnswersRequired: number;
  minimumRightAnswersRequired: number;
  answerInputLabel: string;
}

export interface IQuestionMediaType {
  label: string;
  accept: Accept;
  iconifyIcon: string;
  dbField: string;
  // maxSize: number;
}

export type IQuestionTableFilters = {
  questionType: string;
  exactPoints: string;
  searchQuery?: string;
  searchText?: string;
};

export type IQuestion = {
  id: string;
  question: string;
  answers: Array<IQuestionAnswer>;
  totalPoints: number;
  instruction?: string;
  imagePath?: string;
  audioPath?: string;
  videoPath?: string;
  mimeType?: string;
  questionType: string;
  tags: string[];
  isPublished: boolean;
  isArchived: boolean;
  createdAt: Date;
  updatedAt: Date;
};
