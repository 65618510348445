import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import { Theme, SxProps } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { localStorageSetItem } from 'src/utils/storage-available';

import { itLang, useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function LogoutButton({ sx }: Props) {
  const { logout } = useAuthContext();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();

  const handleLogout = async () => {
    try {
      await logout();
      localStorageSetItem('i18nextLng', itLang.value);
      router.replace(paths.login);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('logout_failed'), { variant: 'error' });
    }
  };
  return (
    <Button
      component={RouterLink}
      onClick={handleLogout}
      variant="text"
      sx={{
        color: 'primary.contrastText',
        m: 0,
        p: 0,
        justifyContent: 'flex-end',
        textDecoration: 'underline',
        minWidth: 'max-content',
        lineHeight: '15px',
        fontSize: '14px',
        fontWeight: 900,
        '&.MuiButton-root:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiButton-startIcon': {
          marginRight: '12px',
        },
        ...sx,
      }}
      startIcon={<Iconify icon="material-symbols:logout" sx={{ color: 'primary.contrastText' }} />}
    >
      {t('common.logout')}
    </Button>
  );
}
