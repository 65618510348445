import React from 'react';
import { Helmet } from 'react-helmet-async';

import { CollaboratorsListView } from 'src/sections/staff/collaborators/view';

export default function CollaboratorsList() {
  return (
    <>
      <Helmet>
        <title>Collaborators: List</title>
      </Helmet>

      <CollaboratorsListView />
    </>
  );
}
