import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { localStorageSetItem } from 'src/utils/storage-available';
import { handleUpdateTestAnsUserIdForNonAuthUser } from 'src/utils/test';

import { useAuthContext } from 'src/auth/hooks';
import { USER_ROLES } from 'src/constants/user-roles';
import { LOCAL_STORAGE_KEYS } from 'src/constants/misc';
import { enLang, itLang, useTranslate } from 'src/locales';
import loadingAnimation from 'src/assets/animations/LoadingAnimation.json';
import { PATH_AFTER_LOGIN, PATH_AFTER_LOGIN_FOR_COLLAB } from 'src/config-global';

import Head from 'src/components/head';
import { LottieAnimation } from 'src/components/animate';

import { UserRoles } from 'src/types/user-roles';

export default function CompleteLoginView() {
  const { completeLoginWithEmailLink } = useAuthContext();
  const location = useLocation();
  const router = useRouter();
  const { t } = useTranslate();

  useEffect(() => {
    async function checkForEmailLink() {
      try {
        const role = await completeLoginWithEmailLink();
        await handleUpdateTestAnsUserIdForNonAuthUser();

        const testSubmissionURL = localStorage.getItem(LOCAL_STORAGE_KEYS.TEST_SUBMISSION_URL);
        let redirectRoute = paths.staff.root;
        const lang = role !== USER_ROLES.STUDENT ? enLang.value : itLang.value;

        localStorageSetItem('i18nextLng', lang);

        if (testSubmissionURL) {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.TEST_SUBMISSION_URL);
          redirectRoute = testSubmissionURL;
        } else if (role === USER_ROLES.STUDENT) {
          redirectRoute = PATH_AFTER_LOGIN;
        } else if ([USER_ROLES.ADMIN, USER_ROLES.ANALYST].includes(role as UserRoles)) {
          redirectRoute = PATH_AFTER_LOGIN_FOR_COLLAB;
        }

        router.replace(redirectRoute);

        // TODO: navigate to admin area if user role is admin
      } catch (error) {
        enqueueSnackbar(t('complete_login_page.login_failed'), { variant: 'error' });
        router.replace(paths.login);
      }
    }

    checkForEmailLink();
  }, [location, completeLoginWithEmailLink, router, t]);

  return (
    <>
      <Head title={t('complete_login_page.page_title')} />
      <Stack
        sx={{ minHeight: '100vh', backgroundColor: 'primary.main' }}
        alignItems="center"
        justifyContent="center"
      >
        <LottieAnimation
          animationData={loadingAnimation}
          // bgColor="#043F6B"
          height={180}
          width={180}
        />
      </Stack>
    </>
  );
}
