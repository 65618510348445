import v from 'voca';

import { UserRoles } from 'src/types/user-roles';

export const USER_ROLES: Record<string, UserRoles> = {
  ADMIN: 'admin',
  ANALYST: 'analyst',
  STUDENT: 'student',
};

export const USER_ROLES_OPTIONS = [
  {
    label: v.titleCase(USER_ROLES.ADMIN),
    value: USER_ROLES.ADMIN,
  },
  {
    label: v.titleCase(USER_ROLES.ANALYST),
    value: USER_ROLES.ANALYST,
  },
  {
    label: v.titleCase(USER_ROLES.STUDENT),
    value: USER_ROLES.STUDENT,
  },
];
