import { Helmet } from 'react-helmet-async';

import { TestsListView } from 'src/sections/staff/tests/tests/view';

// ----------------------------------------------------------------------

export default function TestsPage() {
  return (
    <>
      <Helmet>
        <title>Tests: List</title>
      </Helmet>

      <TestsListView />
    </>
  );
}
