import { ChangeEvent, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { TextField, InputAdornment } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useTranslate } from 'src/locales';
import { useGetCategoriesList } from 'src/api/categories';

import Iconify from 'src/components/iconify';

import { ITestsTableFilters, ITestsTableFilterValue } from 'src/types/staff/tests';

// ----------------------------------------------------------------------

type Props = {
  filters: ITestsTableFilters;
  onFilters: (name: string, value: ITestsTableFilterValue) => void;
};

export default function TestsTableToolbar({ filters, onFilters }: Props) {
  const { t } = useTranslate();

  const { data: categories = [] } = useGetCategoriesList();

  const handleFilter = useCallback(
    (
      event:
        | SelectChangeEvent<ITestsTableFilterValue>
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
    ) => {
      const { value } = event.target;
      if (field === 'categoryId') {
        const selectedCategory = (categories as unknown as { id: string; name: string }[]).find(
          (cat) => cat.id === value,
        );
        onFilters('categoryId', selectedCategory?.id || '');
        onFilters('categoryName', selectedCategory?.name || '');
      } else {
        onFilters(field, value);
      }
    },
    [categories, onFilters],
  );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 287 },
        }}
      >
        <InputLabel id="category-select-label">{t('common.category')}</InputLabel>

        <Select
          labelId="category-select-label"
          id="category-select"
          value={filters.categoryId}
          onChange={(e) => handleFilter(e, 'categoryId')}
          input={<OutlinedInput label={t('common.category')} />}
          sx={{ textTransform: 'capitalize' }}
        >
          {(categories as unknown as { id: string; name: string }[]).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 287 },
        }}
      >
        <InputLabel id="status-select-label">{t('common.status')}</InputLabel>

        <Select
          labelId="status-select-label"
          id="status-select"
          value={filters.status}
          onChange={(e) => handleFilter(e, 'status')}
          input={<OutlinedInput label="Status" />}
        >
          <MenuItem value="Published">{t('common.published')}</MenuItem>
          <MenuItem value="Unpublished">{t('common.unpublished')}</MenuItem>
        </Select>
      </FormControl>

      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={filters.searchQuery}
          onChange={(e) => handleFilter(e, 'searchQuery')}
          placeholder={t('common.search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
}
