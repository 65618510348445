import { useDropzone } from 'react-dropzone';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useTranslate } from 'src/locales';

import Iconify from '../iconify';
import { UploadProps } from './types';
import RejectionFiles from './errors-rejection-files';
import SingleFilePreviewCustom from './preview-single-file-custom';

// ----------------------------------------------------------------------

export default function UploadBoxCustom({
  disabled,
  error,
  helperText,
  accept,
  //
  file,
  onDelete,
  //
  mimeType,
  sx,
  ...other
}: UploadProps & {
  mimeType?: string;
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    disabled,
    accept,
    ...other,
  });
  const { t } = useTranslate();

  const hasFile = !!file;

  const hasError = isDragReject || !!error;

  const renderPlaceholder = (
    <Stack spacing={3} alignItems="center" justifyContent="center" flexWrap="wrap">
      <Stack spacing={0.5} alignItems="center">
        <Iconify icon="eva:cloud-upload-fill" width={40} />
        <Typography variant="body2">{t('test_edit_page.questions.upload_file')}</Typography>
      </Stack>
    </Stack>
  );

  const renderSinglePreview = (
    <SingleFilePreviewCustom
      mediaUrl={typeof file === 'string' ? file : (file?.preview as string)}
      mimeType={mimeType}
    />
  );

  const removeSinglePreview = hasFile && onDelete && (
    <IconButton
      size="small"
      onClick={onDelete}
      sx={{
        top: 16,
        right: 16,
        zIndex: 9,
        position: 'absolute',
        color: (theme) => alpha(theme.palette.common.white, 0.8),
        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
        '&:hover': {
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
        },
      }}
    >
      <Iconify icon="mingcute:close-line" width={18} />
    </IconButton>
  );

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <Box
        {...getRootProps()}
        sx={{
          p: 5,
          outline: 'none',
          borderRadius: 1,
          cursor: 'pointer',
          overflow: 'hidden',
          // height: 210,
          position: 'relative',
          color: (theme) => theme.palette.grey[500],
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
          border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
          transition: (theme) => theme.transitions.create(['opacity', 'padding']),
          '&:hover': {
            opacity: 0.72,
          },
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            color: 'error.main',
            borderColor: 'error.main',
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
          }),
          ...(hasFile && {
            padding: '22% 0',
          }),
        }}
      >
        <input {...getInputProps()} />

        {hasFile ? renderSinglePreview : renderPlaceholder}
      </Box>

      {removeSinglePreview}

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />
    </Box>
  );
}
