import { useState, useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { getAccountRole } from 'src/api/public';
import { USER_ROLES } from 'src/constants/user-roles';
import loadingAnimation from 'src/assets/animations/LoadingAnimation.json';

import { LottieAnimation } from 'src/components/animate';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
// ----------------------------------------------------------------------

export default function NonStaffGuard({ children }: Props) {
  const { loading } = useAuthContext();

  return (
    <>
      {loading ? (
        <LottieAnimation
          animationData={loadingAnimation}
          bgColor="#043F6B"
          height={180}
          width={180}
        />
      ) : (
        <Container>{children}</Container>
      )}
    </>
  );
}

function Container({ children }: Props) {
  const router = useRouter();
  const { user } = useAuthContext();

  const [loading, setLoading] = useState(false);

  const check = useCallback(async () => {
    setLoading(true);
    try {
      const accountRole: string = await getAccountRole();
      if (accountRole !== USER_ROLES.STUDENT) router.push(paths.staff.root);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) check();
  }, [user, check]);

  return loading ? (
    <LottieAnimation animationData={loadingAnimation} bgColor="#043F6B" height={180} width={180} />
  ) : (
    children
  );
}
