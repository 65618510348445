import { useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';

import { useDebounce } from 'src/hooks/use-debounce';

import { useTranslate } from 'src/locales';
import { useGetCollaboratorsList } from 'src/api/collaborators';

import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';

import { ICollaboratorsItem } from 'src/types/collaborators';

type Props = {
  onStaffChosen: (staff: ICollaboratorsItem) => void;
  disabled?: boolean;
};

export default function StaffSearchBox({ onStaffChosen, disabled = false }: Props) {
  const [query, setQuery] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const { t } = useTranslate();

  const [debouncedSearchText, isDebouncing] = useDebounce(query, 1500);

  const { data: staffData, isPending } = useGetCollaboratorsList({
    all: true,
    searchQuery: debouncedSearchText,
    disableUntilSearch: true,
  });

  const staff = useMemo(() => (staffData ? staffData.data : []), [staffData]);

  const isLoading = isDebouncing || (debouncedSearchText.trim() !== '' && isPending);

  return (
    <Autocomplete
      disabled={disabled}
      loading={isLoading}
      autoHighlight
      fullWidth
      inputValue={searchInput}
      onInputChange={(_event, newVal, reason) => {
        if (reason !== 'reset') {
          setSearchInput(newVal);
          setQuery(newVal);
        }
      }}
      onChange={(_e, newVal) => {
        setSearchInput((newVal?.fullName as string) ?? '');
        setQuery('');
        onStaffChosen(newVal as unknown as ICollaboratorsItem);
      }}
      options={staff as ICollaboratorsItem[]}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => `${option.fullName}`}
      renderOption={(props, option: ICollaboratorsItem) => (
        <li {...props} key={option.id} style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
          <Typography variant="caption">{option.fullName}</Typography>
        </li>
      )}
      noOptionsText={<SearchNotFound query={query} sx={{ bgcolor: 'unset' }} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('search_collaborators')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <Iconify icon="svg-spinners:8-dots-rotate" />
                ) : (
                  <Iconify icon="ion:chevron-down" width="1rem" height="1rem" color="grey.500" />
                )}
              </>
            ),
          }}
        />
      )}
      sx={{
        '& .MuiInputBase-root': {
          maxHeight: '40px',
          padding: '10px 14px 10px 14px !important',
        },
      }}
    />
  );
}
