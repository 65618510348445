import { useMutation, UseQueryResult, keepPreviousData } from '@tanstack/react-query';

import { useGetData, usePostData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints, mutateData, REQUEST_METHODS } from 'src/utils/axios';

import keys from 'src/constants/query-keys';
import { ITest, IAnswerTest, ITestResult, IAllTestResult, IHomeCategoryItem } from 'src/types';

import { IListFilters } from 'src/types/misc';
import { IResultsTableFilters } from 'src/types/results';
import { IPremisesPublic } from 'src/types/public/premises';

export function useGetHomeCategories(): UseQueryResult<IHomeCategoryItem[], Error> {
  return useGetData({
    args: [endpoints.general.categories],
    queryKey: [keys.general.categories.fetchCategories],
  });
}

export async function getAccountRole() {
  const {
    data: { data },
  } = await axiosInstance.get(endpoints.staff.account.root);
  return data.role;
}

export function useGetTestDetails({ id }: { id: string }) {
  return useGetData<ITest>({
    args: [endpoints.general.tests.details(id)],
    queryKey: [keys.general.tests.fetchTest],
  });
}

export function useSubmitTest() {
  return useMutation({
    mutationFn: ({ data }: { data: IAnswerTest }) =>
      mutateData({ url: `${endpoints.general.tests.submit}`, method: REQUEST_METHODS.POST, data }),
  });
}

export function useGetTestResult({ id }: { id: string }) {
  return useGetData<ITestResult>({
    args: [endpoints.general.results.details(id)],
    queryKey: [keys.general.results.fetchResult],
  });
}

export function useGetAllTestResult(params?: IListFilters<Omit<IResultsTableFilters, 'search'>>) {
  return useGetData<IAllTestResult>({
    args: [endpoints.general.results.root, { params }],
    queryKey: [keys.general.results.fetchResult, params],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export function useGetPremisesListForPublic(
  data: {
    searchQuery?: string;
    excludeIds?: string[];
  },
  enableQuery = false,
): UseQueryResult<IPremisesPublic, Error> {
  return usePostData({
    args: [endpoints.general.premises, { ...data }],
    queryKey: [keys.general.premises.fetchPremises, data],
    options: {
      enabled: enableQuery || Boolean(data.searchQuery),
    },
  });
}
