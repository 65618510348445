import Stack from '@mui/material/Stack';

import { useAuthContext } from 'src/auth/hooks/use-auth-context';

import NavList from './nav-list';
import { NavProps } from '../types';

// ----------------------------------------------------------------------

export default function NavDesktop({ data }: NavProps) {
  const { user } = useAuthContext();

  return (
    <Stack component="nav" direction="row" spacing={5} sx={{ mr: 2.5, height: 1 }}>
      {data.map((list) => {
        if (!list.forRole || (user && user.role === list.forRole)) {
          return <NavList key={list.title} data={list} />;
        }
        return null;
      })}
    </Stack>
  );
}
