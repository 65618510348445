import { m, domMax, LazyMotion } from 'framer-motion';

import { ENVIRONMENT } from 'src/config-global';
import { ENVIRONMENTS } from 'src/constants/misc';

import Banner, { BANNER_HEIGHT_OFFSET } from 'src/components/banner';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function MotionLazy({ children }: Props) {
  return (
    <LazyMotion strict features={domMax}>
      <Banner />
      <m.div
        style={{
          height: '100%',
          marginTop: ENVIRONMENT === ENVIRONMENTS.prod ? 0 : BANNER_HEIGHT_OFFSET,
        }}
      >
        {children}
      </m.div>
    </LazyMotion>
  );
}
