import React, { useMemo, useCallback } from 'react';

import { Table, TableBody, TableContainer } from '@mui/material';

import { removeFalsyValuesFromObject } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { TABLE_DEFAULTS } from 'src/constants/misc';
import { USER_ROLES } from 'src/constants/user-roles';
import { useGetStudentResultList } from 'src/api/students';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { IListFilters } from 'src/types/misc';
import { IStudentResultItem, IModifiedStudentResultItem } from 'src/types/students';

import ResultTabTableRow from './result-tab-table-row';

type Props = {
  id: string;
};

export default function ResultTab({ id }: Props) {
  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const table = useTable({ defaultRowsPerPage: TABLE_DEFAULTS.ROWS_PER_PAGE });
  const { t } = useTranslate();

  const TABLE_HEAD = useMemo(
    () => [
      { id: 'createdAt', label: t('common.date'), width: 108, align: 'left' },
      { id: 'testName', label: t('common.test'), width: 260, align: 'left' },
      { id: 'testLevelName', label: t('common.level'), width: 260, align: 'left' },
      { id: 'scorePercentage', label: t('common.points'), width: 80, align: 'left' },
      { id: 'actions', label: t('common.actions'), align: 'left' },
      ...(!isAnalyst ? [{ id: '' }] : []),
    ],
    [isAnalyst, t],
  );

  const denseHeight = table.dense ? 56 : 56 + 20;

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) => ({
      ...(fetchAll
        ? { all: true }
        : {
            page: table.page + 1,
            limit: table.rowsPerPage,
          }),
      sortBy: table.orderBy === 'fullName' ? 'name' : table.orderBy,
      sortDirection: table.order,
    }),
    [table],
  );

  const apiFilters: IListFilters<any> = useMemo(() => {
    const tableFilters = tableFiltersHandler({ fetchAll: false });

    return removeFalsyValuesFromObject({
      ...tableFilters,
    });
  }, [tableFiltersHandler]);

  const { data }: any = useGetStudentResultList(id, { ...apiFilters });

  const {
    studentResults: tableData = [],
    totalCount = 0,
  }: {
    studentResults: IModifiedStudentResultItem[];
    totalCount: number;
  } = useMemo(() => {
    const modify = data?.data.map((sr: IStudentResultItem) => ({
      id: sr.id,
      scorePercentage: sr.scorePercentage,
      createdAt: sr.createdAt,
      testName: sr.test.name,
      testLevelName: sr?.testLevels?.name || '',
    }));
    return {
      studentResults: modify,
      totalCount:
        data && data?.total_pages > 1
          ? data.total_pages * table.rowsPerPage
          : data?.data.length || 0,
    };
  }, [data, table]);

  const notFound = !tableData.length;

  return (
    <>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              // TODO:
              rowCount={20}
              numSelected={table.selected.length}
              onSort={table.onSort}
            />

            <TableBody>
              {tableData.length > 0 &&
                tableData.map((row) => (
                  <ResultTabTableRow key={row.id} row={row} headLabel={TABLE_HEAD} />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalCount}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </>
  );
}
