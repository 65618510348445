import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import { Alert, Collapse } from '@mui/material';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { toggleCategoryActiveField } from 'src/api/categories';

import { ICategoryList } from 'src/types/category';

import CategoryCard from './category-card';
// import { t } from 'i18next';

// ----------------------------------------------------------------------

type Props = {
  categories: ICategoryList[];
};

export default function CategoriesCardList({ categories }: Props) {
  const [liveUpdatedCategory, setLiveUpdatedCategory] = useState<
    { id: string; field: string; status: boolean }[]
  >([]);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();

  const handleToggleActive = async ({
    id,
    field,
    status,
  }: {
    id: string;
    field: string;
    status: boolean;
  }) => {
    try {
      await toggleCategoryActiveField(id);
      queryClient.invalidateQueries({ queryKey: [keys.staff.categories.fetchAllCategories] });
      setLiveUpdatedCategory((prevState) => [{ id, field, status }, ...prevState]);
      setTimeout(() => {
        setLiveUpdatedCategory((prevState) => prevState.filter((cat) => cat.id !== id));
      }, 3000);
    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.message;
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  };

  return (
    <>
      <Collapse in={liveUpdatedCategory.length > 0} sx={{ width: 1, mb: { xs: 3, md: 5 } }}>
        {liveUpdatedCategory.map((category) => (
          <Alert
            key={category.id}
            severity="success"
            variant="outlined"
            onClose={() =>
              setLiveUpdatedCategory((prevState) =>
                prevState.filter((cat) => cat.id !== category.id),
              )
            }
            sx={{ mb: 1 }}
          >
            {t('categories_listing_page.category_status_notification', {
              category_field: category.field,
              category_status: category.status
                ? t('common.live').toLowerCase()
                : t('common.inactive').toLowerCase(),
            })}
          </Alert>
        ))}
      </Collapse>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {categories.map((category) => (
          <CategoryCard
            key={category.id}
            category={category}
            handleToggleActive={handleToggleActive}
          />
        ))}
      </Box>
    </>
  );
}
