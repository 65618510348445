import { useMutation, UseQueryResult, keepPreviousData } from '@tanstack/react-query';

import { useGetData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints, mutateData, REQUEST_METHODS } from 'src/utils/axios';

import queryKeys from 'src/constants/query-keys';

import { IListFilters } from 'src/types/misc';
import {
  IStudentAccount,
  IUpdateStudentItem,
  IStudentModifiedItem,
  IStudentsTableFilters,
} from 'src/types/students';

export function useGetStudentsList(
  params?: IListFilters<Omit<IStudentsTableFilters, 'searchText'>>,
): UseQueryResult<{ data: IStudentModifiedItem[] }, Error> {
  return useGetData({
    args: [endpoints.staff.students, { params }],
    queryKey: [queryKeys.staff.students.fetchAllStudents, params],
    options: {
      placeholderData: keepPreviousData,
      enabled: !params?.disableUntilSearch || !!params?.searchQuery,
    },
  });
}

export function useGetStudentDetails(id: string) {
  return useGetData({
    args: [`${endpoints.staff.students}/${id}`],
    queryKey: [queryKeys.staff.students.fetchStudentInfo],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export async function deleteStudent(id: string) {
  const URL = `${endpoints.staff.students}/${id}`;
  await axiosInstance.delete(URL);
}

export async function updateStudent(data: Partial<IUpdateStudentItem>) {
  const URL = `${endpoints.staff.students}/${data?.id}`;
  await axiosInstance.put(URL, data);
}

export function useGetStudentResultList(id: string, params?: IListFilters<any>) {
  return useGetData({
    args: [endpoints.staff.student.results(id ?? ''), { params }],
    queryKey: [queryKeys.staff.students.fetchStudentResults, params],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export async function deleteStudentResults(id: string) {
  const URL = `${endpoints.staff.student.results(id)}`;
  await axiosInstance.delete(URL);
}

export function useGetStudentNotificationsList(id: string) {
  return useGetData({
    args: [endpoints.staff.student.notifications(id ?? '')],
    queryKey: [queryKeys.staff.students.fetchStudentNotifications],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export function useGetStudentAccount(): UseQueryResult<IStudentAccount, Error> {
  return useGetData({
    args: [endpoints.student.account.root],
    queryKey: [queryKeys.student.fetchStudentAccountInfo],
  });
}

export function useUpdateAccountInfo() {
  return useMutation({
    mutationFn: ({ data }: { data: any }) =>
      mutateData({ url: endpoints.student.account.root, method: REQUEST_METHODS.PUT, data }),
  });
}

export function useUpdatePremisesNewsletterStatus() {
  return useMutation({
    mutationFn: ({
      data,
    }: {
      data: {
        premisesId: string;
        isSubscribed: boolean;
      };
    }) =>
      mutateData({
        url: endpoints.student.account.notification(data.premisesId),
        method: REQUEST_METHODS.PUT,
        data,
      }),
  });
}

export function useDeleteAccount() {
  return useMutation({
    mutationFn: () =>
      mutateData({ url: endpoints.student.account.root, method: REQUEST_METHODS.DELETE }),
  });
}

export function useDisconnectProvider() {
  return useMutation({
    mutationFn: ({ data }: { data: { providerType: string } }) =>
      mutateData({
        url: `${endpoints.student.account.provider.disconnect}`,
        method: REQUEST_METHODS.PUT,
        data,
      }),
  });
}
