import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import { Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  useLocation,
  matchRoutes,
  BrowserRouter,
  useNavigationType,
  createRoutesFromChildren,
} from 'react-router-dom';

import App from './app';
import { GTM_ID, ENVIRONMENT, SENTRY_CONFIG } from './config-global';
import { CONSTANT_VARIABLES } from './constants/constants-variables';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const tagManagerArgs = {
  gtmId: GTM_ID,
};

const sentryArgs = {
  dsn: SENTRY_CONFIG.dsn,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  env: SENTRY_CONFIG.env,
  enabled: [
    CONSTANT_VARIABLES.PRODUCTION,
    CONSTANT_VARIABLES.STAGE,
    CONSTANT_VARIABLES.DEVELOP,
  ].includes(ENVIRONMENT),
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};

TagManager.initialize(tagManagerArgs);
if (
  [CONSTANT_VARIABLES.PRODUCTION, CONSTANT_VARIABLES.STAGE, CONSTANT_VARIABLES.DEVELOP].includes(
    ENVIRONMENT,
  )
) {
  Sentry.init(sentryArgs);
}

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);
