import { isEqual } from 'lodash';
import { useMemo, useState, useCallback } from 'react';

import { Card, Table, Button, TableBody, TableContainer } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';

import { convertToTitleCase, removeFalsyValuesFromObject } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { useGetTests } from 'src/api/staff/tests';
import { TABLE_DEFAULTS } from 'src/constants/misc';
import { USER_ROLES } from 'src/constants/user-roles';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import EmptyContent from 'src/components/empty-content/empty-content';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { ITestTableItem, ITestsTableFilters, ITestsTableFilterValue } from 'src/types/staff/tests';

import TestsTableRow from './test-table-row';
import TestNewDialog from './test-new-dialog';
import TestsTableToolbar from './test-table-toolbar';
import TestTableFiltersResult from './test-table-filters-result';

const defaultFilters: ITestsTableFilters = {
  categoryId: '',
  categoryName: '',
  searchQuery: '',
  status: '',
};

function TestTable({ questionId }: { questionId?: string }) {
  const newTestDialog = useBoolean();
  const { t } = useTranslate();

  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const TABLE_HEAD = useMemo(
    () => [
      { id: 'name', label: t('common.name'), width: 300, align: 'left' },
      { id: 'category', label: t('common.category'), width: 220, align: 'left' },
      { id: 'isPrimary', label: t('common.primary'), width: 132, align: 'left' },
      {
        id: 'totalQuestions',
        label: t('test_listing_page.no_of_questions'),
        width: 100,
        align: 'left',
      },
      { id: 'isPublished', label: '', width: 50, align: 'right' },
      { id: 'open', label: '', width: 50, align: 'right' },
      { id: 'share', label: '', width: 50, align: 'right' },
      ...(!isAnalyst
        ? [
            { id: 'menu', label: '', width: 50, align: 'right' },
            { id: 'delete', label: '', width: 50, align: 'right' },
          ]
        : []),
    ],
    [t, isAnalyst],
  );

  const isQuestionTestTabView = Boolean(questionId);

  const table = useTable({ defaultRowsPerPage: TABLE_DEFAULTS.ROWS_PER_PAGE });

  const [filters, setFilters] = useState(defaultFilters);
  // const [duplicateTestData, setDuplicateTestData] = useState(null);
  const [testDuplicationId, setTestDuplicationId] = useState<string | null>(null);

  const canReset = !isEqual(defaultFilters, filters);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) => ({
      ...(fetchAll
        ? { all: true }
        : {
            page: table.page + 1,
            pageSize: table.rowsPerPage,
          }),
      sortColumn: table.orderBy,
      sortOrder: table.order,
    }),
    [table],
  );

  const apiFiltersHandler = useCallback(() => {
    const allFilters: ITestsTableFilters = { ...filters };
    if (allFilters.searchQuery) {
      delete allFilters.searchQuery;
    }

    if (allFilters.status) {
      allFilters.isPublished = allFilters.status === 'Published';
    }

    return allFilters;
  }, [filters]);

  const [debouncedsearchQuery] = useDebounce(filters.searchQuery || '', 1500);

  const apiFilters: ITestsTableFilters = useMemo(() => {
    const allFilters = apiFiltersHandler();
    const tableFilters = tableFiltersHandler({ fetchAll: false });

    return removeFalsyValuesFromObject({
      ...allFilters,
      ...tableFilters,
      searchQuery: debouncedsearchQuery,
    });
  }, [apiFiltersHandler, tableFiltersHandler, debouncedsearchQuery]);

  const { data, isFetching }: any = useGetTests({
    ...apiFilters,
    questionId,
  });

  const {
    tests: tableData = [],
    totalCount = 0,
  }: {
    tests: ITestTableItem[];
    totalCount: number;
  } = useMemo(
    () => ({
      tests: data?.data,
      totalCount: data?.totalCount,
    }),
    [data],
  );

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const handleFilters = useCallback(
    (name: string, value: ITestsTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleTestDuplication = useCallback(
    (testId: string) => {
      // const formattedTestData = {
      //   ...testData,
      //   levels: testData.levels
      //     ? testData.levels.map(({ id, ...rest }: { id: string; rest: any }) => ({
      //         ...rest,
      //       }))
      //     : [],
      // };
      // const originalTestData =
      // setDuplicateTestData(formattedTestData);
      setTestDuplicationId(testId);
      newTestDialog.onTrue();
    },
    [newTestDialog],
  );

  return (
    <>
      {!isQuestionTestTabView && (
        <CustomBreadcrumbs
          heading={t('common.tests')}
          links={[{ name: t('common.dashboard'), href: '#' }, { name: t('common.tests') }]}
          action={
            tableData.length > 0 &&
            !isAnalyst && (
              <Button
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={newTestDialog.onTrue}
                sx={{
                  backgroundColor: 'customColors.custom1',
                  '&:hover': {
                    backgroundColor: 'customColors.custom4',
                  },
                }}
              >
                {convertToTitleCase(t('common.new_test'))}
              </Button>
            )
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
      )}
      <Card>
        {isFetching ||
        debouncedsearchQuery.length > 0 ||
        canReset ||
        isQuestionTestTabView ||
        tableData.length ? (
          <>
            <TestsTableToolbar filters={filters} onFilters={handleFilters} />

            {canReset && (
              <TestTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                onResetFilters={handleResetFilters}
                results={totalCount}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={20}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    sx={{ whiteSpace: 'nowrap' }}
                  />

                  <TableBody>
                    {tableData.length > 0 &&
                      tableData.map((row) => (
                        <TestsTableRow
                          key={row.id}
                          row={row}
                          handleTestDuplication={handleTestDuplication}
                        />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={totalCount}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </>
        ) : (
          <EmptyContent
            filled
            title="0 Tests"
            imgUrl="/assets/icons/empty/ic_chat.svg"
            action={
              <Button
                variant="contained"
                sx={{
                  mt: 1,
                  backgroundColor: 'customColors.custom1',
                  '&:hover': {
                    backgroundColor: 'customColors.custom4',
                  },
                }}
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={newTestDialog.onTrue}
                disabled={isAnalyst}
              >
                {t('common.new_test')}
              </Button>
            }
            sx={{ p: 5 }}
          />
        )}
      </Card>
      {newTestDialog.value && (
        <TestNewDialog
          dialog={newTestDialog}
          testDuplicationId={testDuplicationId}
          setTestDuplicationId={setTestDuplicationId}
        />
      )}
    </>
  );
}

export default TestTable;
