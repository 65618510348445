import v from 'voca';
import React from 'react';
import { useNavigate } from 'react-router';

import { Paper, Stack, ListItemText } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import StudentIcon from 'src/assets/icons/student-icon';
import { roleTypeToPlural } from 'src/constants/template';
import CollaboratorsIcon from 'src/assets/icons/collaborators-icon';

import { ITemplateItem } from 'src/types/templates';

type Props = {
  template: ITemplateItem;
};

export default function EmailTemplatesItems({ template }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslate();

  const renderIcon = (
    <span
      style={{
        width: 40,
        height: 40,
        backgroundColor: '#F9FAFB',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {template.role === 'student' ? <StudentIcon /> : <CollaboratorsIcon />}
    </span>
  );

  const renderText = (
    <ListItemText
      primary={v.titleCase(roleTypeToPlural[template.role] || template.role)}
      secondary={`${template.count} ${t('notifications.email_templates')}`}
      primaryTypographyProps={{
        noWrap: true,
        typography: 'subtitle1',
        fontWeight: 900,
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        alignItems: 'center',
        typography: 'body2',
        color: 'text.disabled',
        display: 'inline-flex',
      }}
    />
  );
  return (
    <Stack
      component={Paper}
      variant="elevation"
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'unset', sm: 'center' }}
      height={{ md: 94 }}
      onClick={() => navigate(paths.staff.notifications.getEmailTemplates(template.role))}
      sx={{
        borderRadius: 0.5,
        bgcolor: 'unset',
        boxShadow: (theme) => theme.customShadows.z12,
        cursor: 'pointer',
        position: 'relative',
        p: { xs: 2.5, sm: 2 },
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: (theme) => theme.customShadows.z20,
        },
      }}
    >
      {renderIcon}
      {renderText}
    </Stack>
  );
}
