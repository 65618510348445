import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useDebounce } from 'src/hooks/use-debounce';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { useUpdateAccountInfo } from 'src/api/students';
import { useGetPremisesListForPublic } from 'src/api/public';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';

import { IUseBooleanReturnType } from 'src/types/misc';

import PremisesSearch from './premises-search';

function AddNewPremisesDialog({
  dialog,
  excludePremisesIds = [],
}: {
  dialog: IUseBooleanReturnType;
  excludePremisesIds: string[];
}) {
  const { t } = useTranslate();

  const { mutateAsync: updateAccountInfo } = useUpdateAccountInfo();
  const queryClient = useQueryClient();

  const NewCategorySchema = Yup.object().shape({
    primaryTestId: Yup.object({
      name: Yup.string(),
      id: Yup.string(),
    }).test('is primary test have value', 'primary test cannot be empty', (val) => {
      if (!val.id || !val.name) return false;
      return true;
    }),
  });

  const defaultValues = {
    primaryTestId: {
      name: '',
      id: '',
    },
  };

  const methods = useForm({
    resolver: yupResolver(NewCategorySchema),
    defaultValues,
  });

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText || '', 1000);

  const { data: { data: Premises } = { data: [] }, isLoading } = useGetPremisesListForPublic({
    searchQuery: debouncedSearchText,
    excludeIds: excludePremisesIds,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const newPremisesId = data.primaryTestId.id;

      const finalData = [...excludePremisesIds, newPremisesId];

      await updateAccountInfo(
        {
          data: {
            premisesIds: finalData,
          },
        },
        {
          onSettled: (_, err) => {
            if (!err) {
              enqueueSnackbar('Update success!');
              closeDialog();
            }
          },
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [keys.student.fetchStudentAccountInfo] });
          },
        },
      );
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      }
    }
  });

  const closeDialog = () => {
    dialog.onFalse();
  };

  const handleSearch = useCallback((inputValue: string) => {
    setSearchText(inputValue);
  }, []);

  return (
    <Dialog
      open={dialog.value}
      onClose={closeDialog}
      fullWidth
      sx={{
        '& .MuiPaper-elevation': {
          maxWidth: '720px',
        },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>
          <Typography variant="h6" component="span" fontWeight="bold">
            {t('account_page.premises_section.new_premises_modal.title')}
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="mingcute:close-line" />
        </IconButton>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <PremisesSearch
            name="primaryTestId"
            query={debouncedSearchText}
            results={Premises as any}
            searchInput={searchText}
            onSearch={handleSearch}
            loading={isLoading}
            disableClearable
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={closeDialog}
            sx={{
              fontFamily: 'Nunito Sans',
              fontSize: '14px',
              letterSpacing: '2px',
              textTransform: 'uppercase',
            }}
            variant="outlined"
            color="inherit"
          >
            {t('common.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            type="submit"
            loading={isSubmitting}
            disabled={!isDirty}
            sx={{
              padding: '8px 16px',
              width: 'max-content',
              marginLeft: 'auto',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Nunito Sans',
                fontSize: '14px',
                fontWeight: '900',
                lineHeight: '18px' /* 150% */,
                letterSpacing: '2px',
                textTransform: 'uppercase',
              }}
            >
              {t('common.add')}
            </Typography>
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default AddNewPremisesDialog;
