import axiosInstance, { endpoints } from 'src/utils/axios';

import { ICreateUser, IMagicEmailPayload, ICheckStudentExists } from 'src/types';

export async function createUser(data: ICreateUser) {
  const URL = `${endpoints.auth.register.student}`;
  const resData = await axiosInstance.post(URL, data);
  return resData.data;
}

export async function checkAccountExists(data: ICheckStudentExists) {
  const URL = `${endpoints.auth.checkAccountExist}`;
  const resData = await axiosInstance.post(URL, data);
  return resData.data.data;
}

export async function sendSignInLinkToEmail(data: IMagicEmailPayload) {
  const resData = await axiosInstance.post(endpoints.auth.link.login, data);
  return resData.data.data;
}
