import { keepPreviousData } from '@tanstack/react-query';

import { useGetData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints } from 'src/utils/axios';

import queryKeys from 'src/constants/query-keys';

import { IListFilters } from 'src/types/misc';
import { IResultsTableFilters } from 'src/types/results';

export function useGetResultsList(params?: IListFilters<Omit<IResultsTableFilters, 'search'>>) {
  return useGetData({
    args: [endpoints.staff.results.root, { params }],
    queryKey: [queryKeys.staff.results.fetchAllResults, params],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export async function deleteResult(id: string) {
  const URL = `${endpoints.staff.results.root}/${id}`;
  await axiosInstance.delete(URL);
}

export async function shareResult(data: {
  to: string;
  link: string;
  studentName: string;
  studentEmail: string;
  testName: string;
  testLevel: string;
  userType: string;
}) {
  const URL = endpoints.staff.results.share;
  await axiosInstance.post(URL, data);
}
