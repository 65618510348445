import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { Box, Card, Stack, alpha, Button, Typography } from '@mui/material';

import { useTranslate } from 'src/locales';
import { SITE_URL } from 'src/config-global';

import Iconify from 'src/components/iconify';
import StudentSearchBox from 'src/components/sharing/students-search-box';

import { IStudentModifiedItem } from 'src/types/students';
import { ICollaboratorsItem } from 'src/types/collaborators';

import StaffSearchBox from '../staff-search-box';

function ShareCard({
  title,
  linkToShare,
  isSharingToStaff = false,
  onShare,
  addtionalPayloadToShareFn,
}: {
  title: string;
  linkToShare: string;
  isSharingToStaff?: boolean;
  onShare: (payload: any) => Promise<void>;
  addtionalPayloadToShareFn: Record<string, string>;
}) {
  const [selectedRecipient, setSelectedRecipient] = useState<
    null | IStudentModifiedItem | ICollaboratorsItem
  >(null);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();

  const handleRecipientChosen = (recipient: IStudentModifiedItem | ICollaboratorsItem) => {
    setSelectedRecipient(recipient);
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(`${SITE_URL}${linkToShare}`)
      .then(() => {
        enqueueSnackbar(t('sharing.link_copied'), { variant: 'success' });
      })
      .catch((_error) => {
        enqueueSnackbar(t('sharing.failed_copy_link'), { variant: 'error' });
      });
  };

  const handleSendEmail = async () => {
    try {
      setIsSendingEmail(true);
      const toEmail = selectedRecipient?.email;
      const toFullName = selectedRecipient?.fullName;

      const data = {
        ...addtionalPayloadToShareFn,
        to: toEmail,
        link: `${SITE_URL}${linkToShare}`,
        toName: toFullName,
        isForStudent: !isSharingToStaff,
      };

      await onShare(data);
      enqueueSnackbar(t('sharing.share_email_sent'), { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('sharing.failed_share_email'), { variant: 'error' });
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <Card
      sx={{
        padding: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        marginTop: '2.5rem',
        boxShadow: (theme) => theme.customShadows.z12,
        borderRadius: '2px',
      }}
    >
      <Typography variant="h6" component="span" fontWeight="bold">
        {title}
      </Typography>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing="1rem">
        <Box sx={{ minWidth: '390px' }}>
          {isSharingToStaff ? (
            <StaffSearchBox onStaffChosen={handleRecipientChosen} />
          ) : (
            <StudentSearchBox onStudentChosen={handleRecipientChosen} />
          )}
        </Box>
        <LoadingButton
          variant="contained"
          endIcon={
            <Iconify
              icon="streamline:mail-send-envelope-solid"
              sx={{ width: '16px', height: '16px' }}
            />
          }
          sx={{ minWidth: '125px', backgroundColor: 'customColors.custom1' }}
          onClick={handleSendEmail}
          disabled={!selectedRecipient}
          loading={isSendingEmail}
        >
          {t('sharing.share_send_email')}
        </LoadingButton>
        <Typography
          variant="body2"
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '24px',
            color: 'grey.500',
            textAlign: 'center',
          }}
        >
          {t('common.or')}
        </Typography>
        <Button
          variant="contained"
          sx={{
            minWidth: '90px',
            backgroundColor: (theme) => alpha(theme.palette.customColors.custom1, 0.08),
            color: 'customColors.custom1',
            '&:hover': {
              backgroundColor: (theme) => alpha(theme.palette.customColors.custom1, 0.3),
            },
          }}
          onClick={handleCopyLink}
        >
          {t('sharing.share_copy_link')}
        </Button>
      </Stack>
    </Card>
  );
}

export default ShareCard;
