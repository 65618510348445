import { useState, useCallback } from 'react';

import Collapse from '@mui/material/Collapse';
import { stackClasses } from '@mui/material/Stack';
import { listItemButtonClasses } from '@mui/material/ListItemButton';

import { usePathname } from 'src/routes/hooks';

import { isBaseString } from 'src/utils/misc';
// import { useActiveLink } from 'src/routes/hooks/use-active-link';

import { NavSectionVertical } from 'src/components/nav-section';

import { NavItem } from './nav-item';
import { NavListProps } from '../types';

// ----------------------------------------------------------------------

export default function NavList({ data }: NavListProps) {
  const pathname = usePathname();

  const paths = Array.isArray(data.path) ? data.path : [data.path];

  const active = paths.some((path) => isBaseString(path, pathname));

  const externalLink = paths.some((path) => path.includes('http'));

  const [openMenu, setOpenMenu] = useState(false);

  const handleToggleMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu((prev) => !prev);
    }
  }, [data.children]);

  return (
    <>
      <NavItem
        open={openMenu}
        onClick={handleToggleMenu}
        //
        title={data.title}
        path={data.path}
        icon={data.icon}
        //
        hasChild={!!data.children}
        externalLink={externalLink}
        //
        active={active}
      />

      {!!data.children && (
        <Collapse in={openMenu} unmountOnExit>
          <NavSectionVertical
            data={data.children}
            slotProps={{
              rootItem: {
                minHeight: 36,
              },
            }}
            sx={{
              [`& .${stackClasses.root}`]: {
                '&:last-of-type': {
                  [`& .${listItemButtonClasses.root}`]: {
                    height: 160,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    bgcolor: 'background.neutral',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url(/assets/illustrations/illustration_dashboard.png)',
                    '& .label': {
                      display: 'none',
                    },
                  },
                },
              },
            }}
          />
        </Collapse>
      )}
    </>
  );
}
