import { Helmet } from 'react-helmet-async';

import { CategoryView } from 'src/sections/staff/tests/category/view';

// ----------------------------------------------------------------------

export default function CategoriesViewPage() {
  return (
    <>
      <Helmet>
        <title>Category: List</title>
      </Helmet>

      <CategoryView />
    </>
  );
}
