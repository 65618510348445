const StudentIcon = () => (
  <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.32"
      d="M3.80166 32.7625C3.94944 34.1935 4.88769 35.3262 6.2948 35.6258C8.51584 36.0987 12.7027 36.6667 20 36.6667C27.2973 36.6667 31.4842 36.0987 33.7053 35.6258C35.1123 35.3262 36.0506 34.1935 36.1983 32.7625C36.4175 30.6407 36.6667 26.7449 36.6667 20C36.6667 13.2551 36.4175 9.35933 36.1983 7.23751C36.0506 5.80647 35.1123 4.67373 33.7052 4.37417C31.4842 3.90135 27.2973 3.33333 20 3.33333C12.7027 3.33333 8.51584 3.90135 6.2948 4.37417C4.88769 4.67373 3.94944 5.80647 3.80166 7.23751C3.58254 9.35933 3.33334 13.2551 3.33334 20C3.33334 26.7449 3.58254 30.6407 3.80166 32.7625Z"
      fill="#1877F2"
    />
    <path
      d="M23.2302 23.0931C25.4382 21.9304 26.9437 19.6132 26.9437 16.9444C26.9437 13.1092 23.8346 10 19.9992 10C16.164 10 13.0548 13.1092 13.0548 16.9444C13.0548 19.6133 14.5604 21.9307 16.7686 23.0933C13.8115 24.2199 11.5536 26.8487 10.8542 30.0882C10.7574 30.5365 11.0041 30.9967 11.4557 31.0785C13.0763 31.3717 15.7602 31.6667 20.001 31.6667C24.2417 31.6667 26.9256 31.3717 28.5462 31.0785C28.9962 30.9971 29.2422 30.5386 29.1459 30.0918C28.4474 26.8505 26.1887 24.2201 23.2302 23.0931Z"
      fill="#1877F2"
    />
  </svg>
);

export default StudentIcon;
