import React from 'react';

import { Stack } from '@mui/material';

import { useTranslate } from 'src/locales';

import { RHFEditor, RHFTextField } from 'src/components/hook-form';

const QuestionContent: React.FC = () => {
  const { t } = useTranslate();
  return (
    <Stack spacing={3}>
      <RHFTextField name="question" label={t('common.question')} />
      <RHFEditor
        simple
        name="instruction"
        placeholder={t('questions_edit_page.instructions_for_question')}
        sx={{
          '& .ql-editor': {
            bgcolor: 'transparent',
          },
        }}
      />
    </Stack>
  );
};

export default QuestionContent;
