import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { USER_ROLES } from 'src/constants/user-roles';

const EXTERNAL_TEACHING_SITE_LINK = 'https://new-english-teaching.it';

export function useHeaderNavData() {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      {
        title: t('common.home').toUpperCase(),
        path: paths.root,
      },
      {
        title: t('common.results').toUpperCase(),
        path: paths.student.results,
        forRole: USER_ROLES.STUDENT, // Only visible for users with the 'student' role
      },
      {
        title: t('common.courses').toUpperCase(),
        path: `${EXTERNAL_TEACHING_SITE_LINK}/offerta/corsi`,
      },
      {
        title: t('common.premises').toUpperCase(),
        path: `${EXTERNAL_TEACHING_SITE_LINK}/dovesiamo/sedi`,
      },
      {
        title: t('common.about_us').toUpperCase(),
        path: `${EXTERNAL_TEACHING_SITE_LINK}/net/chi-siamo`,
      },
    ],
    [t],
  );

  return data;
}
