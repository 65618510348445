import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm, useFieldArray } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  alpha,
  Stack,
  Button,
  Dialog,
  Divider,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { IUseBooleanReturnType } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { useGetTestById, createOrUpdateTest } from 'src/api/staff/tests';
import {
  CreateOrUpdateTest,
  CreateOrUpdateTestSchema,
} from 'src/schemas/create-update-duplicate-test';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFTextField } from 'src/components/hook-form';

function TestNewDialog({
  dialog,
  testDuplicationId,
  setTestDuplicationId,
}: {
  dialog: IUseBooleanReturnType;
  testDuplicationId: string | null;
  setTestDuplicationId: (testId: string | null) => void;
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const router = useRouter();

  const { data: duplicateTestData, isPending } = useGetTestById(testDuplicationId);

  const defaultValues: CreateOrUpdateTest = {
    name: '',
    instruction: '',
    isRandomized: false,
    totalQuestions: 0,
    dimension: undefined,
    testDuration: 3600,
    isPublished: true,
    isArchived: false,
    levels: [],
  };

  const methods = useForm({
    resolver: yupResolver(CreateOrUpdateTestSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (duplicateTestData) {
      setValue('name', `${duplicateTestData.name} (Duplicate)`);
      setValue('instruction', duplicateTestData.instruction);
      setValue('isRandomized', duplicateTestData.isRandomized);
      setValue('totalQuestions', duplicateTestData.totalQuestions);
      setValue('dimension', duplicateTestData.dimension ?? undefined);
      setValue('testDuration', duplicateTestData.testDuration);
      setValue('isPublished', duplicateTestData.isPublished);
      setValue(
        'levels',
        duplicateTestData.levels.map(({ id, ...otherLevelData }) => ({ ...otherLevelData })),
      );
    }
  }, [duplicateTestData, setValue]);

  const [newLevelName, setNewLevelName] = useState('');
  const [newLevelPercentage, setNewLevelPercentage] = useState(1);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'levels',
  });
  const handleAddLevel = () => {
    append({ name: newLevelName, minPercentage: newLevelPercentage });
    setNewLevelName('');
    setNewLevelPercentage(1);
  };

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const testData = data;
      if (testDuplicationId && duplicateTestData) {
        const sections = duplicateTestData.sections.map(
          ({ id: sectionId, ...otherSectionData }: { id: string }) => ({
            ...otherSectionData,
            testId: testDuplicationId,
            questions: duplicateTestData.testQuestions
              .filter((question: { sectionId: any }) => question.sectionId === sectionId)
              .sort((a: { position: number }, b: { position: number }) => a.position - b.position)
              // eslint-disable-next-line @typescript-eslint/no-shadow
              .map(({ id, ...otherQuestionData }: { id: string }) => ({
                testId: testDuplicationId,
                sectionId: null,
                ...otherQuestionData,
              })),
          }),
        );

        const independentQuestions = duplicateTestData.testQuestions
          .filter((question: { sectionId: any }) => !question.sectionId)
          .map(({ id, ...otherQuestionData }: { id: string }) => ({
            testId: testDuplicationId,
            ...otherQuestionData,
          }));

        testData.independentQuestions = independentQuestions;
        testData.sections = sections;
      }

      const resData = await createOrUpdateTest(data, !!testDuplicationId);
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
      return router.push(`${paths.staff.test.tests.edit(resData.data.data.newTestId)}?new=true`);
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      return enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  const closeDialog = () => {
    dialog.onFalse();
    setTestDuplicationId(null);
  };

  return (
    <Dialog
      open={dialog.value}
      onClose={closeDialog}
      fullWidth
      sx={{
        '& .MuiPaper-elevation': {
          maxWidth: '720px',
          minHeight: '580px',
        },
      }}
    >
      {testDuplicationId && isPending ? (
        <Box
          sx={{ height: '580px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Iconify
            icon="svg-spinners:8-dots-rotate"
            color="grey.900"
            sx={{ width: '2.5rem', height: '2.5rem' }}
          />
        </Box>
      ) : (
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle>
            <Typography variant="h6" component="span" fontWeight="bold">
              {t('common.new_test')}
            </Typography>
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 20,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="mingcute:close-line" />
          </IconButton>

          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <RHFTextField
              name="name"
              label={t('common.name')}
              size="small"
              margin="dense"
              fullWidth
            />

            <Box>
              <RHFEditor
                simple
                name="instruction"
                placeholder={t('common.instructions')}
                sx={{
                  '& .ql-editor': {
                    bgcolor: 'transparent',
                  },
                }}
              />
            </Box>
            <Stack spacing={2}>
              <Grid container spacing={3} sx={{ m: 0, width: '100% !important' }}>
                <Grid item xs={12} sm={8} sx={{ m: 0, p: '0 !important' }}>
                  <Typography
                    component={Typography}
                    variant="subtitle2"
                    fontWeight="fontWeightBold"
                    marginBottom={1}
                  >
                    {t('common.levels')}*
                  </Typography>

                  <Stack gap={2} direction="row" alignItems="start">
                    <RHFTextField
                      name="levels"
                      value={newLevelName}
                      onChange={(e) => setNewLevelName(e.target.value)}
                      size="small"
                      id="level-name-input"
                      placeholder={t('common.level')}
                    />
                    <RHFTextField
                      name="levels.minPercentage"
                      value={newLevelPercentage}
                      onChange={(e) => setNewLevelPercentage(+e.target.value)}
                      type="number"
                      label="%"
                      size="small"
                      helperText=""
                      ignoreErrorMessage
                      sx={{
                        width: '20%',
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={handleAddLevel}
                      disabled={!newLevelName.trim()}
                      endIcon={<Iconify icon="mingcute:add-line" />}
                      sx={{ color: 'secondary.contrastText', backgroundColor: 'secondary.main' }}
                    >
                      {t('common.add')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              {fields.map((field, index) => (
                <Grid
                  key={index}
                  container
                  gap={2}
                  sx={{ m: 0, width: '100% !important' }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    sx={{ m: 0, p: '0 !important', display: 'flex', alignItems: 'center' }}
                  >
                    <Iconify icon="akar-icons:dot-grid" width="20px" color="grey.600" />
                  </Grid>
                  <Grid item xs={8} sx={{ m: 0, p: '0 !important' }}>
                    <RHFTextField
                      name={`levels.${index}.name`}
                      defaultValue={field.name}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1} sx={{ m: 0, p: '0 !important' }}>
                    <RHFTextField
                      name={`levels.${index}.minPercentage`}
                      type="number"
                      label="%"
                      size="small"
                      fullWidth
                      helperText=""
                      ignoreErrorMessage
                    />
                  </Grid>
                  <Grid item sx={{ m: 0, p: '0 !important' }}>
                    <Button
                      onClick={() => remove(index)}
                      endIcon={<Iconify icon="mdi:close" />}
                      size="small"
                      sx={{
                        color: 'error.dark',
                        backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                      }}
                    >
                      {t('common.remove')}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Stack>
          </DialogContent>
          <Divider sx={{ marginTop: '40px', borderStyle: 'dashed' }} />
          <DialogActions>
            <LoadingButton
              color="success"
              size="medium"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {t('common.create')}
            </LoadingButton>
            <Button onClick={closeDialog} size="medium" variant="outlined" color="inherit">
              {t('common.cancel')}
            </Button>
          </DialogActions>
        </FormProvider>
      )}
    </Dialog>
  );
}

export default TestNewDialog;
