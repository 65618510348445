import { useState } from 'react';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

import { useDebounce } from 'src/hooks/use-debounce';

import { convertToTitleCase } from 'src/utils/misc';
import { lightenHexColor } from 'src/utils/lighten-hex-color';

import { useTranslate } from 'src/locales';
import { customColors } from 'src/theme/palette';
import { useGetQuestionsForTest } from 'src/api/staff/tests';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';

import { IQuestion } from 'src/types/question';
import { ITestQuestion } from 'src/types/staff/tests';

type Props = {
  excludeIds: string[];
  onQuestionChosen: (question: ITestQuestion) => void;
  disabled: boolean;
};

export default function QuestionSearchBox({
  excludeIds,
  onQuestionChosen,
  disabled = false,
}: Props) {
  const [query, setQuery] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const [debouncedSearchText, isDebouncing] = useDebounce(query, 1500);
  const { t } = useTranslate();

  const { data: questions = [], isPending } = useGetQuestionsForTest({
    search: debouncedSearchText,
    excludeIds,
  });

  const isLoading = isDebouncing || (debouncedSearchText.trim() !== '' && isPending);

  return (
    <Autocomplete
      disabled={disabled}
      loading={isLoading}
      autoHighlight
      fullWidth
      inputValue={searchInput}
      onInputChange={(_event, newVal, reason) => {
        if (reason !== 'reset') {
          setSearchInput(newVal);
          setQuery(newVal);
        }
      }}
      onChange={(_e, newVal) => {
        setSearchInput('');
        setQuery('');
        if (newVal) onQuestionChosen(newVal as unknown as ITestQuestion);
      }}
      options={questions as IQuestion[]}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => `${option.question} ${option.tags?.join(' ') || ''}`}
      renderOption={(props, option: IQuestion) => (
        <li {...props} style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
          <Typography variant="caption">
            {option.question}
            &nbsp;&nbsp;&nbsp;
            {option.tags &&
              option.tags.map((tag, index) => (
                <Label
                  key={index}
                  variant="soft"
                  sx={{
                    backgroundColor: lightenHexColor(customColors.custom3, 84),
                    color: 'customColors.custom3',
                    fontWeight: '700',
                    fontSize: '12px',
                    lineHeight: '20px',
                    marginLeft: index !== 0 ? '4px' : '0px',
                    marginRight: '4px', // Add some margin to the right of each tag
                  }}
                >
                  {tag}
                </Label>
              ))}
          </Typography>
        </li>
      )}
      noOptionsText={<SearchNotFound query={query} sx={{ bgcolor: 'unset' }} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={convertToTitleCase(t('test_edit_page.questions.search_questions'))}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
            endAdornment: <>{isLoading ? <Iconify icon="svg-spinners:8-dots-rotate" /> : null}</>,
          }}
        />
      )}
      sx={{
        '& .MuiInputBase-root': {
          maxHeight: '40px',
          padding: '10px 14px 10px 14px !important',
        },
      }}
    />
  );
}
