import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, alpha, Button, Divider, IconButton, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { convertToTitleCase } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { useAuthContext } from 'src/auth/hooks';
import { USER_ROLES } from 'src/constants/user-roles';
import { shareTestToStudent } from 'src/api/staff/tests';
import { updateTestInCategory } from 'src/api/categories';

import Iconify from 'src/components/iconify/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import ShareDialog from 'src/components/sharing/share-dialog-new';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';

import { ICategoryItem } from 'src/types/category';

import TestSearch, { ITestItem } from './categoryies-test-search';

const sortTests = (tests: NonNullable<ICategoryItem['tests']>) => {
  const testMap: { [key: string]: NonNullable<ICategoryItem['tests']>[number] } = {};
  let primaryTest: NonNullable<ICategoryItem['tests']>[number] | null = null;

  // For non-primary tests without lowerThreshold, prevTestId & nextTestId values
  const legacyTests = tests.filter(
    (t) => t.lowerThreshold === null && t.prevTestId === null && t.nextTestId === null,
  );

  // Create a map of tests by their id and identify the primary test
  tests.forEach((test) => {
    testMap[test.id] = test;
    if (test.isPrimary || test.upperThreshold === null || test.upperThreshold === 0) {
      primaryTest = test;
    }
  });

  if (!primaryTest) {
    return [];
  }

  if (primaryTest && (primaryTest as any).upperThreshold === 0) {
    (primaryTest as any).upperThreshold = null;
  }
  (primaryTest as any).isPrimary = true;

  const sortedTests: NonNullable<ICategoryItem['tests']> = [];
  let currentTest: NonNullable<ICategoryItem['tests']>[number] | null = primaryTest;

  // Add tests to the sorted list based on nextTestId
  while (currentTest) {
    sortedTests.push(currentTest);
    currentTest = currentTest.nextTestId ? testMap[currentTest.nextTestId] : null;
  }

  // For legacy tests that existed in the category, before the lowerThreshold, prevTestId & nextTestId fields were added
  legacyTests.forEach((t) => {
    if (primaryTest!.id !== t.id) {
      sortedTests[sortedTests.length - 1].nextTestId = t.id;
      t.prevTestId = sortedTests[sortedTests.length - 1].id;
      t.lowerThreshold = 0;
      sortedTests.push(t);
    }
  });

  return sortedTests;
};

export default function CategoryEditViewTests({
  tests,
  id,
}: Required<Pick<ICategoryItem, 'tests' | 'id'>>) {
  const queryClient = useQueryClient();

  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();
  const shareDialog = useBoolean(false);

  const [excludeIds, setExcludeIds] = useState<string[]>([]);
  const [tempSelectedTest, setTempSelectedTest] = useState<ITestItem | null>(null);

  const [shareTestId, setShareTestId] = useState<string>('');

  const EditCategoryTestsSchema = Yup.object({
    tests: Yup.array().of(
      Yup.object({
        checked: Yup.boolean().default(true),
        upperThreshold: Yup.number()
          .nullable()
          .when(['isPrimary', 'checked'], {
            is: (isPrimary: boolean, checked: boolean) => !isPrimary && checked,
            then: (schema) =>
              schema.min(1, '% > 0').max(100, '% < 101').required('Upper threshold is required'),
            otherwise: (schema) => schema.nullable(),
          }),
        lowerThreshold: Yup.number()
          .nullable()
          .when(['isPrimary', 'checked'], {
            is: (isPrimary: boolean, checked: boolean) => !isPrimary && checked,
            then: (schema) =>
              schema.min(1, '% > 0').max(100, '% < 101').required('Lower threshold is required'),
            otherwise: (schema) => schema.nullable(),
          }),
        prevTestId: Yup.string().nullable(),
        nextTestId: Yup.string().nullable(),
        isPrimary: Yup.boolean().default(false),
        name: Yup.string().required(),
        id: Yup.string().required(),
        totalResults: Yup.number().notRequired(),
        totalQuestions: Yup.number().notRequired(),
      }).test('is test have value', 'test cannot be empty', (val) => {
        if (!val.id || !val.name) return false;
        return true;
      }),
    ),
  });

  type EditCategoryTestsType = Yup.InferType<typeof EditCategoryTestsSchema>;
  type TestType = NonNullable<EditCategoryTestsType['tests']>[number];

  const NewCategoryTestSchema = Yup.object().shape({
    checked: Yup.boolean(),
    upperThreshold: Yup.number()
      .nullable()
      .when('checked', {
        is: true,
        then: (schema) => schema.min(1, '% > 0').max(100, '% < 101').required(''),
      }),
    lowerThreshold: Yup.number()
      .nullable()
      .when('checked', {
        is: true,
        then: (schema) => schema.min(1, '% > 0').max(100, '% < 101'),
      }),
    prevTestId: Yup.string().nullable(),
    nextTestId: Yup.string().nullable(),
    isPrimary: Yup.boolean().default(false),
    test: Yup.object({
      name: Yup.string(),
      id: Yup.string(),
    }),
    // .test('is test have value', 'test cannot be empty', (val) => {
    //   if (checkedNewWatcher && (!val.id || !val.name)) return false;
    //   return true;
    // }),
  });

  const defaultValues = useMemo(
    () => ({ tests: sortTests(tests).map((tes) => ({ checked: true, ...tes })) }),
    [tests],
  );

  const newTestDefaultValues = {
    checked: false,
    upperThreshold: 1,
    lowerThreshold: 1,
    prevTestId: null,
    nextTestId: null,
    isPrimary: false,
    test: {
      name: '',
      id: '',
    },
  };

  const methods = useForm({
    resolver: yupResolver(EditCategoryTestsSchema),
    defaultValues,
  });

  const newTestMethod = useForm({
    resolver: yupResolver(NewCategoryTestSchema),
    defaultValues: newTestDefaultValues,
  });

  const {
    handleSubmit: newTestHandleSubmit,
    watch: newTestWatch,
    reset: newTestReset,
    setValue: setNewTestValue,
  } = newTestMethod;
  const checkedNewWatcher = newTestWatch('checked');

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting, isDirty, errors },
    setValue,
  } = methods;
  const formTests = watch('tests');

  const isFormValid = Object.keys(errors).length === 0;
  const isUpdateButtonDisabled = !isDirty || !isFormValid;

  useEffect(() => {
    const allCurrTestIds = formTests?.map((field) => field?.id as string).filter(Boolean) || [];

    setExcludeIds(allCurrTestIds);
  }, [formTests]);

  const updateTest = (index: number, newTestData: ITestItem | null) => {
    const updatedTests = (formTests || []).map((test, i) => {
      if (i === index) {
        if (newTestData) {
          return {
            ...test,
            ...newTestData,
            nextTestId: test.nextTestId,
            prevTestId: test.prevTestId,
            isPrimary: test.isPrimary, // Preserve the isPrimary status
          };
        }
        // If clearing the test, keep it in the array but clear its data
        return {
          ...test,
          id: '',
          name: '',
          // Preserve other properties
          isPrimary: test.isPrimary,
          nextTestId: test.nextTestId,
          prevTestId: test.prevTestId,
          upperThreshold: test.upperThreshold,
          lowerThreshold: test.lowerThreshold,
          checked: test.checked,
        };
      }
      return test;
    });

    // Update adjacent tests' links
    if (newTestData) {
      if (index > 0) {
        updatedTests[index].prevTestId = updatedTests[index - 1].id;
        if (updatedTests[index - 1].id) {
          updatedTests[index - 1].nextTestId = newTestData.id;
        }
      }
      if (index < updatedTests.length - 1) {
        updatedTests[index].nextTestId = updatedTests[index + 1].id;
        if (updatedTests[index + 1].id) {
          updatedTests[index + 1].prevTestId = newTestData.id;
        }
      }
    }

    setValue('tests', updatedTests);

    // Update excludeIds
    const allCurrTestIds = updatedTests.map((field) => field.id).filter(Boolean);
    setExcludeIds(allCurrTestIds);
  };

  const onSubmitNew = newTestHandleSubmit(async (data: any) => {
    if (!tempSelectedTest) {
      return;
    }

    const lastTestIndex = formTests!.length - 1;

    const newTest = {
      checked: true,
      upperThreshold: data.upperThreshold,
      lowerThreshold: 1,
      nextTestId: null,
      prevTestId: formTests![lastTestIndex].id,
      isPrimary: false,
      ...tempSelectedTest,
    };

    // Update the previous test's nextTestId to reference the new test's id
    const updatedFields = [...formTests!, newTest];
    updatedFields[lastTestIndex].nextTestId = newTest.id;

    setValue('tests', updatedFields);

    newTestReset();
    setTempSelectedTest(null);
    queryClient.invalidateQueries({
      queryKey: [keys.staff.categories.fetchTestForCategories],
    });
  });

  useEffect(() => {
    if (tempSelectedTest) {
      setNewTestValue('test', tempSelectedTest);
    } else {
      setNewTestValue('test', { name: '', id: '' });
    }
  }, [tempSelectedTest, setNewTestValue]);

  const onSubmit = handleSubmit(async (categoryTests: EditCategoryTestsType) => {
    // Filter out unchecked tests and update links
    const updatedTests = (categoryTests.tests || []).reduce<TestType[]>(
      (acc, test, index, array) => {
        if (test.checked) {
          const updatedTest: TestType = { ...test };

          // Find the next checked test
          const nextCheckedTest = array.slice(index + 1).find((tst) => tst.checked);
          // Find the previous checked test
          const prevCheckedTest = [...array]
            .slice(0, index)
            .reverse()
            .find((tst) => tst.checked);

          updatedTest.nextTestId = nextCheckedTest ? nextCheckedTest.id : null;
          updatedTest.prevTestId = prevCheckedTest ? prevCheckedTest.id : null;

          acc.push(updatedTest);
        }
        return acc;
      },
      [],
    );

    const transformedData = updatedTests.map((test) => ({
      testId: test.id,
      upperThreshold: test.isPrimary ? null : test.upperThreshold,
      lowerThreshold: test.isPrimary ? null : test.lowerThreshold,
      nextTestId: test.nextTestId,
      prevTestId: test.prevTestId,
      isPrimary: test.isPrimary,
    }));

    try {
      await updateTestInCategory(id, transformedData);
      enqueueSnackbar(t('categories_edit_page.test.saved_test'), { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: [keys.staff.categories.fetchCategory] });

      // Update the form state with the new test arrangement
      setValue('tests', updatedTests);
    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.message;
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  const handleRemove = (testId: string) => {
    const updatedFormTests = [...formTests!];
    const testIndex = formTests?.findIndex((test) => test.id === testId);
    if (testIndex && testIndex !== -1) {
      if (testIndex === formTests!.length - 1) {
        updatedFormTests[testIndex - 1].nextTestId = null;
      } else {
        updatedFormTests[testIndex - 1].nextTestId = updatedFormTests[testIndex + 1].id;
        updatedFormTests[testIndex + 1].prevTestId = updatedFormTests[testIndex - 1].id;
      }
      updatedFormTests.splice(testIndex, 1);
      setValue('tests', updatedFormTests);

      setExcludeIds(excludeIds.filter((eId) => eId !== testId));
    }
  };
  const handleShare = async (testId: string) => {
    setShareTestId(testId);
    shareDialog.onTrue();
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {formTests?.map((test, index) => (
          <React.Fragment key={index}>
            {test?.isPrimary ? (
              <Stack
                key={test.id}
                spacing={2}
                marginY={5}
                sx={{ p: 3, bgcolor: 'background.neutral' }}
                borderRadius={2}
              >
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <Iconify icon="solar:eye-bold" />
                  <Typography variant="subtitle1" fontWeight="bold">
                    {convertToTitleCase(t('common.primary_test'))}
                  </Typography>
                </Stack>
                <TestSearch
                  excludeIds={excludeIds}
                  name="tests.0"
                  isForPrimaryTest
                  infoText={t('categories_listing_page.new_category_form.primary_test_info')}
                  onTestSelect={(newTest) => updateTest(index, newTest)}
                  currentCategoryId={id}
                  disabled={isAnalyst}
                />
                <Divider sx={{ borderStyle: 'dashed' }} />
                {test.id && (
                  <Typography variant="body2">
                    {t('categories_edit_page.test.no_results_questions', {
                      total_results: test?.totalResults,
                      total_questions: test?.totalQuestions,
                    })}
                  </Typography>
                )}
              </Stack>
            ) : (
              <React.Fragment key={test.id}>
                <Divider sx={{ borderStyle: 'dashed', marginY: 5 }} />
                <Stack flexDirection="row" alignItems="center" marginTop={3} marginBottom={2}>
                  <RHFCheckbox
                    disabled={isAnalyst}
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: 'grey.900',
                        }}
                      >
                        {t('common.if')}{' '}
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: '16px',
                            fontWeight: '900',
                            lineHeight: '24px',
                            color: 'grey.900',
                            display: 'inline',
                          }}
                        >
                          {formTests[index - 1]?.name}
                        </Typography>{' '}
                        {t('common.test').toLowerCase()} {t('categories_edit_page.test.result_is')}
                      </Typography>
                    }
                    name={`tests[${index}].checked`}
                    color="primary"
                  />
                  <Typography variant="h6" marginRight={1}>
                    &gt;
                  </Typography>
                  <RHFTextField
                    disabled={isAnalyst}
                    size="small"
                    sx={{
                      width: '75px',
                      '& .MuiFormHelperText-root': {
                        position: 'absolute',
                        bottom: '-1.5rem',
                      },
                    }}
                    type="number"
                    name={`tests[${index}].upperThreshold`}
                    label="%"
                    InputProps={{
                      endAdornment: '%',
                    }}
                  />
                  <Typography variant="body2" marginLeft={1}>
                    {t('categories_edit_page.test.go_to')}
                  </Typography>
                </Stack>
                <Stack display="flex" flexDirection="row" alignItems="center" gap={2}>
                  <Iconify icon="solar:forward-2-bold" color="blue" />
                  <Stack spacing={2} sx={{ p: 3, width: 1 }} boxShadow={2} borderRadius={2}>
                    <Stack flexDirection="row" gap={1} alignItems="center">
                      <Iconify icon="solar:eye-closed-bold" />
                      <TestSearch
                        disabled={isAnalyst}
                        excludeIds={excludeIds}
                        name={`tests[${index}]`}
                        infoText={t(
                          'categories_listing_page.new_category_form.non_primary_test_info',
                        )}
                        onTestSelect={(newTest) => updateTest(index, newTest)}
                        currentCategoryId={id}
                      />
                    </Stack>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box>
                        {test.id && (
                          <Typography variant="body2">
                            {t('categories_edit_page.test.no_results_questions', {
                              total_results: test.totalResults,
                              total_questions: test.totalQuestions,
                            })}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <IconButton
                          LinkComponent={RouterLink}
                          target="_blank"
                          href={paths.test.answerTest(test.id)}
                          disabled={!test.id}
                        >
                          <Iconify icon="eva:external-link-outline" />
                        </IconButton>
                        <IconButton disabled={!test.id} onClick={() => handleShare(test.id)}>
                          <Iconify icon="solar:share-bold" />
                        </IconButton>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: '#fff',
                            color: 'customColors.custom5',
                            '&:hover': {
                              backgroundColor: (theme) =>
                                alpha(theme.palette.customColors.custom5, 0.08),
                            },
                          }}
                          size="small"
                          onClick={() => handleRemove(test.id)}
                          endIcon={<Iconify icon="mingcute:close-line" />}
                          disabled={isAnalyst}
                        >
                          {t('common.remove')}
                        </Button>
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
                <Stack flexDirection="row" alignItems="center" marginTop={3} marginBottom={2}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: 'grey.900',
                      marginLeft: '2rem',
                    }}
                  >
                    {t('common.if')} {t('common.test').toLowerCase()}{' '}
                    {t('categories_edit_page.test.result_is')}{' '}
                  </Typography>
                  <Typography variant="h6" marginLeft={1} marginRight={1}>
                    &lt;
                  </Typography>
                  <RHFTextField
                    size="small"
                    sx={{
                      width: '75px',
                      '& .MuiFormHelperText-root': {
                        position: 'absolute',
                        bottom: '-1.5rem',
                      },
                    }}
                    type="number"
                    name={`tests[${index}].lowerThreshold`}
                    label="%"
                    InputProps={{
                      endAdornment: '%',
                    }}
                    disabled={isAnalyst}
                  />
                  <Typography variant="body2" marginLeft={1}>
                    {t('categories_edit_page.test.go_to')}{' '}
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '16px',
                        fontWeight: '900',
                        lineHeight: '24px',
                        color: 'grey.900',
                        display: 'inline',
                      }}
                    >
                      {formTests[index - 1]?.name}
                    </Typography>
                  </Typography>
                </Stack>
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
        <Divider sx={{ borderStyle: 'dashed', marginY: 5 }} />
      </FormProvider>
      <FormProvider methods={newTestMethod} onSubmit={onSubmitNew}>
        <Stack
          gap={2}
          display={isAnalyst ? 'none' : 'flex'}
          flexDirection="row"
          sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
          alignItems="center"
        >
          <Stack gap={2} flexDirection="row" alignItems="center">
            <RHFCheckbox
              name="checked"
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: checkedNewWatcher ? 'grey.900' : 'grey.500',
                  }}
                >
                  {t('common.if')}{' '}
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '900',
                      lineHeight: '24px',
                      color: checkedNewWatcher ? 'grey.900' : 'grey.500',
                      display: 'inline',
                    }}
                  >
                    {formTests && formTests[formTests.length - 1]?.name}
                  </Typography>{' '}
                  {t('categories_edit_page.test.result_is')}
                </Typography>
              }
              color="primary"
              sx={{
                marginRight: '0',
              }}
            />
            <Typography variant="h6">&gt;</Typography>
            <RHFTextField
              size="small"
              sx={{
                width: '75px',
                '& .MuiFormHelperText-root': {
                  position: 'absolute',
                  bottom: '-1.5rem',
                },
              }}
              type="number"
              name="upperThreshold"
              label="%"
              InputProps={{
                endAdornment: '%',
              }}
              disabled={!checkedNewWatcher}
            />
          </Stack>

          <Stack gap={2} flexDirection="row" alignItems="center" sx={{ flexGrow: 1 }}>
            <Typography variant="body2">{t('categories_edit_page.test.go_to')}</Typography>
            <TestSearch
              disabled={!checkedNewWatcher}
              excludeIds={excludeIds}
              name="test"
              isForAppendNewTest
              sx={{ flexGrow: 1 }}
              currentCategoryId={id}
              tempSelectedTest={tempSelectedTest}
              onTempTestSelect={setTempSelectedTest}
            />
            <Button
              variant="contained"
              color="success"
              type="submit"
              endIcon={<Iconify icon="mingcute:add-line" />}
              disabled={!checkedNewWatcher || !tempSelectedTest}
            >
              {t('common.add')}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
      <Divider sx={{ display: isAnalyst ? 'none' : 'block', my: 5 }} />
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid
          container
          display={isAnalyst ? 'none' : 'flex'}
          sx={{ justifyContent: 'flex-end', width: '100%' }}
        >
          <Button
            onClick={() => {
              reset();
            }}
            size="large"
            color="inherit"
            variant="outlined"
            sx={{ alignSelf: 'center', marginRight: 2 }}
          >
            {t('common.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            color="success"
            disabled={isUpdateButtonDisabled}
            loading={isSubmitting}
          >
            {t('common.update')}
          </LoadingButton>
        </Grid>
      </FormProvider>

      <ShareDialog
        dialog={shareDialog}
        title={t('test_listing_page.share_test')}
        studentLinkToShare={paths.test.answerTest(shareTestId)}
        onShare={shareTestToStudent}
        addtionalPayloadToShareFn={{ testName: 'Test name' }}
      />
    </>
  );
}
