import v from 'voca';
import React, { ChangeEvent, useCallback } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  Select,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';

import { IPremisesTableFilters, IPremisesTableFilterValue } from 'src/types/premises';

type props = {
  filters: IPremisesTableFilters;
  onFilters: (name: string, value: IPremisesTableFilterValue) => void;
  fileDownloadHandler: () => void;
  isFileLoading: boolean;
};

const STATUS = {
  active: 'active',
  inActive: 'inactive',
};

export default function PremisesToolbar({
  filters,
  onFilters,
  fileDownloadHandler,
  isFileLoading,
}: props) {
  const { t } = useTranslate();
  const handleFilter = useCallback(
    (
      event: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
    ) => {
      onFilters(field, event.target.value);
    },
    [onFilters],
  );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
      }}
    >
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 287 },
        }}
      >
        <InputLabel id="type-multiple-checkbox-label">{t('common.status')}</InputLabel>

        <Select
          labelId="type-multiple-checkbox-label"
          id="type-multiple-checkbox"
          value={filters.status}
          onChange={(e) => handleFilter(e, 'status')}
          input={<OutlinedInput label={t('common.status')} />}
          sx={{ textTransform: 'capitalize' }}
        >
          {Object.values(STATUS).map((option) => (
            <MenuItem key={option} value={option}>
              {v.titleCase(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={filters.searchText}
          onChange={(e) => handleFilter(e, 'searchText')}
          placeholder={t('common.search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          variant="outlined"
          size="small"
          endIcon={<Iconify icon="mingcute:download-3-fill" />}
          loading={isFileLoading}
          sx={{
            color: 'customColors.custom1',
            borderColor: 'customColors.custom1',
            minWidth: 113,
          }}
          onClick={fileDownloadHandler}
        >
          <Typography variant="overline" textTransform="none" whiteSpace="nowrap">
            {t('common.export')} CSV
          </Typography>
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
