import { keepPreviousData } from '@tanstack/react-query';

import { useGetData } from 'src/hooks/use-get-data';

import { endpoints } from 'src/utils/axios';

import queryKeys from 'src/constants/query-keys';

export function useGetTestLevelList() {
  return useGetData({
    args: [endpoints.staff.testLevels],
    queryKey: [queryKeys.staff.fetchTestLevels],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}
