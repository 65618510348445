export async function getUserDetilsFromUserCredential(userCredential: any) {
  const { user, _tokenResponse } = userCredential;

  const userToken = await user?.getIdToken(true);

  return {
    email: user.email as string,
    firstName: _tokenResponse.firstName as string,
    lastName: _tokenResponse.lastName as string,
    refreshToken: user.refreshToken as string,
    token: userToken as string,
  };
}
