import { UseQueryResult, keepPreviousData } from '@tanstack/react-query';

import { useGetData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints } from 'src/utils/axios';

import queryKeys from 'src/constants/query-keys';

import { IListFilters, IFetchBaseParams } from 'src/types/misc';
import {
  ICollaboratorsItem,
  ICollaboratorsCreateItem,
  ICollaboratorsTableFilters,
} from 'src/types/collaborators';

export function useGetCollaboratorsList(
  params?: IListFilters<Omit<ICollaboratorsTableFilters, 'searchText'>>,
): UseQueryResult<{ data: ICollaboratorsItem[] }, Error> {
  return useGetData({
    args: [endpoints.staff.staffs, { params }],
    queryKey: [queryKeys.staff.staffs.fetchAllStaffs, params],
    options: {
      placeholderData: keepPreviousData,
      enabled: !params?.disableUntilSearch || !!params?.searchQuery,
    },
  });
}

export function useGetCollaborator(
  params?: IFetchBaseParams,
): UseQueryResult<ICollaboratorsItem, Error> {
  return useGetData({
    args: [`${endpoints.staff.staffs}/${params?.id}`],
    queryKey: [queryKeys.staff.staffs.fetchStaff, params?.id],
  }) as UseQueryResult<ICollaboratorsItem, Error>;
}

export async function deleteStaff(id: string) {
  const URL = `${endpoints.staff.staffs}/${id}`;
  await axiosInstance.delete(URL);
}

export async function createStaff(data: Partial<ICollaboratorsCreateItem>) {
  const URL = `${endpoints.staff.staffs}`;
  await axiosInstance.post(URL, data);
}

export async function updateStaff(data: Partial<ICollaboratorsCreateItem>) {
  const URL = `${endpoints.staff.staffs}/${data?.id}`;
  await axiosInstance.put(URL, data);
}
