import { useMemo, useState, useEffect, ReactNode, useContext, createContext } from 'react';

import Box from '@mui/material/Box';

import Footer from './footer';
import Header from './header';

type Props = {
  children: ReactNode;
};

type MainLayoutContextType = {
  showFixedHeader: boolean;
};

const MainLayoutContext = createContext<MainLayoutContextType | undefined>(undefined);

export const useMainLayoutContext = () => {
  const context = useContext(MainLayoutContext);
  if (!context) {
    throw new Error('useMainLayoutContext must be used within a MainLayoutProvider');
  }
  return context;
};

export const PADDING_TOP = 0;

const MainLayout = ({ children }: Props) => {
  const [showFixedHeader, setShowFixedHeader] = useState(true);
  // const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setShowFixedHeader(false);
      } else {
        setShowFixedHeader(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const matches = matchRoutes(
  //   [{ path: paths.test.answerTest(':id') }, { path: paths.test.retryTest(':id') }],
  //   location,
  // );
  // const isSkipHeaderRoute = matches !== null;

  const contextValue = useMemo(() => ({ showFixedHeader }), [showFixedHeader]);

  return (
    <MainLayoutContext.Provider value={contextValue}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
        <Header />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            // ...(!homePage && {
            pt: PADDING_TOP,
            // }),
          }}
        >
          {children}
        </Box>

        <Footer />
      </Box>
    </MainLayoutContext.Provider>
  );
};

export default MainLayout;
