import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { useFormContext } from 'react-hook-form';
import { useMemo, Dispatch, SetStateAction } from 'react';

import {
  Box,
  Card,
  Stack,
  alpha,
  Button,
  Divider,
  Checkbox,
  TextField,
  Typography,
  CardContent,
} from '@mui/material';

import uuidv4 from 'src/utils/uuidv4';
import { lightenHexColor } from 'src/utils/lighten-hex-color';
import { updateATestsQuestionsAndSectionsFormFields } from 'src/utils/test';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { customColors } from 'src/theme/palette';
import { USER_ROLES } from 'src/constants/user-roles';
import { ANSWERS_TYPES_CONFIG } from 'src/constants/questions';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

import { ITestQuestion } from 'src/types/staff/tests';

import TestQuestionMedia from './test-question-media';

function TestQuestionsBox({
  question,
  id,
  questionNumber,
  handleEditQuestion,
  handleQuestionSelect,
  handleRemoveQuestionFromSection,
  selectedQuestions,
  setDndItems,
}: {
  question: ITestQuestion;
  id: string;
  questionNumber: number;
  handleEditQuestion: (questionId: string) => void;
  handleQuestionSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    question: ITestQuestion,
  ) => void;
  handleRemoveQuestionFromSection: (p: null, q: ITestQuestion) => void;
  selectedQuestions: ITestQuestion[];
  setDndItems: Dispatch<SetStateAction<any>>;
}) {
  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: transform ? 999 : 'auto',
      }
    : undefined;

  const { setValue } = useFormContext();
  const { t } = useTranslate();

  const isSelected = useMemo(
    () => selectedQuestions.some((q) => (q.id || q._tempId) === (id || question._tempId)),
    [id, question._tempId, selectedQuestions],
  );

  const rightAnswers: typeof question.answers = [];
  const wrongAnswers: typeof question.answers = [];

  let maxPoints: number = 0;
  question.answers.forEach((answer) => {
    const rightAnswer = answer.points > 0;

    // For 'multiple' questions, sum up all correct points
    if (question.questionType === 'multiple' && rightAnswer) {
      maxPoints += answer.points;
    }

    // For 'text' and 'single choice' questions, find the maximum points achieved
    else {
      maxPoints = Math.max(maxPoints, answer.points);
    }

    // Push answer to rightAnswers or wrongAnswers array based on correctness
    if (rightAnswer) {
      rightAnswers.push(answer);
    } else {
      wrongAnswers.push(answer);
    }
  });

  const handleRemoveQuestion = () => {
    setDndItems((prevItems: any) => {
      let prevItemsCopy = JSON.parse(JSON.stringify(prevItems));

      if (question.sectionId) {
        const originalSectionIdx = prevItemsCopy.findIndex(
          (item: any) => item.id === question.sectionId || item._itemId === question.sectionId,
        );
        if (originalSectionIdx !== -1) {
          const originalQuestionIdx = prevItemsCopy[originalSectionIdx].questions.findIndex(
            (q: any) => q.id === question.id,
          );
          if (originalQuestionIdx !== -1)
            prevItemsCopy[originalSectionIdx].questions.splice(originalQuestionIdx, 1);
          prevItemsCopy[originalSectionIdx].questions = prevItemsCopy[
            originalSectionIdx
          ].questions.map((item: any, idx: number) => ({
            ...item,
            position: idx + 1,
          }));
        }
      } else {
        const originalQuestionIdx = prevItemsCopy.findIndex((item: any) => item.id === question.id);
        if (originalQuestionIdx !== -1) {
          prevItemsCopy.splice(originalQuestionIdx, 1);
          prevItemsCopy = prevItemsCopy.map((item: any, idx: number) => ({
            ...item,
            position: idx + 1,
          }));
        }
      }
      updateATestsQuestionsAndSectionsFormFields(prevItemsCopy, setValue);
      return prevItemsCopy;
    });
  };

  const handleDuplicateQuestion = () => {
    setDndItems((prevItems: any) => {
      let prevItemsCopy = JSON.parse(JSON.stringify(prevItems));

      if (question.sectionId) {
        const originalSectionIdx = prevItemsCopy.findIndex(
          (item: any) => item.id === question.sectionId || item._itemId === question.sectionId,
        );
        if (originalSectionIdx !== -1) {
          const originalQuestionIdx = prevItemsCopy[originalSectionIdx].questions.findIndex(
            (q: any) => q.id === question.id,
          );
          if (originalQuestionIdx !== -1) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id: _id, ...rest } = question;
            const newQuestion = {
              ...rest,
              questionId: null,
              question: `${question.question} (${t('common.duplicate')})`,
              _tempId: uuidv4(),
              position: question.position + 1,
            };
            prevItemsCopy[originalSectionIdx] = {
              ...prevItemsCopy[originalSectionIdx],
              questions: [
                ...prevItemsCopy[originalSectionIdx].questions.slice(0, originalQuestionIdx + 1),
                newQuestion,
                ...prevItemsCopy[originalSectionIdx].questions
                  .slice(originalQuestionIdx + 1)
                  .map((q: { position: number }) => ({
                    ...q,
                    position: q.position + 1,
                  })),
              ],
            };
          }
        }
      } else {
        const originalQuestionIdx = prevItemsCopy.findIndex((item: any) => item.id === question.id);
        if (originalQuestionIdx !== -1) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id: _id, ...rest } = question;
          const newQuestion = {
            ...rest,
            questionId: null,
            question: `${question.question} (${t('common.duplicate')})`,
            _tempId: uuidv4(),
            position: question.position + 1,
          };
          prevItemsCopy = [
            ...prevItemsCopy.slice(0, originalQuestionIdx + 1),
            newQuestion,
            ...prevItemsCopy.slice(originalQuestionIdx + 1).map((q: { position: number }) => ({
              ...q,
              position: q.position + 1,
            })),
          ];
        }
      }
      updateATestsQuestionsAndSectionsFormFields(prevItemsCopy, setValue);
      return prevItemsCopy;
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleQuestionSelect(event, question);
  };

  return (
    <Stack ref={setNodeRef} style={style} alignItems="center" direction="row" width="100%">
      <Stack alignItems="center" marginRight="1rem" direction="row">
        <Iconify
          icon="akar-icons:dot-grid"
          width="20px"
          color="grey.600"
          {...listeners}
          {...attributes}
          sx={{
            cursor: 'pointer',
            display: isAnalyst ? 'none' : 'block',
          }}
        />
        <Typography
          variant="body2"
          sx={{
            mx: '0.75rem',
            color: 'grey.600',
            fontWeight: '900',
            fontSize: '16px',
            lineHeight: '16px',
          }}
        >
          {questionNumber}
        </Typography>

        <Checkbox
          sx={{
            p: '0.25rem',
            display: isAnalyst ? 'none' : 'inline-flex',
          }}
          checked={isSelected}
          onChange={handleSelectChange}
        />
      </Stack>
      <Card
        sx={{
          display: 'grid',
          width: '100%',
          overflow: 'auto',
          boxShadow: (theme) => (isSelected ? theme.customShadows.z8 : theme.customShadows.z4),
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '50fr 50fr', sm: '60fr 40fr', lg: '70fr 30fr' },
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateAreas: '"icon qs"',
                gridTemplateColumns: '5fr 95fr',
                alignItems: 'center',
                gap: '2rem',
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  bgcolor: 'grey.300',
                  borderRadius: 3,
                  gridArea: 'icon',
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Iconify
                  icon={ANSWERS_TYPES_CONFIG[question.questionType]?.iconifyIcon}
                  width={20}
                  color="grey.600"
                />
              </Box>
              <Stack sx={{ gridArea: 'qs' }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 16,
                    fontWeight: '900',
                    color: 'info.dark',
                    lineHeight: '24px',
                    mb: '8px',
                  }}
                >
                  {question.question}
                </Typography>

                <Box>
                  {rightAnswers.map((ans, idx) => (
                    <Stack key={idx} direction="row" alignItems="center" gap="4px">
                      <Iconify icon="lets-icons:check-fill" width={18} color="success.main" />
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '18px',
                          color: 'success.main',
                        }}
                      >
                        {ans.text}
                      </Typography>
                    </Stack>
                  ))}
                </Box>

                <Box sx={{ mt: 2 }}>
                  {wrongAnswers.map((answer, idx) => (
                    <Stack key={idx} direction="row" alignItems="center" gap="4px">
                      <Iconify icon="gridicons:cross-circle" width={16} color="grey.500" />
                      <Typography
                        key={idx}
                        variant="body1"
                        sx={{
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '18px',
                          color: 'grey.500',
                        }}
                      >
                        {answer.text}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Stack>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateAreas: '"media qNo"',
                gridTemplateColumns: { xs: '60fr 40fr', lg: '70fr 30fr' },
                gap: '2rem',
                alignItems: 'center',
              }}
            >
              <TestQuestionMedia currentQuestion={question} sx={{ gridArea: 'media' }} />

              <Box sx={{ gridArea: 'qNo' }}>
                <TextField
                  label={t('test_edit_page.questions.max_points')}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="medium"
                  type="number"
                  defaultValue={maxPoints}
                  fullWidth
                  sx={{
                    width: '100%',
                    maxWidth: '96px',
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Divider sx={{ borderStyle: 'dashed', my: '1.5rem' }} />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack display={isAnalyst ? 'none' : 'flex'} direction="row" spacing={3}>
              <Button
                disabled={isSelected}
                sx={{
                  fontSize: '13px',
                  fontWeight: '700',
                  color: 'customColors.custom1',
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  '&:hover': {
                    backgroundColor: (theme) => alpha(theme.palette.customColors.custom1, 0.08),
                  },
                }}
                variant="text"
                onClick={handleDuplicateQuestion}
              >
                {t('common.duplicate')}
                <Iconify
                  icon="pajamas:duplicate"
                  width={20}
                  marginLeft={1}
                  sx={{ color: !isSelected ? 'customColors.custom1' : 'grey.500' }}
                />
              </Button>
              <Button
                disabled={isSelected}
                sx={{
                  fontSize: '13px',
                  fontWeight: '700',
                  color: 'customColors.custom1',
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  '&:hover': {
                    backgroundColor: (theme) => alpha(theme.palette.customColors.custom1, 0.08),
                  },
                }}
                variant="text"
                onClick={() => handleEditQuestion(question._tempId || question.id)}
              >
                {t('common.edit')}
                <Iconify
                  icon="fluent:edit-12-filled"
                  width={20}
                  marginLeft={1}
                  sx={{ color: !isSelected ? 'customColors.custom1' : 'grey.500' }}
                />
              </Button>
              {question.sectionId && (
                <Button
                  disabled={isSelected}
                  variant="contained"
                  endIcon={<Iconify icon="octicon:dash-24" />}
                  sx={{
                    minWidth: '90px',
                    backgroundColor: '#fff',
                    color: 'customColors.custom1',
                    '&:hover': {
                      backgroundColor: (theme) => alpha(theme.palette.customColors.custom1, 0.08),
                    },
                  }}
                  onClick={() => handleRemoveQuestionFromSection(null, question)}
                >
                  {t('test_edit_page.questions.remove_from_section')}
                </Button>
              )}
              <Button
                disabled={isSelected}
                variant="contained"
                endIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                sx={{
                  minWidth: '90px',
                  backgroundColor: '#ffffff',
                  color: 'customColors.custom5',
                  '&:hover': {
                    backgroundColor: (theme) => alpha(theme.palette.customColors.custom5, 0.08),
                  },
                }}
                onClick={handleRemoveQuestion}
              >
                {t('common.delete')}
              </Button>
            </Stack>

            <Stack direction="row" spacing={1}>
              {question.tags.map((tag, idx) => (
                <Label
                  key={idx}
                  variant="soft"
                  sx={{
                    backgroundColor: lightenHexColor(customColors.custom2, 84),
                    color: 'customColors.custom2',
                    fontWeight: '900',
                    fontSize: '13px',
                    lineHeight: '18px',
                  }}
                >
                  {tag}
                </Label>
              ))}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default TestQuestionsBox;
