import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import { paths } from 'src/routes/paths';

import { HOST_API, FIREBASE_API } from 'src/config-global';

import {
  AUTH_STORAGE_KEYS,
  localStorageGetItem,
  localStorageSetItem,
  localStorageRemoveItem,
} from './storage-available';

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    login: '/auth/login',
    link: {
      login: '/auth/link/login',
    },
    refreshToken: '/auth/refresh-token',
    // passwords: '/auth/passwords',
    firebase: {
      login: '/auth/login',
    },
    register: {
      student: '/auth/register/student',
    },
    checkAccountExist: '/auth/account-exist',
  },
  staff: {
    questions: '/staff/questions',
    test: {
      category: '/staff/tests/category',
      questions: '/staff/questions/test',
      tests: '/staff/tests',
      testQuestions: '/staff/tests/questions',
      share: '/staff/tests/share',
    },
    notifications: {
      templates: 'staff/templates',
      templateByRole: (role: string) => `staff/templates/${role}`,
      template: 'staff/template',
      setting: 'staff/template/settings',
    },
    categories: {
      root: '/staff/categories',
      share: '/staff/categories/share',
    },
    staffs: '/staff/staffs',
    premises: '/staff/premises',
    account: {
      root: '/staff/account',
      provider: {
        disconnect: '/staff/account/provider/disconnect',
      },
    },
    students: '/staff/students',
    shareViaEmail: '/staff/share/email',
    student: {
      results: (id: string) => `staff/students/${id}/results`,
      notifications: (id: string) => `staff/students/${id}/notifications`,
    },
    results: {
      root: '/staff/results',
      share: '/staff/results/share',
    },
    testLevels: 'staff/testLevels',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  utils: {
    files: {
      link: '/utils/files/link/create',
    },
  },
  general: {
    categories: '/general/categories',
    tests: {
      details: (id: string) => `/general/tests/${id}`,
      submit: '/general/tests/submit',
    },
    results: {
      root: '/general/results',
      details: (id: string) => `/general/results/${id}`,
    },
    premises: '/general/premises',
  },
  student: {
    account: {
      root: '/student/account',
      notification: (premiseId: string) => `/student/account/notification/${premiseId}`,
      provider: {
        disconnect: '/student/account/provider/disconnect',
      },
    },
  },
};

// ----------------------------------------------------------------------

const createAxiosInstance = ({ baseURL }: { baseURL: string }) => {
  const tokenKey = AUTH_STORAGE_KEYS.accessToken;

  const customAxiosInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const firebaseApp = initializeApp(FIREBASE_API);

  const AUTH = getAuth(firebaseApp);

  customAxiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = localStorageGetItem(tokenKey);

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  const fetchCurrentUser = () =>
    new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        AUTH,
        (user: any) => {
          unsubscribe();
          resolve(user);
        },
        reject,
      );
    });

  const refreshAuthToken = async (failedRequest: any) => {
    try {
      const userData = JSON.parse(localStorageGetItem(AUTH_STORAGE_KEYS.user) || '{}');

      if (!userData) {
        return await Promise.reject(failedRequest);
      }

      const currentUser: any = await fetchCurrentUser();

      if (!currentUser) {
        throw new Error('User not authenticated');
      }

      if (currentUser) {
        const accessToken = await currentUser.getIdToken(true);
        localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
        localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, currentUser.refreshToken);
      }

      return await Promise.resolve();
    } catch (error) {
      Object.keys(AUTH_STORAGE_KEYS).forEach((key) => {
        localStorageRemoveItem(key);
      });

      window.location.href = `${paths.login}`;
      return Promise.reject(error);
    }
  };

  createAuthRefreshInterceptor(customAxiosInstance, refreshAuthToken, {
    statusCodes: [401, 403],
  });

  return customAxiosInstance;
};

const axiosInstance = createAxiosInstance({ baseURL: HOST_API });

export default axiosInstance;

// ----------------------------------------------------------------------

export const REQUEST_METHODS = {
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const {
    data: { data },
  } = await axiosInstance.get(url, { ...config });

  return data;
};

export const postFetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const {
    data: { data },
  } = await axiosInstance.post(url, { ...config });

  return data;
};

export const mutateData = async (config: AxiosRequestConfig) => {
  const {
    data: { data },
  } = await axiosInstance({ ...config });

  return data;
};
