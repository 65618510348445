import React, { useMemo } from 'react';

import { Box, Container } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useGetTemplateList } from 'src/api/templates';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import { ITemplateItem } from 'src/types/templates';

import EmailTemplatesItems from '../list/email-templates-items';

export default function EmailListView() {
  const { data }: any = useGetTemplateList();
  const { t } = useTranslate();

  const templates: ITemplateItem[] = useMemo(() => data || [], [data]);
  const settings = useSettingsContext();
  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('common.email')}
        links={[
          { name: t('common.dashboard'), href: '#' },
          { name: t('common.notification') },
          { name: t('common.email') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr',
          },
          gap: 5,
          width: '100%',
        }}
      >
        {templates.map((template) => (
          <EmailTemplatesItems key={template.role} template={template} />
        ))}
      </Box>
    </Container>
  );
}
