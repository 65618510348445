import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { fetcher, postFetcher } from 'src/utils/axios';

export const useGetData = <T>({ queryKey, args, options }: any): UseQueryResult<T, Error> =>
  useQuery({
    queryKey,
    queryFn: () => fetcher(args),
    ...options,
  });

export const usePostData = <T>({ queryKey, args, options }: any): UseQueryResult<T, Error> =>
  useQuery({
    queryKey,
    queryFn: () => postFetcher(args),
    ...options,
  });

// interface IFetchResult<ResultType> {
//   data?: ResultType;
//   isLoading: boolean;
//   error: any;
//   isValidating: boolean;
