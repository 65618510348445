import { useLocation } from 'react-router';
import { QueryCache } from '@tanstack/react-query';
import { useMemo, useEffect, useReducer, useCallback } from 'react';
import {
  getAuth,
  signOut,
  OAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
  // GithubAuthProvider,
  // sendEmailVerification,
  signInWithEmailLink,
  // TwitterAuthProvider,
  // sendSignInLinkToEmail,
  isSignInWithEmailLink,
  // signInWithEmailAndPassword,
} from 'firebase/auth';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import axiosInstance, { endpoints } from 'src/utils/axios';
import { getUserDetilsFromUserCredential } from 'src/utils/firebase';
// config
import {
  AUTH_STORAGE_KEYS,
  localStorageGetItem,
  localStorageSetItem,
  localStorageRemoveItem,
} from 'src/utils/storage-available';

import { SITE_URL } from 'src/config-global';
import { checkAccountExists, sendSignInLinkToEmail } from 'src/api/auth';

import { firebaseApp } from './lib';
import { AuthContext } from './auth-context';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

const AUTH = getAuth(firebaseApp);

// const DB = getFirestore(firebaseApp);

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const router = useRouter();
  const { pathname } = useLocation();

  const isNormalLoginPage = pathname === paths.login;

  // LOGOUT
  const logout = useCallback(async () => {
    const queryCache = new QueryCache();

    await signOut(AUTH);

    Object.keys(AUTH_STORAGE_KEYS).forEach((key) => {
      localStorageRemoveItem(key);
    });

    queryCache.clear();

    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // Initialize
  const initialize = useCallback(() => {
    try {
      const accessToken = localStorageGetItem(AUTH_STORAGE_KEYS.accessToken);
      const refreshToken = localStorageGetItem(AUTH_STORAGE_KEYS.refreshToken);
      const userData = JSON.parse(localStorageGetItem(AUTH_STORAGE_KEYS.user) || '{}');

      if (accessToken) {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...userData,
              accessToken,
              refreshToken,
            },
          },
        });
      } else {
        logout();
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error('Failed to initialize user session:', error);
      logout();
    }
  }, [logout]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  // const login = useCallback(async (email: string, password: string) => {
  //   const loginDetails = await signInWithEmailAndPassword(AUTH, email, password);
  //   if (loginDetails.user) {
  //     const accessToken = await loginDetails?.user?.getIdToken();
  //     const { refreshToken } = loginDetails.user;

  //     const token: { token: string } = { token: accessToken };
  //     localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
  //     localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, refreshToken);
  //     const userDetails = await axiosInstance.post(
  //       endpoints.auth.firebase.login,
  //       token,
  //     );

  //     const { userData } = userDetails?.data?.data || {};

  //     localStorageSetItem(AUTH_STORAGE_KEYS.user, JSON.stringify(userData));

  //     dispatch({
  //       type: Types.LOGIN,
  //       payload: {
  //         user: {
  //           ...userData,
  //           accessToken,
  //           refreshToken,
  //         },
  //       },
  //     });
  //   }
  // }, []);

  const sendLoginLinkToEmail = useCallback(
    async (email: string) => {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: `${SITE_URL}${paths.completeLogin}`,
        // This must be true.
        handleCodeInApp: true,
      };

      const isEmailExist = await checkAccountExists({ email });

      if (!isEmailExist) {
        router.push(isNormalLoginPage ? paths.signup : `${pathname}?auth=sign-up`, {
          state: {
            email,
            magicLink: true,
          },
        });
        return;
      }

      await sendSignInLinkToEmail({
        email,
        redirectURL: actionCodeSettings.url,
      });
      // await sendSignInLinkToEmail(AUTH, email, actionCodeSettings);
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
    },
    [router, isNormalLoginPage, pathname],
  );

  const completeLoginWithEmailLink = useCallback(async () => {
    if (isSignInWithEmailLink(AUTH, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email: string | null = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }

      if (email) {
        // The client SDK will parse the code from the link for you.
        try {
          const result = await signInWithEmailLink(AUTH, email, window.location.href);

          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          const accessToken = await result.user.getIdToken(true);
          const { refreshToken } = result.user;

          const token: { token: string } = { token: accessToken };

          const userDetails = await axiosInstance.post(endpoints.auth.firebase.login, token);
          const { userData } = userDetails?.data?.data || {};

          localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
          localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, refreshToken);

          localStorageSetItem(AUTH_STORAGE_KEYS.user, JSON.stringify(userData));

          dispatch({
            type: Types.LOGIN,
            payload: {
              user: {
                ...userData,
                accessToken,
                refreshToken,
              },
            },
          });
          return userData.role;
        } catch (error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          logout();
          throw new Error(error);
        }
      }
    }
    return 'student';
  }, [logout]);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    try {
      const loginDetails = await signInWithPopup(AUTH, provider);
      if (loginDetails.user) {
        const isEmailExist = await checkAccountExists({ email: loginDetails.user!.email! });

        if (!isEmailExist) {
          const userDetails = await getUserDetilsFromUserCredential(loginDetails);

          router.push(isNormalLoginPage ? paths.signup : `${pathname}?auth=sign-up`, {
            state: {
              ...userDetails,
            },
          });

          return true;
        }
        // eslint-disable-next-line
        const accessToken = await loginDetails?.user?.getIdToken(true);
        const { refreshToken } = loginDetails.user;

        const token: { token: string } = { token: accessToken };
        localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
        localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, refreshToken);
        const userDetails = await axiosInstance.post(endpoints.auth.firebase.login, token);

        const { userData } = userDetails?.data?.data || {};

        localStorageSetItem(AUTH_STORAGE_KEYS.user, JSON.stringify(userData));

        dispatch({
          type: Types.LOGIN,
          payload: {
            user: {
              ...userData,
              accessToken,
              refreshToken,
            },
          },
        });
        return false;
      }
      return false;
    } catch (error) {
      console.error('Login failed: ', error);
      logout();
      return false;
    }
  }, [logout, router, isNormalLoginPage, pathname]);

  const loginWithApple = useCallback(async () => {
    const appleProvider = new OAuthProvider('apple.com');
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    try {
      const loginDetails = await signInWithPopup(AUTH, appleProvider);
      if (loginDetails.user) {
        const isEmailExist = await checkAccountExists({ email: loginDetails.user!.email! });

        if (!isEmailExist) {
          const userDetails = await getUserDetilsFromUserCredential(loginDetails);

          router.push(isNormalLoginPage ? paths.signup : `${pathname}?auth=sign-up`, {
            state: {
              ...userDetails,
            },
          });

          return true;
        }
        // eslint-disable-next-line
        const accessToken = await loginDetails?.user?.getIdToken(true);

        const { refreshToken } = loginDetails.user;

        const token: { token: string } = { token: accessToken };
        localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
        localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, refreshToken);
        const userDetails = await axiosInstance.post(endpoints.auth.firebase.login, token);

        const { userData } = userDetails?.data?.data || {};

        localStorageSetItem(AUTH_STORAGE_KEYS.user, JSON.stringify(userData));

        dispatch({
          type: Types.LOGIN,
          payload: {
            user: {
              ...userData,
              accessToken,
              refreshToken,
            },
          },
        });
        return false;
      }
      return false;
    } catch (error) {
      console.error('Login failed: ', error);
      logout();
      return false;
    }
  }, [logout, router, isNormalLoginPage, pathname]);

  const signupWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    try {
      const loginDetails = await signInWithPopup(AUTH, provider);
      return loginDetails;
    } catch (error) {
      logout();
      return error;
    }
  }, [logout]);

  const signupWithApple = useCallback(async () => {
    const appleProvider = new OAuthProvider('apple.com');
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    try {
      const loginDetails = await signInWithPopup(AUTH, appleProvider);
      return loginDetails;
    } catch (error) {
      logout();
      return error;
    }
  }, [logout]);

  const signupWithProviderComplete = useCallback(
    async ({
      userData,
      accessToken,
      refreshToken,
    }: {
      userData: any;
      accessToken: string;
      refreshToken: string;
    }) => {
      // eslint-disable-next-line
      localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
      localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, refreshToken);
      localStorageSetItem(AUTH_STORAGE_KEYS.user, JSON.stringify(userData));
      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...userData,
            accessToken,
            refreshToken,
          },
        },
      });
    },
    [],
  );

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      // login,
      logout,
      // register,
      loginWithGoogle,
      signupWithGoogle,
      signupWithApple,
      signupWithProviderComplete,
      // loginWithGithub,
      // loginWithTwitter,
      loginWithApple,
      sendLoginLinkToEmail,
      completeLoginWithEmailLink,
    }),
    [
      status,
      state.user,
      signupWithGoogle,
      signupWithApple,
      signupWithProviderComplete,
      //
      // login,
      logout,
      // register,
      // loginWithGithub,
      loginWithGoogle,
      // loginWithTwitter,
      loginWithApple,
      sendLoginLinkToEmail,
      completeLoginWithEmailLink,
    ],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
