import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import StaffGuard from 'src/auth/guard/staff-guard';
import DashboardLayout from 'src/layouts/dashboard';
import { USER_ROLES } from 'src/constants/user-roles';
import TestsPage from 'src/pages/staff/test/tests/list';
import TestEditPage from 'src/pages/staff/test/tests/edit';
import ResultsListPage from 'src/pages/staff/results/list';
import PremisesListPage from 'src/pages/staff/premises/list';
import PremisesEditPage from 'src/pages/staff/premises/edit';
import StudentListViewPage from 'src/pages/staff/student/list';
import QuestionNewPage from 'src/pages/staff/test/questions/new';
import QuestionsAppPage from 'src/pages/staff/test/questions/list';
import QuestionsEditPage from 'src/pages/staff/test/questions/edit';
import StudentDetailsViewPage from 'src/pages/staff/student/details';
import EmailViewPage from 'src/pages/staff/notifications/email/list';
import CategoriesViewPage from 'src/pages/staff/test/categories/list';
import CollaboratorsListPage from 'src/pages/staff/collaborators/list';
import loadingAnimation from 'src/assets/animations/LoadingAnimation.json';
import CategoryEditViewPage from 'src/pages/staff/test/categories/edit-view';
import SettingsEditViewPage from 'src/pages/staff/notifications/settings/edit';
import StudentTemplateEditViewPage from 'src/pages/staff/notifications/email/edit';
import StudentTemplateDetailsViewPage from 'src/pages/staff/notifications/email/details';

import { LottieAnimation } from 'src/components/animate';

import { paths } from '../paths';

const StaffAccountEditPage = lazy(() => import('src/pages/staff/account/edit'));

// ----------------------------------------------------------------------

// Lazily import page components here

// ----------------------------------------------------------------------

export const staffRoutes = [
  {
    path: paths.staff.root,
    element: (
      <StaffGuard roles={[USER_ROLES.ADMIN, USER_ROLES.ANALYST]}>
        <DashboardLayout>
          <Suspense
            fallback={
              <LottieAnimation
                animationData={loadingAnimation}
                bgColor="#043F6B"
                height={180}
                width={180}
              />
            }
          >
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </StaffGuard>
    ),
    children: [
      { path: paths.staff.results, element: <ResultsListPage /> },
      {
        path: paths.staff.test.root,
        element: (
          <Suspense
            fallback={
              <LottieAnimation
                animationData={loadingAnimation}
                bgColor="#043F6B"
                height={180}
                width={180}
              />
            }
          >
            <Outlet />
          </Suspense>
        ),
        children: [
          {
            path: paths.staff.test.categories.root,
            children: [
              { element: <CategoriesViewPage />, index: true },
              {
                path: paths.staff.test.categories.editView(':id'),
                element: <CategoryEditViewPage />,
              },
            ],
          },
          {
            path: paths.staff.test.tests.root,
            children: [
              { element: <TestsPage />, index: true },
              {
                path: paths.staff.test.tests.edit(':id'),
                element: <TestEditPage />,
              },
            ],
          },
          {
            path: paths.staff.test.questions.root,
            element: (
              <StaffGuard roles={[USER_ROLES.ADMIN]}>
                <Outlet />
              </StaffGuard>
            ),
            children: [
              { element: <QuestionsAppPage />, index: true },
              {
                path: paths.staff.test.questions.new,
                element: <QuestionNewPage />,
              },
              {
                path: paths.staff.test.questions.edit(':id'),
                element: <QuestionsEditPage />,
              },
            ],
          },
        ],
      },
      { path: paths.staff.students, element: <StudentListViewPage /> },
      { path: paths.staff.studentDetails(':id'), element: <StudentDetailsViewPage /> },
      { path: paths.staff.premises, element: <PremisesListPage /> },
      { path: paths.staff.premiseEdit(':id'), element: <PremisesEditPage /> },
      { path: paths.staff.collaborators, element: <CollaboratorsListPage /> },
      {
        path: paths.staff.notifications.root,
        element: (
          <StaffGuard roles={[USER_ROLES.ADMIN]}>
            <Suspense
              fallback={
                <LottieAnimation
                  animationData={loadingAnimation}
                  bgColor="#043F6B"
                  height={180}
                  width={180}
                />
              }
            >
              <Outlet />
            </Suspense>
          </StaffGuard>
        ),
        children: [
          {
            path: paths.staff.notifications.email,
            element: <EmailViewPage />,
          },
          {
            path: paths.staff.notifications.getEmailTemplates(':roleType'),
            element: <StudentTemplateDetailsViewPage />,
          },
          {
            path: `${paths.staff.notifications.getEmailTemplates(':roleType')}/:templateName`,
            element: <StudentTemplateEditViewPage />,
          },
          {
            path: paths.staff.notifications.settings,
            element: <SettingsEditViewPage />,
          },
        ],
      },
      { path: paths.staff.account, element: <StaffAccountEditPage /> },
    ],
  },
];
