import { Container } from '@mui/system';

import { useSettingsContext } from 'src/components/settings';

import TestTable from '../test-table';

export default function TestsListView() {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <TestTable />
    </Container>
  );
}
