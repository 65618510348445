import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'src/routes/hooks';

import { EmailDetailsView } from 'src/sections/staff/notifications/email/view';

export default function EmailStudentDetailsViewPage() {
  const params = useParams();
  return (
    <>
      <Helmet>
        <title>Student: details</title>
      </Helmet>
      {params?.roleType && <EmailDetailsView role={params.roleType} />}
    </>
  );
}
