import merge from 'lodash/merge';
// date fns
import {
  // fr as frFRAdapter,
  it as itITAdapter,
  enUS as enUSAdapter,
} from 'date-fns/locale';

// core (MUI)
import {
  enUS as enUSCore,
  itIT as itITCore,
  // frFR as frFRCore,
} from '@mui/material/locale';
// data grid (MUI)
import {
  enUS as enUSDate,
  itIT as itITDate,
  // frFR as frFRDate,
} from '@mui/x-date-pickers/locales';
import {
  enUS as enUSDataGrid,
  itIT as itITDataGrid,
  // frFR as frFRDataGrid,
} from '@mui/x-data-grid';
// date pickers (MUI)

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const itLang = {
  label: 'Italian',
  value: 'it',
  systemValue: merge(itITDate, itITDataGrid, itITCore),
  adapterLocale: itITAdapter,
  icon: 'flagpack:it',
  numberFormat: {
    code: 'it-IT',
    currency: 'EUR',
  },
};

export const enLang = {
  label: 'English',
  value: 'en',
  systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
  adapterLocale: enUSAdapter,
  icon: 'flagpack:gb-nir',
  numberFormat: {
    code: 'en-US',
    currency: 'USD',
  },
};

export const allLangs = [
  itLang,
  enLang,
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
  //   adapterLocale: frFRAdapter,
  //   icon: 'flagpack:fr',
  //   numberFormat: {
  //     code: 'fr-Fr',
  //     currency: 'EUR',
  //   },
  // },
];

export const defaultLang = allLangs[0]; // Italian

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
