import Button from '@mui/material/Button';
import { Theme, SxProps } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function LoginButton({ sx }: Props) {
  const { t } = useTranslate();
  return (
    <Button
      component={RouterLink}
      href="/login"
      variant="text"
      sx={{
        color: 'primary.contrastText',
        m: 0,
        p: 0,
        justifyContent: 'flex-end',
        minWidth: 'max-content',
        lineHeight: '15px',
        fontSize: '14px',
        fontWeight: 900,
        '&.MuiButton-root:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiButton-startIcon': {
          marginRight: '12px',
        },
        ...sx,
      }}
      startIcon={<Iconify icon="material-symbols:login" sx={{ color: 'primary.contrastText' }} />}
    >
      {t('common.login')}
    </Button>
  );
}
