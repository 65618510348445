import React from 'react';
import { useNavigate } from 'react-router';

import { Paper, Stack, Button, ListItemText } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify/iconify';

import { ITemplateRoleItem } from 'src/types/templates';

type Props = {
  template: ITemplateRoleItem;
  role: string;
};

export default function EmailTemplatesRoleItems({ template, role }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslate();

  const renderIcon = (
    <Iconify icon="mdi:email" height={24} width={24} color="customColors.custom4" />
  );

  const renderText = (
    <ListItemText
      primary="Email Object"
      sx={{ width: '100%' }}
      secondary={template.label}
      primaryTypographyProps={{
        noWrap: true,
        typography: 'body2',
        color: 'text.disabled',
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        alignItems: 'center',
        display: 'inline-flex',
        typography: 'subtitle1',
        fontWeight: 900,
        color: 'text.primary',
      }}
    />
  );

  const renderAction = (
    <Button
      variant="text"
      startIcon={<Iconify icon="solar:pen-bold" />}
      sx={{ color: 'action.active' }}
      onClick={() =>
        navigate(`${paths.staff.notifications.getEmailTemplates(role)}/${template.name}`)
      }
    >
      {t('common.edit')}
    </Button>
  );
  return (
    <Stack
      component={Paper}
      variant="elevation"
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'unset', sm: 'center' }}
      height={{ md: 94 }}
      sx={{
        borderRadius: 0.5,
        bgcolor: 'unset',
        boxShadow: (theme) => theme.customShadows.z12,
        cursor: 'pointer',
        position: 'relative',
        p: { xs: 2.5, sm: 2 },
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: (theme) => theme.customShadows.z20,
        },
      }}
    >
      <div
        style={{
          width: 'fit-content',
          height: 'fit-content',
          padding: 6,
          backgroundColor: '#F9FAFB',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {renderIcon}
      </div>
      {renderText}
      {renderAction}
    </Stack>
  );
}
