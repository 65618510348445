import { utils, writeFile, AOA2SheetOpts } from 'xlsx';

export const csvDownloader = ({
  fileName,
  fileType = 'xlsx',
  headers = [],
  rows = [],
}: {
  fileName: string;
  fileType: string;
  headers: string[];
  rows: (string | number)[][];
}) => {
  const wb = utils.book_new();
  // creating sheet and adding data from 2nd row of column A.
  // Leaving first row to add Heading
  const ws = utils.aoa_to_sheet(
    rows as unknown[][],
    { origin: 'A2', skipHeader: true } as AOA2SheetOpts,
  );

  // adding heading to the first row of the created sheet.
  // sheet already have contents from above statement.
  utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });

  // Ensure that the sheet name does not exceed 31 characters
  const safeSheetName = fileName.substring(0, 31).replace(/[/:?*[\]]/g, '_');

  utils.book_append_sheet(wb, ws, safeSheetName);

  // Ensure that the file name does not exceed 250 characters. Max limit 255 char including file extension
  const safeFileName = fileName.substring(0, 250).replace(/[/:?*[\]]/g, '_');

  writeFile(wb, `${safeFileName}.${fileType}`);
};
