import { useCallback } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';

import {
  ICollaboratorsTableFilters,
  ICollaboratorsTableFilterValue,
} from 'src/types/collaborators';

// ----------------------------------------------------------------------

type Props = StackProps & {
  filters: ICollaboratorsTableFilters;
  onFilters: (name: string, value: ICollaboratorsTableFilterValue) => void;
  //
  onResetFilters: VoidFunction;
  //
  results: number;
};

export default function CollaboratorsTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) {
  const { t } = useTranslate();
  const handleRemoveKeyword = useCallback(() => {
    onFilters('searchText', '');
  }, [onFilters]);

  const handleRemoveRole = useCallback(() => {
    onFilters('role', '');
  }, [onFilters]);

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          {t('common.results_found').toLowerCase()}
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap">
        {!!filters.role && (
          <Block label={`${t('common.role')}:`}>
            <Chip label={filters.role} size="small" onDelete={() => handleRemoveRole()} />
          </Block>
        )}

        {!!filters.searchText && (
          <Block label={`${t('common.keyword')}:`}>
            <Chip label={filters.searchText} size="small" onDelete={handleRemoveKeyword} />
          </Block>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          {t('common.clear')}
        </Button>
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
  label: string;
};

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}
