import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Box, Tab, Tabs, Stack, Container, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import { convertToTitleCase } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useGetStudentDetails } from 'src/api/students';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import { IStudentDetailsItem } from 'src/types/students';

import ResultTab from '../details/student-details-result-tab';
import GeneraLab from '../details/student-details-general-tab';
import NotificationsTab from '../details/student-details-notifications-tab';

type Props = {
  id: string;
};

export const STUDENTS_TAB = {
  general: '0',
  result: '1',
  notification: '3',
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function StudentDetailsView({ id }: Props) {
  const { data }: any = useGetStudentDetails(id);
  const { t } = useTranslate();

  const settings = useSettingsContext();

  const query = useQuery();

  const navigate = useNavigate();

  const studentItem: IStudentDetailsItem = useMemo(() => data, [data]);

  const [currentTab, setCurrentTab] = React.useState(STUDENTS_TAB.general);

  React.useEffect(() => {
    setCurrentTab(query.get('activeTab') || STUDENTS_TAB.general);
  }, [query]);

  const handleTabChange = (_: any, newValue: string) => {
    if (newValue === STUDENTS_TAB.general) {
      navigate('.', { replace: true });
    } else {
      navigate(`?activeTab=${newValue}`);
    }
    setCurrentTab(newValue);
  };
  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('common.students')}
        links={[
          { name: t('common.dashboard'), href: '#' },
          { name: t('common.students'), href: paths.staff.students },
          { name: `${data && data.name ? data.name : t('common.student')}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        mb={2}
      >
        <Tabs value={currentTab} onChange={handleTabChange} sx={{ marginBottom: '1rem' }}>
          <Tab
            label={
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Iconify icon="mdi:card-account-details" />
                <Typography fontWeight={900}>{t('common.general')}</Typography>
              </Stack>
            }
            value={STUDENTS_TAB.general}
          />
          <Tab
            label={
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Iconify icon="solar:bill-list-bold" />
                <Typography fontWeight={900}>
                  {convertToTitleCase(t('student_edit_page.test_results'))}
                </Typography>
              </Stack>
            }
            value={STUDENTS_TAB.result}
          />

          <Tab
            label={
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Iconify icon="solar:bell-bing-bold" />
                <Typography fontWeight={900}>{t('common.notifications')}</Typography>
              </Stack>
            }
            value={STUDENTS_TAB.notification}
          />
        </Tabs>
      </Box>
      {currentTab === STUDENTS_TAB.general && studentItem && (
        <GeneraLab studentItem={studentItem} />
      )}
      {currentTab === STUDENTS_TAB.result && id && <ResultTab id={id} />}
      {currentTab === STUDENTS_TAB.notification && <NotificationsTab id={id} />}
    </Container>
  );
}
