import { convertToTitleCase } from 'src/utils/misc';

import {
  IQuestionType,
  QUESTION_TYPES,
  IQuestionMediaType,
  IQuestionTypeConfig,
} from 'src/types/question';

export const ANSWERS_TYPES_CONFIG: Record<IQuestionType, IQuestionTypeConfig> = {
  [QUESTION_TYPES.Single]: {
    iconifyIcon: 'tabler:checkbox',
    label: 'Single Choice',
    minimumAnswersRequired: 2,
    minimumRightAnswersRequired: 1,
    answerInputLabel: 'Option',
  },
  [QUESTION_TYPES.Multiple]: {
    iconifyIcon: 'material-symbols:tv-options-edit-channels',
    label: 'Multiple Choice',
    minimumAnswersRequired: 3,
    minimumRightAnswersRequired: 2,
    answerInputLabel: 'Choices',
  },
  [QUESTION_TYPES.Text]: {
    iconifyIcon: 'fluent:text-field-24-regular',
    label: 'Text Input',
    minimumAnswersRequired: 1,
    minimumRightAnswersRequired: 1,
    answerInputLabel: 'Text',
  },
};

export const getAnswerTypesConfig = (t: any) => ({
  [QUESTION_TYPES.Single]: {
    iconifyIcon: 'tabler:checkbox',
    label: convertToTitleCase(t('questions_listing_page.single_choice')),
    minimumAnswersRequired: 2,
    minimumRightAnswersRequired: 1,
    answerInputLabel: 'Option',
  },
  [QUESTION_TYPES.Multiple]: {
    iconifyIcon: 'material-symbols:tv-options-edit-channels',
    label: convertToTitleCase(t('questions_listing_page.multiple_choice')),
    minimumAnswersRequired: 3,
    minimumRightAnswersRequired: 2,
    answerInputLabel: 'Choices',
  },
  [QUESTION_TYPES.Text]: {
    iconifyIcon: 'fluent:text-field-24-regular',
    label: convertToTitleCase(t('questions_listing_page.text_input')),
    minimumAnswersRequired: 1,
    minimumRightAnswersRequired: 1,
    answerInputLabel: 'Text',
  },
});

export const POSITIVE_POINTS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const NEGATIVE_POINTS = [-0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10];

export const QUESTION_MEDIA_TYPES: Record<string, IQuestionMediaType> = {
  image: {
    label: 'Add image',
    accept: { 'image/jpeg': [], 'image/png': [] },
    iconifyIcon: 'solar:gallery-wide-bold',
    dbField: 'imagePath',
    // maxSize: 4145728
  },
  audio: {
    label: 'Add audio',
    accept: { 'audio/mpeg': [], 'audio/wav': [] },
    iconifyIcon: 'solar:microphone-bold',
    dbField: 'audioPath',
    // maxSize: 4145728
  },
  video: {
    label: 'Add video',
    accept: { 'video/mp4': [], 'video/quicktime': [] },
    iconifyIcon: 'solar:videocamera-record-bold',
    dbField: 'videoPath',
    // maxSize: 14145728
  },
};

export const getQuestionMediaTypes = (t: any) => ({
  image: {
    label: t('test_edit_page.questions.add_image'),
    accept: { 'image/jpeg': [], 'image/png': [] },
    iconifyIcon: 'solar:gallery-wide-bold',
    dbField: 'imagePath',
    // maxSize: 4145728
  },
  audio: {
    label: t('test_edit_page.questions.add_audio'),
    accept: { 'audio/mpeg': [], 'audio/wav': [] },
    iconifyIcon: 'solar:microphone-bold',
    dbField: 'audioPath',
    // maxSize: 4145728
  },
  video: {
    label: t('test_edit_page.questions.add_video'),
    accept: { 'video/mp4': [], 'video/quicktime': [] },
    iconifyIcon: 'solar:videocamera-record-bold',
    dbField: 'videoPath',
    // maxSize: 14145728
  },
});

export const TABS = [
  { name: 'General', iconifyIcon: 'material-symbols:info' },
  { name: 'Tests', iconifyIcon: 'solar:notes-bold' },
];
