import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { PremiseEditView } from 'src/sections/staff/premises/view';

export default function CollaboratorsEdit() {
  const params = useParams();

  const { id } = params;
  return (
    <>
      <Helmet>
        <title>Premises: Edit</title>
      </Helmet>

      <PremiseEditView id={id} />
    </>
  );
}
