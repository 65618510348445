import React from 'react';

import { Tab, Tabs } from '@mui/material';

import Iconify from 'src/components/iconify';

interface QuestionMediaTabsProps {
  currentTab: string;
  handleChangeTab: (event: React.SyntheticEvent, newValue: string) => void;
  QUESTION_MEDIA_TYPES: Record<string, any>;
}

const QuestionMediaTabs: React.FC<QuestionMediaTabsProps> = ({
  currentTab,
  handleChangeTab,
  QUESTION_MEDIA_TYPES,
}) => (
  <Tabs value={currentTab} onChange={handleChangeTab} sx={{ width: 1 }} scrollButtons={false}>
    {Object.keys(QUESTION_MEDIA_TYPES).map((type) => (
      <Tab
        key={type}
        sx={{ flexGrow: 1, fontWeight: 'fontWeightBold' }}
        value={type}
        iconPosition="end"
        icon={<Iconify icon={QUESTION_MEDIA_TYPES[type].iconifyIcon} width={24} />}
        label={QUESTION_MEDIA_TYPES[type].label}
      />
    ))}
  </Tabs>
);

export default QuestionMediaTabs;
