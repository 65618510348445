import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';

export interface ITestItem {
  id: string;
  name: string;
}

// ----------------------------------------------------------------------

type Props = {
  name: string;
  query: string;
  results: ITestItem[];
  searchInput: string;
  fullWidth?: boolean;
  onSearch: (inputValue: string) => void;
  isForAppendNewTest?: boolean;
  loading?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
};

export default function PremisesSearch({
  name,
  query,
  results,
  searchInput,
  onSearch,
  loading,
  disabled = false,
  disableClearable = false,
}: Props) {
  const { control, setValue, clearErrors, setError, getValues } = useFormContext();
  const { t } = useTranslate();

  return (
    <Controller
      name={name}
      disabled={disabled}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          loading={loading}
          autoHighlight
          fullWidth
          disableClearable={disableClearable}
          inputValue={searchInput}
          onChange={(e, newVal, reason) => {
            if (reason === 'clear') {
              setValue(name, { name: '', id: '' });
              setError(name, {
                type: 'required',
                message: t('premises_edit_page.premises_required'),
              });
            } else {
              setValue(name, { ...getValues(name), ...newVal });
              clearErrors();
            }
          }}
          popupIcon={null}
          options={results}
          onInputChange={(event, newVal) => onSearch(newVal)}
          getOptionLabel={(option) => option.name ?? ''}
          noOptionsText={<SearchNotFound query={query} sx={{ bgcolor: 'unset' }} />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('common.search_test')}
              helperText={
                error?.message && (
                  <FormHelperText
                    error={!!error?.message}
                    sx={{ display: 'flex', alignItems: 'center', margin: 0, gap: '4px' }}
                  >
                    <Iconify icon="material-symbols:info" />
                    {error?.message}
                  </FormHelperText>
                )
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="pajamas:search"
                      width={13}
                      height={13}
                      sx={{ color: 'text.primary', ml: 1 }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {loading ? <Iconify icon="svg-spinners:8-dots-rotate" sx={{ mr: -3 }} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}
