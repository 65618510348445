import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import { useSettingsContext } from 'src/components/settings';
import QuestionForm from 'src/components/question/question-form';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

export default function QuestionsCreateView() {
  const settings = useSettingsContext();
  const { t } = useTranslate();
  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('common.questions')}
        links={[
          { name: t('common.dashboard'), href: '#' },
          { name: t('common.questions'), href: paths.staff.test.questions.root },
          { name: t('questions_edit_page.add_new_question'), href: '#' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <QuestionForm />
    </Container>
  );
}
