import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { IInputSectionDetailsProps } from './types';

const InputSectionDetails = ({ label, details }: IInputSectionDetailsProps) => (
  <Grid md={4}>
    <Typography variant="h6" fontWeight="fontWeightBold" sx={{ mb: 1 }}>
      {label}
    </Typography>
    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      {details}
    </Typography>
  </Grid>
);

export default InputSectionDetails;
