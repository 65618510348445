import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { LOCAL_STORAGE_KEYS } from 'src/constants/misc';

import { ITestSection, ITestQuestion } from 'src/types/staff/tests';

import { isQuestionOrSection } from './misc';
import { AUTH_STORAGE_KEYS } from './storage-available';

export const getTestAnswerKey = ({ testId, userId }: { testId: string; userId?: string }) =>
  ['test', testId, userId].filter(Boolean).join('_');

const removeFrontSlash = (url: string) => {
  if (url?.startsWith('/')) {
    return url?.slice(1);
  }
  return url;
};

export const handleUpdateTestAnsUserIdForNonAuthUser = async () => {
  const testSubmissionURL = localStorage.getItem(LOCAL_STORAGE_KEYS.TEST_SUBMISSION_URL);

  if (!testSubmissionURL) return;

  const testId = removeFrontSlash(testSubmissionURL)?.split('/')?.[1] || '';

  if (!testId) return;

  const userData = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.user) || '{}');

  if (!userData?.id) return;

  const currentTestKey = getTestAnswerKey({ testId });

  const storedTestData = localStorage.getItem(currentTestKey);

  if (!storedTestData) return;

  localStorage.removeItem(currentTestKey);

  const localTestData = JSON.parse(storedTestData);
  const newTestKey = getTestAnswerKey({ testId, userId: userData.id });
  const payload = { ...localTestData, userId: userData.id };

  await updateNonAuthTest({ key: newTestKey, payload });
};

const updateNonAuthTest = async ({ key, payload }: { key: string; payload: any }) =>
  new Promise((resolve) => {
    setTimeout(() => {
      localStorage.setItem(key, JSON.stringify(payload));
      resolve({ key, payload });
    }, 10);
  });

export const updateATestsQuestionsAndSectionsFormFields = (
  items: Array<ITestQuestion | ITestSection>,
  setValue: UseFormSetValue<FieldValues>,
) => {
  const processItems = (type: 'question' | 'section') =>
    items.filter((item) => isQuestionOrSection(item) === type).map(({ _tempId, ...rest }) => rest);

  setValue('independentQuestions', processItems('question') as any);
  setValue('sections', processItems('section') as any);
};
