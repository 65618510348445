import { useState, ChangeEvent } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Box, Link, alpha, Switch, Typography, FormControlLabel } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';
import { lightenHexColor } from 'src/utils/lighten-hex-color';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { customColors } from 'src/theme/palette';
import { ANSWERS_TYPES_CONFIG } from 'src/constants/questions';
import { archiveQuestion, updateQuestionLiveStatus } from 'src/api/question';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { IListFilters } from 'src/types/misc';
import { IQuestionItem, IQuestionTableFilters } from 'src/types/question';

// ----------------------------------------------------------------------

type Props = {
  row: IQuestionItem;
  filters: IListFilters<IQuestionTableFilters>;
};

export default function QuestionTableRow({ row, filters }: Props) {
  const { questionType, question, isPublished, tags, totalPoints, id, createdAt } = row;

  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const [activeStatus, setActiveStatus] = useState(isPublished);

  const confirm = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const quickEdit = useBoolean();

  const handleDeleteQuestion = async () => {
    confirm.onFalse();

    try {
      await archiveQuestion(id!);
      enqueueSnackbar(t('questions_listing_page.question_deleted'));
      queryClient.invalidateQueries({ queryKey: [keys.staff.questions.fetchAllQuestions] });
    } catch (error) {
      if (error?.response?.data?.data?.length) {
        enqueueSnackbar(
          t('questions_listing_page.question_deletion_is_causing_issue_with_tests', {
            test_word: error.response.data.length > 1 ? 'tests' : 'test',
            tests: error?.response?.data?.data?.join(','),
          }),
          { variant: 'error' },
        );
      }
    }
  };

  // const isLiveToggling = useBoolean(false);

  const handleLiveToggle = async (e: ChangeEvent<HTMLInputElement>) => {
    const status = e.currentTarget.checked;
    try {
      // isLiveToggling.onTrue();
      await updateQuestionLiveStatus(id!, { isPublished: status });
      setActiveStatus(status);
      // checkout.refetchCart()
      // isLiveToggling.onFalse();
      queryClient.invalidateQueries({ queryKey: [keys.staff.questions.fetchAllQuestions] });
    } catch (error) {
      enqueueSnackbar(error?.message ? error?.message : JSON.stringify(error || '{}'), {
        variant: 'error',
      });
      // isLiveToggling.onFalse();
    }
  };

  const redirectUrl = paths.staff.test.questions.edit(id!);

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Box
            sx={{
              width: '32px',
              height: '32px',
              bgcolor: (theme) => alpha(theme.palette.customColors.custom4, 0.16),
              borderRadius: 3,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Iconify
              icon={ANSWERS_TYPES_CONFIG[questionType]?.iconifyIcon}
              width={20}
              color="customColors.custom4"
            />
          </Box>
        </TableCell>

        <TableCell
          sx={{
            maxWidth: '380px',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontWeight: '900',
              fontSize: '14px',
              lineHeight: '22px',
              color: '#000000',
              cursor: 'pointer',
            }}
          >
            <Link color="inherit" underline="hover" component={RouterLink} href={redirectUrl}>
              {question}
            </Link>
          </Typography>
        </TableCell>

        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {tags?.map((tag, index) => (
            <Label
              key={tag}
              variant="soft"
              sx={{
                backgroundColor: lightenHexColor(customColors.custom3, 84),
                color: 'customColors.custom3',
                fontWeight: '700',
                fontSize: '12px',
                lineHeight: '20px',
                marginLeft: index !== 0 ? '8px' : '0px', //
              }}
            >
              {tag}
            </Label>
          ))}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{totalPoints}</TableCell>

        <TableCell>
          <FormControlLabel
            control={<Switch checked={activeStatus} color="success" onChange={handleLiveToggle} />}
            label={t('common.live')}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{fDate(createdAt)}</TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {/* <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? 'inherit' : 'default'}
              LinkComponent={RouterLink}
              href={redirectUrl}
              onClick={quickEdit.onTrue}
            >
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip> */}

          <Tooltip title={t('common.duplicate')} placement="top" arrow>
            <IconButton
              color={quickEdit.value ? 'inherit' : 'default'}
              LinkComponent={RouterLink}
              href={`${paths.staff.test.questions.edit(id!)}?new=true`}
              onClick={quickEdit.onTrue}
            >
              <Iconify icon="ph:copy-duotone" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('common.delete')} placement="top" arrow>
            <IconButton onClick={confirm.onTrue} sx={{ color: 'error.dark' }}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" />{' '}
            {t('questions_listing_page.delete_question')}
          </Box>
        }
        content={
          <>
            <Typography variant="subtitle1">{t('questions_listing_page.sure_delete')}</Typography>
            <Typography variant="body1">
              {t('questions_listing_page.question_will_be_removed')}
            </Typography>
          </>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteQuestion}>
            {t('common.delete')}
          </Button>
        }
      />
    </>
  );
}
