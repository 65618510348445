import React from 'react';
import { Helmet } from 'react-helmet-async';

import { ResultListView } from 'src/sections/staff/results/view';

export default function ResultListPage() {
  return (
    <>
      <Helmet>
        <title>Results: List</title>
      </Helmet>

      <ResultListView />
    </>
  );
}
