import React from 'react';

import loadingAnimation from 'src/assets/animations/LoadingAnimation.json';

import { LottieAnimation } from 'src/components/animate';

import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthConsumer({ children }: Props) {
  return (
    <AuthContext.Consumer>
      {(auth) =>
        auth.loading ? (
          <LottieAnimation
            animationData={loadingAnimation}
            bgColor="#043F6B"
            height={180}
            width={180}
          />
        ) : (
          children
        )
      }
    </AuthContext.Consumer>
  );
}
