import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';

import { generateMediaUrl } from 'src/utils/misc';

import Image from '../image';

// ----------------------------------------------------------------------

type Props = {
  mediaUrl: string;
  mimeType?: string;
};

export default function SingleFilePreviewCustom({ mediaUrl, mimeType = 'image/jpeg' }: Props) {
  const indexOfSlash = mimeType ? mimeType?.indexOf('/') : -1;
  const valueBeforeSlash = indexOfSlash !== -1 ? mimeType?.substring(0, indexOfSlash) : null;
  if (!valueBeforeSlash) return null;

  const updatedMediaUrl = mediaUrl?.startsWith('blob:') ? mediaUrl : generateMediaUrl(mediaUrl);

  return (
    <Box
      sx={{
        p: 1,
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        position: 'absolute',
      }}
    >
      {valueBeforeSlash === 'image' ? (
        <Image
          alt="file preview"
          src={updatedMediaUrl}
          sx={{
            width: 1,
            height: 1,
            borderRadius: 1,
          }}
        />
      ) : (
        <CardMedia
          component={valueBeforeSlash as any}
          sx={{
            width: 1,
            height: 1,
            borderRadius: 1,
            objectFit: 'cover',
          }}
          controls
          src={updatedMediaUrl}
        />
      )}
    </Box>
  );
}
