import { PopupButton } from '@typeform/embed-react';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import { usePathname } from 'src/routes/hooks';

import { TYPEFORM } from 'src/config-global';
import { USER_ROLES } from 'src/constants/user-roles';
import LoginButton from 'src/layouts/common/login-button';
import LogoutButton from 'src/layouts/common/logout-button';
import ProfileButton from 'src/layouts/common/profile-button';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';
import DashboardButton from 'src/layouts/common/dashboard-button';

import SvgColor from 'src/components/svg-color';
import Scrollbar from 'src/components/scrollbar';
import CustomButton from 'src/components/custom-button/custom-button';

import NavList from './nav-list';
import { NavProps } from '../types';

interface NavMobileProps extends NavProps {
  handleContactBtnClick: VoidFunction;
}

// ----------------------------------------------------------------------

export default function NavMobile({ data, handleContactBtnClick }: NavMobileProps) {
  const pathname = usePathname();
  const { authenticated, user } = useAuthContext();
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" sx={{ bgcolor: '#FFFFFF' }} />
      </IconButton>

      <Drawer
        open={openMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            position: 'relative',
            pb: 5,
            width: 300,
            '&.MuiPaper-root': {
              backgroundColor: 'primary.main',
            },
          },
        }}
      >
        <IconButton
          onClick={handleCloseMenu}
          sx={{
            width: 'fit-content',
            position: 'absolute',
            top: 24,
            right: 24,
          }}
        >
          <SvgColor src="/assets/icons/navbar/ic_close.svg" sx={{ bgcolor: '#FFFFFF' }} />
        </IconButton>
        <Scrollbar
          sx={{
            width: 'fit-content',
            position: 'absolute',
            top: 88,
            paddingX: 3,
          }}
        >
          {authenticated ? (
            <ProfileButton sx={{ marginBottom: 4 }} />
          ) : (
            <LoginButton sx={{ marginBottom: 4 }} />
          )}
          {data.map((list) => {
            if (!list.forRole || (user && user.role === list.forRole)) {
              return <NavList key={list.title} data={list} />;
            }
            return null;
          })}
        </Scrollbar>
        <Stack
          direction="column"
          position="absolute"
          bottom="4.625rem"
          left="0"
          right="0"
          marginX="auto"
          paddingX={3}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            marginBottom={4}
          >
            {authenticated && (
              <>
                <LogoutButton />
                <Box sx={{ color: '#FFFFFF' }}>|</Box>
              </>
            )}
            {authenticated && user?.role !== USER_ROLES.STUDENT && <DashboardButton />}
          </Stack>

          <CustomButton
            customvariant="contact"
            id={TYPEFORM.formId}
            component={user?.role !== USER_ROLES.STUDENT ? PopupButton : undefined}
            onClick={user?.role === USER_ROLES.STUDENT ? handleContactBtnClick : undefined}
          >
            CONTACT US
          </CustomButton>
        </Stack>
      </Drawer>
    </>
  );
}
