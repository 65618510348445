import React, { useState, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

import { useDebounce } from 'src/hooks/use-debounce';

import { convertToTitleCase } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useGetCategoryTestList } from 'src/api/categories';

import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';

export interface ITestItem {
  id: string;
  name: string;
}

type TestSearchProps = {
  name: string;
  excludeIds?: string[];
  infoText?: string;
  fullWidth?: boolean;
  isForAppendNewTest?: boolean;
  isForPrimaryTest?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
  sx?: object;
  onTestSelect?: (newTest: ITestItem | null) => void;
  currentCategoryId?: string;
  tempSelectedTest?: ITestItem | null;
  onTempTestSelect?: (test: ITestItem | null) => void;
};

export default function TestSearch({
  name,
  excludeIds = [],
  infoText = '',
  fullWidth = true,
  isForAppendNewTest = false,
  isForPrimaryTest = false,
  disabled = false,
  disableClearable = false,
  sx = {},
  onTestSelect,
  currentCategoryId,
  tempSelectedTest,
  onTempTestSelect,
}: TestSearchProps) {
  const { control, setValue, clearErrors, setError, getValues } = useFormContext();
  const { t } = useTranslate();

  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearchText] = useDebounce(searchInput, 1500);

  const { data: results = [], isPending: loading } = useGetCategoryTestList({
    search: debouncedSearchText,
    excludeIds,
    currentCategoryId,
  });

  const handleSearch = useCallback((inputValue: string) => {
    setSearchInput(inputValue);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          disabled={disabled}
          loading={loading}
          autoHighlight
          fullWidth={fullWidth}
          disableClearable={disableClearable}
          inputValue={searchInput}
          onChange={(e, newVal, reason) => {
            if (reason === 'clear' || !newVal) {
              if (isForAppendNewTest && onTempTestSelect) {
                onTempTestSelect(null);
              } else {
                // Preserve the structure for both primary and non-primary tests
                const clearedTest = {
                  ...getValues(name),
                  name: '',
                  id: '',
                  ...(isForPrimaryTest && { isPrimary: true }),
                };
                setValue(name, clearedTest);
                setError(name, {
                  type: 'required',
                  message: isForPrimaryTest
                    ? t('categories_listing_page.new_category_form.primary_test_required')
                    : t('categories_listing_page.new_category_form.test_required'),
                });
                if (onTestSelect) {
                  onTestSelect(null);
                }
              }
            } else {
              const updatedTest = {
                ...getValues(name),
                ...(isForPrimaryTest && { isPrimary: true }),
                ...newVal,
              };
              if (isForAppendNewTest && onTempTestSelect) {
                onTempTestSelect(updatedTest);
              } else {
                setValue(name, updatedTest);
                clearErrors();
                if (onTestSelect) {
                  onTestSelect(updatedTest);
                }
              }
            }
          }}
          value={isForAppendNewTest ? tempSelectedTest : field.value}
          popupIcon={null}
          options={results as ITestItem[]}
          onInputChange={(event, newVal) => handleSearch(newVal)}
          getOptionLabel={(option) => option.name ?? ''}
          noOptionsText={<SearchNotFound query={debouncedSearchText} sx={{ bgcolor: 'unset' }} />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{
            width: isForAppendNewTest ? '337px' : 1,
            ...sx,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={convertToTitleCase(t('common.search_test'))}
              helperText={
                isForAppendNewTest && !error?.message ? null : (
                  <FormHelperText
                    error={!!error?.message}
                    sx={{ display: 'flex', alignItems: 'center', margin: 0, gap: '4px' }}
                  >
                    <Iconify icon="material-symbols:info" />
                    {error?.message ? error.message : infoText}
                  </FormHelperText>
                )
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {loading ? <Iconify icon="svg-spinners:8-dots-rotate" sx={{ mr: -3 }} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}
