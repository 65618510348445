import React from 'react';
import { Helmet } from 'react-helmet-async';

import { SettingEditView } from 'src/sections/staff/notifications/settings/view';

export default function SettingEditViewPage() {
  return (
    <>
      <Helmet>
        <title>Setting: Edit</title>
      </Helmet>
      <SettingEditView />
    </>
  );
}
