import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import StudentGuard from 'src/auth/guard/student-guard';
import { GuestGuard, NonStaffGuard } from 'src/auth/guard';
import loadingAnimation from 'src/assets/animations/LoadingAnimation.json';

import { LottieAnimation } from 'src/components/animate';

import { paths } from '../paths';
// ----------------------------------------------------------------------

const RegisterPage = lazy(() => import('src/pages/auth/firebase/register'));
const FirebaseLoginPage = lazy(() => import('src/pages/auth/firebase/login'));
const HomePage = lazy(() => import('src/pages/public/home'));
const AnswerTestPage = lazy(() => import('src/pages/public/test/answer-test'));
const RetryTestPage = lazy(() => import('src/pages/public/test/retry-test'));
const TestSubmissionPage = lazy(() => import('src/pages/public/test/test-submission'));
const TestSubmissionAuthenticationPage = lazy(
  () => import('src/pages/public/test/test-submission-authentication'),
);
const ResultDetailsPage = lazy(() => import('src/pages/public/result/details'));
const AccountPage = lazy(() => import('src/pages/public/account'));
const AllResultDetailsPage = lazy(() => import('src/pages/public/result/allDetails'));
const PrivacyPolicyPage = lazy(() => import('src/pages/public/privacy-policy'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    path: paths.root,
    element: (
      <MainLayout>
        <Suspense
          fallback={
            <LottieAnimation
              animationData={loadingAnimation}
              bgColor="#043F6B"
              height={180}
              width={180}
            />
          }
        >
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      {
        element: <HomePage />,
        index: true,
      },
      {
        path: paths.login,
        element: (
          <GuestGuard>
            <FirebaseLoginPage />
          </GuestGuard>
        ),
      },
      {
        path: paths.signup,
        element: (
          <GuestGuard>
            <RegisterPage />
          </GuestGuard>
        ),
      },
      {
        path: paths.test.answerTest(':id'),
        element: <AnswerTestPage />,
      },
      {
        path: paths.test.retryTest(':id'),
        element: (
          <NonStaffGuard>
            <RetryTestPage />
          </NonStaffGuard>
        ),
      },
      {
        path: paths.test.testSubmission(':id'),
        element: (
          <StudentGuard>
            <TestSubmissionPage />
          </StudentGuard>
        ),
      },
      {
        path: paths.results.resultDetails(':id', ':isAfterTest'),
        element: (
          <StudentGuard>
            <ResultDetailsPage />
          </StudentGuard>
        ),
      },
      {
        path: paths.student.results,
        element: (
          <StudentGuard>
            <AllResultDetailsPage />
          </StudentGuard>
        ),
      },
      {
        path: paths.test.testSubmissionAuthentication(':id'),
        element: (
          <GuestGuard>
            <TestSubmissionAuthenticationPage />
          </GuestGuard>
        ),
      },
      {
        path: paths.student.account,
        element: (
          <StudentGuard>
            <AccountPage />
          </StudentGuard>
        ),
      },
      { path: paths.courses, element: <h1>Courses page</h1> },
      { path: paths.premises, element: <h1>Premises page</h1> },
      { path: paths.aboutUs, element: <h1>About us page</h1> },
      { path: paths.jobs, element: <h1>Jobs page</h1> },
      { path: paths.franchising, element: <h1>Franchising page</h1> },
      { path: paths.privacyPolicy, element: <PrivacyPolicyPage /> },
      { path: paths.cookiePolicy, element: <h1>Cookie policy page</h1> },
      { path: paths.cookieSettings, element: <h1>Cookie settings page</h1> },
    ],
  },
];
