import * as Yup from 'yup';

export type ITestsTableFilters = {
  categoryId: string;
  categoryName: string;
  status?: 'Published' | 'Unpublished' | '';
  isPublished?: boolean;
  searchQuery?: string;
  questionId?: string;
};

export type ITestsTableFilterValue =
  | ITestsTableFilters['categoryId']
  | ITestsTableFilters['status']
  | string;

export type ITestTableItem = {
  id: string;
  name: string;
  instruction: string;
  totalQuestions: number;
  testDuration: number;
  isPublished: boolean;
  isRandomized: boolean;
  upperThreshold: number | null;
  lowerThreshold: number | null;
  nextTestId: string | null;
  prevTestId: string | null;
  isPrimary: boolean;
  totalResults: number;
  category: {
    id: string;
    name: string;
    color: string;
    isPublished: boolean;
  } | null;
  levels: ITestLevel[] | null;
};

export interface ITestLevel {
  id: string;
  name: string;
  minPercentage: number;
}

export interface ICreateOrUpdateTest {
  id: string;
  name: string;
  instruction: string;
  isRandomized: boolean;
  dimension: number;
  totalQuestions: number;
  testDuration: number;
  isPublished: boolean;
  isArchived: boolean;
  levels: Partial<ITestLevel>[];
}

type IUserPremise = {
  premise: {
    name: string;
  };
};

type IUser = {
  id: string;
  name: string;
  surname: string;
  email: string;
  premises: IUserPremise[];
};

type ITestResult = {
  id: string;
  createdAt: Date;
  studentPoints: number;
  totalPoints: number;
  student: IUser;
  testLevels: ITestLevel;
  nextTestId: string;
};

export type ITestSection = {
  id: string;
  name: string;
  instruction: string;
  position: number;
  _tempId?: string;
};

export type ITestQuestion = {
  id: string;
  _tempId?: string;
  questionId: string;
  sectionId: string;
  testId: string;
  question: string;
  answers: Array<{ text: string; points: number }>;
  totalPoints: number;
  instruction?: string;
  imagePath?: string;
  audioPath?: string;
  videoPath?: string;
  mimeType?: string;
  isExternal: boolean;
  questionType: 'text' | 'single' | 'multiple';
  tags: string[];
  position: number;
  isUpdatedOnQuestionsTable: boolean;
  isPublished: boolean;
  isArchived: boolean;
  createdAt: string;
  updatedAt: string;
  isNewQuestion?: boolean;
  questionNumber: number;
};

type ITestCreatedBy = {
  name: string;
  surname: string;
};

export type ITestDetailsResponse = {
  id: string;
  name: string;
  instruction: string;
  isRandomized: boolean;
  dimension?: number;
  createdBy: ITestCreatedBy;
  categoryId: string;
  totalQuestions: number;
  totalResults: number;
  testDuration: number;
  upperThreshold: number | null;
  lowerThreshold: number | null;
  nextTestId: string | null;
  prevTestId: string | null;
  isPrimary: boolean;
  isPublished: boolean;
  isArchived: boolean;
  isRepeatable: boolean;
  createdAt: string;
  updatedAt: string;
  levels: ITestLevel[];
  results: ITestResult[];
  testQuestions: ITestQuestion[];
  sections: ITestSection[];
  category: {
    isPublished: boolean;
    name: string;
  };
};

const newQuestionYupSchema = Yup.object({
  question: Yup.string().required('Question cannot be empty').min(1),
  answers: Yup.array().of(
    Yup.object({
      text: Yup.string().required('Answer text cannot be empty').min(1),
      points: Yup.number().required(),
    }),
  ),
  totalPoints: Yup.number().integer().positive('Total points must be a non-negative integer'),
  instruction: Yup.string().nullable(),
  imagePath: Yup.string().nullable(),
  audioPath: Yup.string().nullable(),
  videoPath: Yup.string().nullable(),
  mimeType: Yup.string().nullable(),
  questionType: Yup.string().oneOf(['single', 'multiple', 'text']).required(),
  tags: Yup.array().of(Yup.string()).default([]),
  isPublished: Yup.boolean().default(true),
});

const saveTestQuestionsYupSchema = Yup.array().of(
  newQuestionYupSchema.shape({
    id: Yup.string().uuid(),
    sectionId: Yup.string().uuid().nullable(),
    testId: Yup.string().uuid().required(),
    position: Yup.number().required(),
    isUpdatedOnQuestionsTable: Yup.boolean().default(false),
    questionId: Yup.string().uuid().nullable(),
    _tempId: Yup.string().uuid().optional(),
  }),
);

const saveTestSectionsYupSchema = Yup.object({
  id: Yup.string().uuid(),
  name: Yup.string().required(),
  instruction: Yup.string().default(''),
  position: Yup.number().required(),
  testId: Yup.string().uuid().required(),
  questions: saveTestQuestionsYupSchema.default([]),
  _tempId: Yup.string().uuid().optional(),
});

export const saveTestYupSchema = Yup.object({
  independentQuestions: saveTestQuestionsYupSchema.default([]),
  sections: Yup.array().of(saveTestSectionsYupSchema.default([])),
  isRandomized: Yup.boolean().default(false),
  dimension: Yup.number().nullable().default(null),
  totalQuestions: Yup.number().default(0),
});

export type SaveTestSectionsYupSchema = Yup.InferType<typeof saveTestSectionsYupSchema>;

export type SaveTestQuestionsYupSchema = Yup.InferType<typeof saveTestYupSchema>;

export type ICreateOrUpdateTestData = Partial<ICreateOrUpdateTest>;
