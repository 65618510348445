import { Helmet } from 'react-helmet-async';

import { useParams } from 'src/routes/hooks';

import { QuestionEditView } from 'src/sections/staff/tests/questions/view';

// ----------------------------------------------------------------------

export default function QuestionsEditPage() {
  const params = useParams();

  const { id } = params;

  return (
    <>
      <Helmet>
        <title>Questions: Edit</title>
      </Helmet>

      <QuestionEditView id={id!} />
    </>
  );
}
