import Button from '@mui/material/Button';
import { Theme, SxProps } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// import { PATH_AFTER_LOGIN } from 'src/config-global';

import { useTranslate } from 'src/locales';
import { PATH_AFTER_LOGIN_FOR_COLLAB } from 'src/config-global';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function DashboardButton({ sx }: Props) {
  const { t } = useTranslate();

  return (
    <Button
      component={RouterLink}
      href={PATH_AFTER_LOGIN_FOR_COLLAB}
      variant="text"
      sx={{
        color: 'primary.contrastText',
        m: 0,
        p: 0,
        justifyContent: 'flex-end',
        minWidth: 'max-content',
        lineHeight: '15px',
        fontSize: '14px',
        fontWeight: 900,
        '&.MuiButton-root:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiButton-startIcon': {
          marginRight: '12px',
        },
        ...sx,
      }}
      startIcon={
        <Iconify icon="ant-design:dashboard-outlined" sx={{ color: 'primary.contrastText' }} />
      }
    >
      {t('header.nav_links.go_to_dashboard')}
    </Button>
  );
}
