import voca from 'voca';
import { groupBy } from 'lodash';

import { ASSETS_API } from 'src/config-global';
import { ISection, IQuestion } from 'src/types';

type Valuable<T> = { [K in keyof T as T[K] extends null | undefined ? never : K]: T[K] };

export function removeFalsyValuesFromObject<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends {},
  V = Valuable<T>,
>(obj: T): V {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) => !((typeof v === 'string' && !v.length) || v === null || typeof v === 'undefined'),
    ),
  ) as V;
}

export const convertToTitleCase = (text: string) => {
  const filteredText = text.replaceAll(/[_-]/g, ' ');
  return voca.titleCase(filteredText);
};

export function isBaseString(base: string, other: string): boolean {
  // Ensure both strings start with a slash to match URL paths
  if (!base.startsWith('/') || !other.startsWith('/')) {
    return false;
  }

  // Remove trailing slashes from both strings
  base = base.replace(/\/+$/, '');
  other = other.replace(/\/+$/, '');

  return other.startsWith(base);
}

export const toSnakeCaseKeys: any = (input: any) => {
  if (Array.isArray(input) && input.length === 0) return [];

  if (Array.isArray(input) && typeof input[0] === 'object') {
    return input.map((item) => toSnakeCaseKeys(item));
  }
  if (typeof input === 'object' && input !== null) {
    return Object.keys(input).reduce((acc: any, key) => {
      const snakeCaseKey = voca.snakeCase(key);
      acc[snakeCaseKey as keyof {}] = toSnakeCaseKeys(input[key]);
      return acc;
    }, {});
  }
  return input;
};

export const toCamelCaseKeys: any = (input: any) => {
  if (Array.isArray(input) && input.length === 0) return [];

  if (Array.isArray(input) && typeof input[0] === 'object') {
    return input.map((item) => toCamelCaseKeys(item));
  }
  if (typeof input === 'object' && input !== null) {
    return Object.keys(input).reduce((acc: any, key) => {
      const snakeCaseKey = voca.camelCase(key);
      acc[snakeCaseKey as keyof {}] = toCamelCaseKeys(input[key]);
      return acc;
    }, {});
  }
  return input;
};

export const isArrayOfObjects = <T>(
  arr: T[],
): arr is Exclude<T, null | undefined | string | number | symbol>[] =>
  arr.every((item) => typeof item === 'object' && item !== null && !Array.isArray(item));

export const generateMediaUrl = (path: string) => `${ASSETS_API}/${path}`;

export const isQuestionOrSection = (obj: Record<string, any>) => {
  if (!obj || typeof obj !== 'object') {
    throw new Error('Invalid input: expected an object.');
  }
  if ('questionType' in obj && Array.isArray(obj.answers)) {
    return 'question';
  }
  if ('name' in obj && Array.isArray(obj.questions)) {
    return 'section';
  }
  throw new Error('Unknown object type.');
};

export const shuffleArray = <T>(array: T[]) => {
  let currentIndex = array.length;

  while (currentIndex !== 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const groupBySectionIdAndShuffle = (array: IQuestion[]) => {
  // Step 1: Group by sectionId using lodash groupBy
  const groups = groupBy(array, 'sectionId');

  // Step 2: Shuffle each group
  const shuffledGroups = Object.entries(groups).map(([sectionId, group]) => ({
    sectionId,
    shuffledGroup: shuffleArray(group),
  }));

  // Step 3: Combine the shuffled groups back into a single array
  const result: IQuestion[] = shuffledGroups.reduce(
    (acc: IQuestion[], { shuffledGroup }) => [...acc, ...shuffledGroup],
    [],
  );

  return result;
};

// Define the function signature
export const orderSectionAndQsByPos = (array: IQuestion[], sections: ISection[]) => {
  // Initialize an empty array to store all questions and sections in their ordered positions
  const allQuestions: any = [];

  // Filter out individual questions that do not belong to any section
  const individualQs = array.filter((arr) => !arr.sectionId);

  // Filter questions that belong to a section
  const qsInsideSection = array.filter((arr) => arr.sectionId);

  // Loop through individual questions and assign them to their respective positions in the allQuestions array if they have a valid position
  individualQs.forEach((qs) => {
    if (qs.position) {
      allQuestions[qs.position] = qs;
    }
  });

  // Group questions inside sections by their sectionId
  const groups = groupBy(qsInsideSection, 'sectionId');

  // Loop through sections, retrieve their associated questions from groups, and assign them to their respective positions in the allQuestions array if the section has a valid position
  sections.forEach((sec: ISection) => {
    if (sec.position) {
      const currentSecQs = groups[sec.id];
      allQuestions[sec.position] = currentSecQs?.sort((a, b) => a.position - b.position);
    }
  });

  // Filter out any undefined entries from allQuestions, flatten the array, and cast it to IQuestion[]
  const result: IQuestion[] = allQuestions.filter(Boolean).flat(1);

  // Return the ordered array of questions and sections
  return result;
};
