import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm, Controller, useFieldArray } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Stack,
  Paper,
  alpha,
  Button,
  Switch,
  Divider,
  Typography,
  FormControlLabel,
} from '@mui/material';

import { fDate } from 'src/utils/format-time';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { useAuthContext } from 'src/auth/hooks';
import { USER_ROLES } from 'src/constants/user-roles';
import { createOrUpdateTest } from 'src/api/staff/tests';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFTextField } from 'src/components/hook-form';

import { ITestDetailsResponse } from 'src/types/staff/tests';

function TestEditGeneralView({ test }: { test: ITestDetailsResponse }) {
  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const createOrUpdateTestSchema = Yup.object().shape({
    id: Yup.string().optional(),
    name: Yup.string().required(t('test_edit_page.general.name_required')),
    instruction: Yup.string().required(t('test_edit_page.general.instructions_required')),
    isRandomized: Yup.boolean().default(false),
    dimension: Yup.number().optional(),
    categoryId: Yup.string().optional(),
    totalQuestions: Yup.number().required(),
    testDuration: Yup.number().default(3600),
    isPublished: Yup.boolean().required(),
    isArchived: Yup.boolean().required(),
    levels: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().optional(),
          name: Yup.string().required(t('test_edit_page.general.level_name_required')),
          minPercentage: Yup.number()
            .min(1)
            .max(100)
            .required(t('test_edit_page.general.valid_percentage_required')),
        }),
      )
      .min(1, t('test_edit_page.general.level_required')),
  });

  type CreateOrUpdateTest = Yup.InferType<typeof createOrUpdateTestSchema>;

  const defaultValues: CreateOrUpdateTest = useMemo(
    () => ({
      id: test.id,
      name: test.name,
      instruction: test.instruction,
      isRandomized: test.isRandomized,
      totalQuestions: test.totalQuestions,
      testDuration: test.testDuration,
      isPublished: test.isPublished,
      isArchived: test.isArchived,
      createdAt: test.createdAt,
      levels: test.levels,
    }),
    [test],
  );

  const methods = useForm({
    resolver: yupResolver(createOrUpdateTestSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (test) {
      reset(defaultValues);
    }
  }, [test, reset, defaultValues]);

  const [newLevelName, setNewLevelName] = useState('');
  const [newLevelPer, setNewLevelPer] = useState(0);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'levels',
  });
  const handleAddLevel = () => {
    append({ name: newLevelName, minPercentage: newLevelPer });
    setNewLevelName('');
    setNewLevelPer(0);
  };

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!data.isPublished && test.category && test.category.isPublished) {
        return enqueueSnackbar(
          t('test_edit_page.test_cannot_unpublish', { categoryName: test.category.name }),
          {
            variant: 'error',
          },
        );
      }
      await createOrUpdateTest(data);
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTest, test.id] });
      return enqueueSnackbar(t('test_edit_page.general.update_successful'), { variant: 'success' });
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      return enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Paper elevation={3} variant="elevation" square sx={{ p: 3, my: 5 }}>
        <Stack gap={4}>
          <RHFTextField
            name="name"
            label={t('common.name')}
            size="small"
            margin="dense"
            fullWidth
            disabled={isAnalyst}
          />

          <Box>
            <RHFEditor
              simple
              name="instruction"
              placeholder={t('common.instructions')}
              sx={{
                '& .ql-editor': {
                  bgcolor: 'transparent',
                },
              }}
              readOnly={isAnalyst}
            />
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack spacing={2}>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
              sx={{ color: 'info.dark' }}
            >
              {t('common.levels')}*
            </Typography>
            {fields.map((field, index) => (
              <Grid
                key={index}
                container
                gap={2}
                sx={{ m: 0, width: '100% !important' }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item sx={{ m: 0, p: '0 !important', display: 'flex', alignItems: 'center' }}>
                  <Iconify icon="akar-icons:dot-grid" width="20px" color="grey.600" />
                </Grid>
                <Grid item xs={8} sx={{ m: 0, p: '0 !important' }}>
                  <RHFTextField
                    name={`levels.${index}.name`}
                    size="small"
                    fullWidth
                    disabled={isAnalyst}
                  />
                </Grid>
                <Grid item xs={1} sx={{ m: 0, p: '0 !important' }}>
                  <RHFTextField
                    name={`levels.${index}.minPercentage`}
                    type="number"
                    label="%"
                    size="small"
                    fullWidth
                    helperText=""
                    ignoreErrorMessage
                    disabled={isAnalyst}
                  />
                </Grid>
                <Grid item sx={{ m: 0, p: '0 !important' }}>
                  <Button
                    onClick={() => remove(index)}
                    startIcon={<Iconify icon="solar:trash-bin-trash-bold" width={16} />}
                    sx={{
                      backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                      color: (theme) => theme.palette.error.dark,
                      '&:hover': {
                        backgroundColor: (theme) => alpha(theme.palette.error.main, 0.07),
                      },
                    }}
                    disabled={isAnalyst}
                  >
                    {t('common.remove')}
                  </Button>
                </Grid>
              </Grid>
            ))}
            {/* <Grid container spacing={3} sx={{ m: 0, width: '100% !important' }}>
              <Grid item xs={12} sm={8} sx={{ m: 0, p: '0 !important' }}> */}
            <Grid
              container
              gap={2}
              sx={{ m: 0, width: '100% !important' }}
              alignItems="center"
              // justifyContent="space-between"
            >
              <Grid item xs={4} sx={{ m: 0, p: '0 !important' }}>
                <RHFTextField
                  name="levels"
                  value={newLevelName}
                  onChange={(e) => setNewLevelName(e.target.value)}
                  size="small"
                  id="level-name-input"
                  placeholder={t('common.level')}
                  disabled={isAnalyst}
                />
              </Grid>
              <Grid item xs={1} sx={{ m: 0, p: '0 !important' }}>
                <RHFTextField
                  name="minPercentage"
                  value={newLevelPer}
                  onChange={(e) => setNewLevelPer(Number(e.target.value))}
                  type="number"
                  label="%"
                  size="small"
                  fullWidth
                  helperText=""
                  ignoreErrorMessage
                  disabled={isAnalyst}
                />
              </Grid>
              <Grid item sx={{ m: 0, p: '0 !important' }}>
                <Button
                  variant="contained"
                  onClick={handleAddLevel}
                  disabled={!newLevelName.trim() || isAnalyst}
                  endIcon={<Iconify icon="mingcute:add-line" />}
                  sx={{ color: 'secondary.contrastText', backgroundColor: 'secondary.main' }}
                >
                  {t('common.add')}
                </Button>
              </Grid>
            </Grid>
            {/* </Grid>
            </Grid> */}
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          {/* <Stack>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
              sx={{ color: 'info.dark' }}
            >
              Timer
            </Typography>
            <Controller
              name="testDuration"
              control={control}
              defaultValue={defaultValues.testDuration}
              render={({ field }) => (
                <Select {...field} id="timer-select" sx={{ maxWidth: '188px' }}>
                  {timeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Stack> */}

          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
              sx={{ color: 'info.dark' }}
            >
              {t('common.status')}
            </Typography>
            <Controller
              name="isPublished"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={field.value}
                      color="success"
                      onChange={(e) => field.onChange(e.target.checked)}
                      disabled={isAnalyst}
                    />
                  }
                  label={t('common.live')}
                  sx={{ width: 'max-content' }}
                />
              )}
            />
          </Stack>

          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
              sx={{ color: 'info.dark' }}
            >
              {t('common.details')}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Typography
                  variant="body2"
                  sx={{ fontWeight: '400', fontSize: '14px', mr: '6px', color: 'grey.600' }}
                >
                  {t('common.created_on')}:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: '900', fontSize: '14px', color: 'grey.600' }}
                >
                  {fDate(test.createdAt, 'dd/MM/yyyy')}
                </Typography>
              </Stack>

              <Stack direction="row">
                <Typography
                  variant="body2"
                  sx={{ fontWeight: '400', fontSize: '14px', mr: '6px', color: 'grey.600' }}
                >
                  {t('common.by')}:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: '900', fontSize: '14px', color: 'grey.600' }}
                >
                  {`${test.createdBy.name} ${test.createdBy.surname ? test.createdBy.surname : ''}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack display={isAnalyst ? 'none' : 'flex'} alignItems="end">
            <LoadingButton color="success" type="submit" variant="contained" loading={isSubmitting}>
              {t('common.save')}
            </LoadingButton>
          </Stack>
        </Stack>
      </Paper>
    </FormProvider>
  );
}

export default TestEditGeneralView;
