import React, { Children } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import {
  Box,
  Stack,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';

import { paths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { convertToTitleCase } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import queryKeys from 'src/constants/query-keys';
import { shareResult, deleteResult } from 'src/api/result';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import ShareDialog from 'src/components/sharing/share-dialog-new';

import { ITableColumns } from 'src/types/misc';
import { IResultsModifiedItem } from 'src/types/results';

type Props = {
  row: IResultsModifiedItem;
  headLabel: Partial<ITableColumns>[] | any;
};

export default function ResultsTableRow({ row, headLabel }: Props) {
  const shareDialog = useBoolean(false);
  const confirmStudentDelete = useBoolean();
  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const handleDeleteResult = async () => {
    try {
      confirmStudentDelete.onFalse();
      await deleteResult(row.id);
      enqueueSnackbar(t('results_listing_page.result_deleted'));
      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.results.fetchAllResults] });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const handleShare = async () => {
    shareDialog.onTrue();
  };

  const tableActions = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('common.share')} placement="top" arrow>
        <IconButton onClick={handleShare}>
          <Iconify icon="solar:share-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  const tableDeleteAction = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('common.delete')} placement="top" arrow>
        <IconButton onClick={() => confirmStudentDelete.onTrue()} sx={{ color: 'error.dark' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
  return (
    <>
      <TableRow hover>
        {Children.toArray(
          headLabel.map((headCell: any) => {
            if (headCell?.field_mod) {
              return (
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                  }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.field_mod]}
                </TableCell>
              );
            }
            if (headCell?.id) {
              if (headCell.id === 'name') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      whiteSpace: 'nowrap',
                      textDecoration: 'none',
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Stack>
                      <Typography variant="subtitle2" fontWeight={900}>
                        {row.studentFullName}
                      </Typography>
                      <Typography variant="caption" sx={{ color: 'customColors.custom1' }}>
                        {row.studentEmail}
                      </Typography>
                    </Stack>
                  </TableCell>
                );
              }

              if (headCell.id === 'date') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {row.date}
                    </Typography>
                  </TableCell>
                );
              }

              if (headCell.id === 'level') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* @ts-ignore */}
                    <Typography variant="subtitle2" fontWeight={900}>
                      {row?.level || ''}
                    </Typography>
                  </TableCell>
                );
              }

              if (headCell.id === 'premises') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* @ts-ignore */}
                    {row?.[headCell?.id].length ? (
                      <Stack direction="row" spacing={2}>
                        <Box width={16}>
                          <Iconify
                            color="secondary.lighter"
                            icon="fluent:location-24-filled"
                            width={16}
                            height={16}
                          />
                        </Box>
                        <Typography variant="body2" whiteSpace="wrap">
                          {/* @ts-ignore */}
                          {row?.premises.join(', ')}
                        </Typography>
                      </Stack>
                    ) : (
                      ''
                    )}
                  </TableCell>
                );
              }

              if (headCell.id === 'actions') {
                return <>{tableActions}</>;
              }

              return (
                <TableCell
                  sx={{ whiteSpace: 'nowrap', width: headCell.width, minWidth: headCell.minWidth }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.id]}
                </TableCell>
              );
            }
            return <>{tableDeleteAction}</>;
          }),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmStudentDelete.value}
        onClose={confirmStudentDelete.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" width={24} height={24} />{' '}
            <Typography variant="h6" fontWeight={900}>
              {convertToTitleCase(t('results_listing_page.delete_result'))}
            </Typography>
          </Box>
        }
        content={
          <Typography variant="subtitle1" fontWeight={400} sx={{ color: 'text.secondary' }}>
            {t('results_listing_page.sure_delete')}
          </Typography>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteResult}>
            {t('common.delete')}
          </Button>
        }
      />
      {/* share dialog box---------------------------------------- */}
      <ShareDialog
        dialog={shareDialog}
        title={t('results_listing_page.share_result')}
        onShare={shareResult}
        enableUserTypeSelection
        addtionalPayloadToShareFn={{
          resultId: row.id,
        }}
        studentLinkToShare={paths.results.resultDetails(row.id)}
      />
    </>
  );
}
