import v from 'voca';
import React from 'react';

import { Container } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useGetTemplateByTemplateName } from 'src/api/templates';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import { ITemplateDetailItem } from 'src/types/templates';

import TemplateEditForm from '../edit/template-edit-form';

type Props = {
  templateName: string;
  role: string;
};

export default function TemplateEditView({ templateName, role }: Props) {
  const settings = useSettingsContext();
  const { data }: any = useGetTemplateByTemplateName(templateName);
  const { t } = useTranslate();

  const templateDetails: ITemplateDetailItem = React.useMemo(() => data || {}, [data]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={v.titleCase(templateDetails.label) || t('common.student')}
        links={[
          { name: t('common.dashboard'), href: '#' },
          { name: t('common.notification'), href: paths.staff.notifications.email },
          {
            name: v.titleCase(role || t('common.student')),
            href: paths.staff.notifications.getEmailTemplates(role),
          },
          {
            name: v.titleCase(templateDetails.label || t('test_submission_page.page_title')),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <TemplateEditForm templateDetails={templateDetails} />
    </Container>
  );
}
