import v from 'voca';
import React, { useMemo, useState, useEffect } from 'react';

import { Box, Stack, Switch, Typography, ListItemText } from '@mui/material';

import { convertToTitleCase } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useGetStudentNotificationsList } from 'src/api/students';

import EmptyContent from 'src/components/empty-content/empty-content';

import { IStudentNotificationsItem } from 'src/types/students';

type Props = {
  id: string;
};

type NotificationProps = {
  notificationItem: IStudentNotificationsItem;
};

export default function NotificationsTab({ id }: Props) {
  const { data }: any = useGetStudentNotificationsList(id);
  const { t } = useTranslate();

  const notificationItems: IStudentNotificationsItem[] = useMemo(() => data || null, [data]);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 2fr' },
          alignItems: 'center',
          gap: 3,
          width: '100%',
        }}
      >
        {notificationItems &&
          notificationItems.map((ni) => <NotificationItem notificationItem={ni} key={ni.id} />)}
      </Box>
      <Stack sx={{ width: '100%', height: '80%' }}>
        {notificationItems && !notificationItems.length && (
          <EmptyContent
            filled
            title={convertToTitleCase(t('common.no_data'))}
            sx={{
              py: 10,
            }}
          />
        )}
      </Stack>
    </>
  );
}

const NotificationItem = ({ notificationItem }: NotificationProps) => {
  const [checked, setChecked] = useState(false);

  const premisesAddress = [notificationItem.premise?.address, notificationItem.premise?.city]
    .filter(Boolean)
    .join(', ');

  useEffect(() => {
    setChecked(notificationItem.isSubscribed);
  }, [notificationItem]);
  return (
    <>
      <Stack>
        <ListItemText
          primary={v.titleCase(notificationItem.premise.name)}
          secondary={premisesAddress}
          primaryTypographyProps={{
            noWrap: true,
            typography: 'subtitle1',
            fontWeight: 900,
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            alignItems: 'center',
            typography: 'body2',
            color: 'text.disabled',
            display: 'inline-flex',
            whiteSpace: 'wrap',
          }}
        />
      </Stack>
      <Stack
        minHeight={80}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: 'background.neutral' }}
        padding={3}
        borderRadius={1}
        spacing={1}
      >
        <Typography variant="body2" sx={{ color: 'text.disabled' }}>
          News Letter
        </Typography>
        <Switch
          checked={checked}
          inputProps={{ 'aria-label': 'controlled' }}
          color="success"
          disabled
        />
      </Stack>
    </>
  );
};
