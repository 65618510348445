export function lightenHexColor(hex: string, percent: number): string {
  const decimalPercent = percent / 100;

  // Strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // Convert hex to RGB
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);

  // Increase the lightness
  r = Math.min(255, Math.floor(r + (255 - r) * decimalPercent));
  g = Math.min(255, Math.floor(g + (255 - g) * decimalPercent));
  b = Math.min(255, Math.floor(b + (255 - b) * decimalPercent));

  // Convert RGB back to hex and pad with 0 if necessary
  const rHex = r.toString(16).padStart(2, '0');
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');

  return `#${rHex}${gHex}${bHex}`;
}
