import { useCallback } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';

import { ITestsTableFilters, ITestsTableFilterValue } from 'src/types/staff/tests';

// ----------------------------------------------------------------------

type Props = StackProps & {
  filters: ITestsTableFilters;
  onFilters: (name: string, value: ITestsTableFilterValue) => void;
  onResetFilters: VoidFunction;
  results: number;
};

export default function TestTableFiltersResult({
  filters,
  onFilters,
  onResetFilters,
  results,
  ...other
}: Props) {
  const { t } = useTranslate();
  const handleRemoveKeyword = useCallback(() => {
    onFilters('searchQuery', '');
  }, [onFilters]);

  const handleRemoveCategory = useCallback(() => {
    onFilters('categoryId', '');
    onFilters('categoryName', '');
  }, [onFilters]);

  const handleRemoveStatus = useCallback(() => {
    delete filters.status;
    delete filters.isPublished;
  }, [filters.isPublished, filters.status]);

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          {t('common.results_found').toLowerCase()}
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap">
        {!!filters.categoryId && (
          <Block label={`${t('common.category')}:`}>
            <Chip
              label={filters.categoryName}
              size="small"
              onDelete={() => handleRemoveCategory()}
            />
          </Block>
        )}

        {!!filters.status && (
          <Block label={`${t('common.status')}:`}>
            <Chip label={filters.status} size="small" onDelete={() => handleRemoveStatus()} />
          </Block>
        )}

        {!!filters.searchQuery && (
          <Block label={`${t('common.keyword')}:`}>
            <Chip label={filters.searchQuery} size="small" onDelete={handleRemoveKeyword} />
          </Block>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          {t('common.clear')}
        </Button>
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
  label: string;
};

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}
