import { UseQueryResult, keepPreviousData } from '@tanstack/react-query';

import { useGetData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints } from 'src/utils/axios';

import queryKeys from 'src/constants/query-keys';

import { IListFilters, IFetchBaseParams } from 'src/types/misc';
import { IQuestionItem, IQuestionTableFilters } from 'src/types/question';

export function useGetQuestionList(
  params?: IListFilters<Omit<IQuestionTableFilters, 'searchText'>>,
) {
  return useGetData({
    args: [endpoints.staff.questions, { params }],
    queryKey: [queryKeys.staff.questions.fetchAllQuestions, params],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export async function createQuestion(data: IQuestionItem) {
  const URL = `${endpoints.staff.questions}`;
  await axiosInstance.post(URL, data);
}

export async function updateQuestion(data: IQuestionItem) {
  const URL = `${endpoints.staff.questions}/${data?.id}`;
  await axiosInstance.put(URL, data);
}

export function useGetQuestion(params?: IFetchBaseParams): UseQueryResult<IQuestionItem, Error> {
  return useGetData({
    args: [`${endpoints.staff.questions}/${params?.id}`],
    queryKey: [queryKeys.staff.questions.fetchQuestion, params?.id],
  });
}

export async function archiveQuestion(id: string) {
  const URL = `${endpoints.staff.questions}/${id}/archive`;
  await axiosInstance.post(URL);
}

export async function updateQuestionLiveStatus(id: string, data: { isPublished: boolean }) {
  const URL = `${endpoints.staff.questions}/${id}`;
  await axiosInstance.put(URL, data);
}

export function useGetQuestionTags(searchQuery: string): UseQueryResult<string[], Error> {
  return useGetData({
    args: [`${endpoints.staff.questions}/tags`, { params: { searchQuery } }],
    queryKey: [queryKeys.staff.questions.fetchTags, searchQuery],
    options: {
      enabled: Boolean(searchQuery),
    },
  });
}
