import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import {
  Card,
  Stack,
  Button,
  Switch,
  Typography,
  CardContent,
  FormControlLabel,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { useUpdatePremisesNewsletterStatus } from 'src/api/students';

import Iconify from 'src/components/iconify';
import ScrollToAnchor from 'src/components/scroll-to-position/scroll-to-anchor';

import { IPremsiesItemForAccount } from 'src/types/students';

import AddNewPremisesDialog from './add-new-premises-dialog';

type Props = {
  premises: IPremsiesItemForAccount[];
};

export const premisesSectionId = 'student-account-premises-contact-section';

export default function AccountPremises({ premises }: Props) {
  const { t } = useTranslate();

  const dialog = useBoolean();

  const { mutateAsync: updateNewsletterStatus } = useUpdatePremisesNewsletterStatus();

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const handleNewsletterStatus = async ({
    premisesId,
    isSubscribed,
  }: {
    premisesId: string;
    isSubscribed: boolean;
  }) => {
    try {
      await updateNewsletterStatus(
        {
          data: {
            premisesId,
            isSubscribed,
          },
        },
        {
          onSettled: (_, err) => {
            if (!err) {
              enqueueSnackbar('Update success!');
            }
          },
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [keys.student.fetchStudentAccountInfo] });
          },
        },
      );
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      }
    }
  };

  return (
    <Stack
      component="div"
      id={premisesSectionId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <ScrollToAnchor />
      <Typography
        variant="body1"
        color="black"
        sx={{ fontWeight: '900 !important', letterSpacing: '0.2px', lineHeight: '18px' }}
      >
        {t('common.premises')}
      </Typography>
      <Stack gap={2}>
        {premises &&
          premises?.length > 0 &&
          premises?.map((premise) => (
            <Card
              key={premise.id}
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
                padding: 3,
                justifyContent: 'space-between',
                alignItems: { xs: 'start', sm: 'center' },
                alignSelf: 'stretch',
                borderRadius: 1,
                gap: 2,
              }}
              style={{
                backgroundColor: '#F4F5F7',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: 2,
                  padding: 0,
                }}
              >
                <Stack
                  sx={{
                    display: 'flex',
                    maxWidth: '700px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <Typography
                    variant="body1"
                    color="black"
                    sx={{
                      fontWeight: '900 !important',
                      letterSpacing: '0.2px',
                      lineHeight: '18px',
                    }}
                  >
                    {premise.premise.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary.text"
                    fontWeight="400"
                    letterSpacing="0.2px"
                    lineHeight="18px"
                  >
                    {premise.premise.address}
                  </Typography>
                </Stack>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() =>
                        handleNewsletterStatus({
                          premisesId: premise.premise.id,
                          isSubscribed: !premise.isSubscribed,
                        })
                      }
                      checked={premise.isSubscribed}
                      color="success"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      color="primary.text"
                      fontWeight="400"
                      letterSpacing="0.2px"
                      lineHeight="18px"
                    >
                      {t('account_page.premises_section.newsletter_switch_label')}
                    </Typography>
                  }
                />
              </CardContent>
              {premise?.premise?.email && (
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  sx={{
                    display: 'flex',
                    flexShrink: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '24px',
                    lineHeight: 0,
                    padding: '8px 16px',
                    textTransform: 'uppercase',
                  }}
                  href={`mailto:${premise.premise.email}`}
                  target="_top"
                  rel="noopener noreferrer"
                  endIcon={<Iconify icon="mdi:email" height={15} width={15} />}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Nunito Sans',
                      fontSize: '12px',
                      fontWeight: '900',
                      lineHeight: '18px' /* 150% */,
                      letterSpacing: '2px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('common.contact')}
                  </Typography>
                </Button>
              )}
            </Card>
          ))}

        <Button
          color="secondary"
          onClick={dialog.onTrue}
          sx={{ fontWeight: 'bold', maxWidth: 'fit-content' }}
        >
          {t('account_page.premises_section.add_a_premise')} +
        </Button>

        {dialog.value && (
          <AddNewPremisesDialog
            dialog={dialog}
            excludePremisesIds={(premises || [])?.map((premise) => premise?.premise?.id)}
          />
        )}
      </Stack>
    </Stack>
  );
}
