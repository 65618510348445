import v from 'voca';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import React, { useMemo, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import { Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { SITE_URL } from 'src/config-global';
import queryKeys from 'src/constants/query-keys';
import { USER_ROLES } from 'src/constants/user-roles';
import { useGetPremisesList } from 'src/api/premises';
import { createStaff, updateStaff } from 'src/api/collaborators';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFTextField, RHFAutocomplete } from 'src/components/hook-form';

import { IUseBooleanReturnType } from 'src/types/misc';
import { ICollaboratorsItem } from 'src/types/collaborators';

type props = {
  dialog: IUseBooleanReturnType;
  collaborator?: ICollaboratorsItem | null;
  setEditData?: React.Dispatch<React.SetStateAction<ICollaboratorsItem | null>>;
};

function InviteEditCollaboratorsDialog({ dialog, collaborator = null, setEditData }: props) {
  const { data: premiseData } = useGetPremisesList({ all: true });
  const { t } = useTranslate();
  const queryClient = useQueryClient();
  const ROLE_OPTIONS = useMemo(
    () =>
      Object.values(USER_ROLES)
        .filter((ur) => ur !== USER_ROLES.STUDENT)
        .map((fur) => ({ label: v.titleCase(fur), value: fur })),
    [],
  );

  const PREMISES_OPTIONS = useMemo(
    () =>
      // @ts-ignore
      (premiseData?.data || []).map((pd: { id: string; name: string }) => ({
        label: pd.name,
        value: pd.id,
      })),
    [premiseData],
  );

  const inviteCollaboratorSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required(t('premises_edit_page.collaborator_name_required')),
    email: Yup.string()
      .email(t('premises_edit_page.email_valid'))
      .required(t('common.email_required')),
    city: Yup.string(),
    phone: Yup.string(),
    role: Yup.mixed<any>().required(t('premises_edit_page.collaborator_role_required')),
    premisesIds: Yup.array().of(Yup.mixed<any>()),
  });

  const getFullName = useCallback((name: string, surname: string) => {
    if (!surname) {
      return `${name}`;
    }
    return `${name} ${surname}`;
  }, []);

  const defaultValues = useMemo(
    () => ({
      id: collaborator?.id || '',
      name: collaborator?.name ? getFullName(collaborator?.name, collaborator?.surname) : '',
      email: collaborator?.email || '',
      role: ROLE_OPTIONS.filter((ro) => ro.value === collaborator?.role)[0] || null,
      premisesIds: PREMISES_OPTIONS.filter((po: { label: string; value: string }) =>
        collaborator?.premises.some((pr) => pr.premise.id === po.value),
      ),
    }),
    [collaborator, ROLE_OPTIONS, PREMISES_OPTIONS, getFullName],
  );

  const methods = useForm({
    resolver: yupResolver<any>(inviteCollaboratorSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const values = watch();
  const onSubmit = handleSubmit(async (data) => {
    try {
      const names = data.name.split(' ');
      const formatData = {
        ...collaborator,
        name: names[0],
        surname: names[1] || null,
        email: data.email,
        role: data?.role.value,
        premisesIds: data?.premisesIds.map((ri: any) => ri.value),
        completeLoginUrl: `${SITE_URL}${paths.completeLogin}`,
      };

      if (collaborator) {
        await updateStaff(formatData);
        if (setEditData) {
          setEditData(null);
        }
      } else {
        await createStaff(formatData);
      }

      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.staffs.fetchAllStaffs] });

      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar(t('common.create_success'));
      dialog.onFalse();
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        reset();
      }
    }
  });

  const dialogContent = (
    <DialogContent>
      <Stack spacing={3}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ width: '100%' }}
          spacing={2}
          paddingTop={1}
        >
          <RHFTextField
            name="name"
            sx={{
              width: '100%',
            }}
            label={t('common.name')}
            InputLabelProps={{ style: { fontWeight: 900 } }}
            required
          />

          <RHFTextField
            name="email"
            sx={{
              width: '100%',
            }}
            label={t('common.email')}
            InputLabelProps={{ style: { fontWeight: 900 } }}
            required
          />
        </Stack>
        <RHFAutocomplete
          name="role"
          options={ROLE_OPTIONS}
          value={values?.role}
          InputLabelProp={{ style: { fontWeight: 900 } }}
          label={t('common.role')}
          sx={{
            '& .MuiInputLabel-root': {
              fontWeight: 900,
            },
          }}
          isOptionEqualToValue={(option, value) =>
            value === '' ? true : option.value === value.value
          }
        />
        <RHFAutocomplete
          multiple
          forcePopupIcon={false}
          disabled={values.role && values?.role?.value !== USER_ROLES.ANALYST}
          name="premisesIds"
          options={PREMISES_OPTIONS}
          value={values?.premisesIds}
          label={t('common.premises')}
          InputLabelProp={{ style: { fontWeight: 900 } }}
          sx={{
            '& .MuiInputLabel-root': {
              fontWeight: 900,
            },
          }}
          ChipProps={{
            style: { backgroundColor: '#00B8D941', color: '#006C9C', fontWeight: 900 },
          }}
          isOptionEqualToValue={(option, value) =>
            value === '' ? true : option.value === value.value
          }
        />
      </Stack>
    </DialogContent>
  );

  const dialogAction = (
    <DialogActions>
      <Stack direction="row" spacing={1.5} justifyContent="end">
        <Button
          variant="outlined"
          size="medium"
          onClick={() => {
            dialog.onFalse();
            reset(defaultValues);
          }}
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="success"
          size="medium"
          loading={isSubmitting}
        >
          {!collaborator ? t('common.invite') : t('common.save')}
        </LoadingButton>
      </Stack>
    </DialogActions>
  );
  return (
    <Dialog
      open={dialog.value}
      sx={{
        '& .MuiDialog-paper': {
          width: { xs: '100%', sm: '90%', md: '50%' },
          maxWidth: 'none',
          minHeight: '40%',
        },
      }}
      onClose={dialog.onFalse}
    >
      <DialogTitle fontWeight={900}>
        {t('collaborators_listing_page.invite_collaborators')}
      </DialogTitle>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {dialogContent}
        {dialogAction}
      </FormProvider>
    </Dialog>
  );
}

export default InviteEditCollaboratorsDialog;
