import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select';

import { useTranslate } from 'src/locales';

import { ITestSection } from 'src/types/staff/tests';

type Props = SelectProps & {
  sectionOpts: ITestSection[];
  selectedSectionId: string;
  setSelectedSectionId: (section: string) => void;
};

export default function TestSectionsDropdown({
  sectionOpts,
  selectedSectionId,
  setSelectedSectionId,
  ...other
}: Props) {
  const handleSectionSelect = (event: SelectChangeEvent<unknown>) => {
    setSelectedSectionId(event.target.value as string);
  };
  const { t } = useTranslate();

  return (
    <Select {...other} value={selectedSectionId} onChange={handleSectionSelect} displayEmpty>
      <MenuItem disabled value="" sx={{ color: 'grey.900' }}>
        <em>{t('test_edit_page.questions.select_a_section')}</em>
      </MenuItem>
      {sectionOpts.map((section) => (
        <MenuItem
          key={section.id || section._tempId}
          value={section.id || section._tempId}
          sx={{ color: 'grey.900' }}
        >
          {section.name}
        </MenuItem>
      ))}
    </Select>
  );
}
